import React, { useState, useEffect } from 'react'
import { Modal, ModalDialog, DialogTitle, DialogContent, FormControl, Input, Typography, Button, Divider, IconButton, Box } from '@mui/joy'
import { Close as CloseIcon } from '@mui/icons-material'
import axios from 'axios'
import { Application, ApplicationSettingsStatesListType } from '@numeo/types'

type EmailSettings = {
    totalFuelPrice: string
    driverCost: string
    ratePerMile: number
    isSetParameters: boolean
    states: {
        included: string[]
        excluded: string[]
        activeList: ApplicationSettingsStatesListType
    }
}

export type EmailSettingsModalProps = {
    application: Application | null
    setApplication: (application: Application) => void
    verifyAuth: () => Promise<void>
}

export const EmailSettingsModal: React.FC<EmailSettingsModalProps> = ({ verifyAuth, application, setApplication }) => {
    const [settings, setSettings] = useState<Partial<EmailSettings>>({
        totalFuelPrice: '4',
        driverCost: '0.6',
        ratePerMile: 2,
        states: {
            included: [],
            excluded: [],
            activeList: ApplicationSettingsStatesListType.INCLUDED,
        },
        isSetParameters: false,
    })

    const [isOpen, setIsOpen] = useState(!application?.temp?.emailSettings?.isSetParameters)

    // Verify auth only once when component mounts
    useEffect(() => {
        verifyAuth()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!application?.temp?.emailSettings?.isSetParameters) {
            setIsOpen(true)
        }
    }, [application])

    const handleSettingChange = (field: keyof EmailSettings, value: number) => {
        setSettings((prev) => ({ ...prev, [field]: value }))
    }

    const onCloseAndSaveSettings = async () => {
        if (!application) return

        try {
            const emailSettings: Partial<EmailSettings> = {
                ...application.temp?.emailSettings,
                ...settings,
                isSetParameters: true,
            }

            await axios.post('/email/email-settings', emailSettings)

            setApplication({
                ...application,
                temp: {
                    ...application.temp,
                    emailSettings,
                },
            })

            setIsOpen(false)
        } catch (error) {
            console.error('Failed to save settings:', error)
        }
    }

    return (
        <Modal open={isOpen} onClose={onCloseAndSaveSettings}>
            <ModalDialog variant="outlined" sx={{ width: { xs: '90vw', sm: '500px' }, maxWidth: '500px', borderRadius: '16px', p: 2, boxShadow: 'lg' }}>
                <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography level="h4" sx={{ fontWeight: 600 }}>
                            Initial Setup
                        </Typography>
                        <IconButton variant="plain" color="neutral" onClick={onCloseAndSaveSettings}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography level="body-sm" sx={{ mt: 1, color: 'text.secondary' }}>
                        Welcome! Let's set up your cost parameters. These values will be used to calculate trip expenses and driver compensation. You can always
                        adjust them later in the settings.
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
                    <FormControl>
                        <Typography gutterBottom>Fuel cost per gallon</Typography>
                        <Input
                            type="number"
                            value={settings.totalFuelPrice}
                            onChange={(e) => handleSettingChange('totalFuelPrice', Number(e.target.value))}
                            slotProps={{ input: { min: 0, step: 0.1 } }}
                        />
                    </FormControl>
                    <Divider sx={{ my: 1 }} />
                    <FormControl>
                        <Typography gutterBottom>Driver cost per mile</Typography>
                        <Input
                            type="number"
                            value={settings.driverCost}
                            onChange={(e) => handleSettingChange('driverCost', Number(e.target.value))}
                            slotProps={{ input: { min: 0, step: 0.1 } }}
                        />
                    </FormControl>
                    <Divider sx={{ my: 1 }} />
                    <FormControl>
                        <Typography gutterBottom>Rate per mile</Typography>
                        <Input
                            type="number"
                            value={settings.ratePerMile}
                            onChange={(e) => handleSettingChange('ratePerMile', Number(e.target.value))}
                            slotProps={{ input: { min: 0, step: 0.1 } }}
                        />
                    </FormControl>
                </DialogContent>
                <Box sx={{ p: 2, display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                    <Button variant="plain" color="neutral" onClick={onCloseAndSaveSettings}>
                        Go with defaults
                    </Button>
                    <Button onClick={onCloseAndSaveSettings}>Save Settings</Button>
                </Box>
            </ModalDialog>
        </Modal>
    )
}
