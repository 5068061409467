import { Box, Sheet, Table, Tooltip, Typography, useColorScheme, CircularProgress, Checkbox, Button, IconButton, Input, Snackbar, Stack } from '@mui/joy'
import React, { useEffect, useState, useMemo } from 'react'
import { HorizontalDirectionIcon, VerticalDirectionIcon } from 'assets/icons/DatDirectionIcons'
import { DatabaseLoad } from '@numeo/types'
import { useWebSocket } from 'context/WebSocketContext'
import CheckIcon from '@mui/icons-material/Check'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import EmailModal from './EmailModal'
import { Popover, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import EmailIcon from '@mui/icons-material/Email'
import { Call as CallIcon } from '@mui/icons-material'
import CallEndIcon from '@mui/icons-material/CallEnd'
import './assets/styles.css'
import { calculateRatePerMile, formatPhoneNumber, sortLoads, timeDisplay, sendInfoEmail, useColspanManager } from './assets/helpers'
import { tableColors } from './assets/utils'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useAuth } from 'hooks/auth/Provider'
import { useGetQuery } from 'hooks/useGetQuery'
import { TrunscriptionTooltip } from './TrunscriptionTooltip'

export default function LoadsTableL({
    loads,
    isSearching,
    callingLoadId,
    handlePhoneClick,
}: {
    loads: DatabaseLoad[]
    sortOption: string
    isSearching: boolean
    callingLoadId: string | null
    handlePhoneClick: (load: DatabaseLoad, phoneNumber?: string) => void
}) {
    const [selectedLoads, setSelectedLoads] = useState<Set<string>>(new Set())
    const { mode } = useColorScheme()
    const { messagesLoading, onLoad } = useWebSocket()
    const { application } = useAuth()
    const threadId = useGetQuery('threadId')
    const projectName = application?.projectName
    const [hiddenLoads, setHiddenLoads] = useState<Set<string>>(new Set())
    const [isRequestRateLoading, setIsRequestRateLoading] = useState(false)
    const [snackbar, setSnackbar] = useState<{
        open: boolean
        message: string
        color: 'success' | 'danger'
    }>({
        open: false,
        message: '',
        color: 'success',
    })
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [activeColumn, setActiveColumn] = useState<string | null>(null)
    const [sortConfigs, setSortConfigs] = useState<{ [key: string]: { key: string; direction: 'asc' | 'desc' | null } }>({})
    const [expandedLoads, setExpandedLoads] = useState<Set<string>>(new Set())
    const [maxMiles, setMaxMiles] = useState<string>('')
    const [minMiles, setMinMiles] = useState<string>('')
    const [minRate, setMinRate] = useState<string>('')
    const [selectedLoadId, setSelectedLoadId] = useState<string | null>(null)
    const [emailSendingLoadId, setEmailSendingLoadId] = useState<string | null>(null)
    const [phoneNumberTest, setPhoneNumberTest] = useState<string>('+16572893125')
    const [emailSentLoads, setEmailSentLoads] = useState<Set<string>>(new Set())

    // Use the colspan manager hook
    const colspan = useColspanManager('.loads-table-large')

    useEffect(() => {
        // Initialize emailSentLoads from loads prop
        const sentLoads = new Set(loads.filter((load) => load.isEmailSent).map((load) => load.matchId))
        setEmailSentLoads(sentLoads)
    }, [loads])

    useEffect(() => {
        setSelectedLoads(new Set())
    }, [threadId])

    const updateEmailStatus = (loadId: string) => {
        setEmailSentLoads((prev) => new Set([...prev, loadId]))
    }

    // Filter loads based on sorting criteria
    const filteredLoads = loads.filter((load) => {
        const tripLength = load.tripLength
        const rate = load.rateInfo?.rateUsd

        if (maxMiles && tripLength > parseFloat(maxMiles)) return false
        if (minMiles && tripLength < parseFloat(minMiles)) return false
        if (minRate && (!rate || rate < parseFloat(minRate))) return false

        return !hiddenLoads.has(load.matchId)
    })

    const handleRowClick = (load: DatabaseLoad) => {
        if (load.status === 'cancelled') return
        setExpandedLoads((prev) => {
            const newSet = new Set(prev)
            if (newSet.has(load.matchId)) {
                newSet.delete(load.matchId)
            } else {
                newSet.add(load.matchId)
            }
            return newSet
        })
    }

    const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>, loadMatchId: string) => {
        e.stopPropagation()
        setSelectedLoads((prev) => {
            const newSet = new Set(prev)
            if (newSet.has(loadMatchId)) {
                newSet.delete(loadMatchId)
            } else {
                newSet.add(loadMatchId)
            }
            return newSet
        })
    }

    const handleHideLoads = () => {
        setHiddenLoads((prev) => new Set([...prev, ...selectedLoads]))
        setSelectedLoads(new Set())
    }

    const handleHeaderClick = (event: React.MouseEvent<HTMLElement>, columnName: string) => {
        setAnchorEl(event.currentTarget)
        setActiveColumn(columnName)
    }

    const handleClosePopover = () => {
        setAnchorEl(null)
    }

    const handleSortOptionChange = (option: string) => {
        // Reset all sorting configs and set only the active column's sort
        setSortConfigs({
            [activeColumn || '']: { key: option, direction: 'asc' },
        })
        handleClosePopover()
    }

    const getIconStyle = (option: string, column: string) => {
        return {
            transform: sortConfigs[column]?.key === option ? 'rotate(180deg)' : 'rotate(0deg)',
            color: sortConfigs[column]?.key === option ? '#1976d2' : '#757575',
            verticalAlign: 'middle',
            marginLeft: '-5px',
        }
    }

    const sortedLoads = useMemo(() => {
        return Object.entries(sortConfigs).reduce((loads, [, config]) => {
            return sortLoads(loads, config.key)
        }, filteredLoads)
    }, [filteredLoads, sortConfigs])

    // Handle select all loads
    const handleSelectAll = () => {
        if (selectedLoads.size > 0) {
            // If any loads are selected, unselect all
            setSelectedLoads(new Set())
        } else {
            // If no loads are selected, select all
            const newSelectedLoads = new Set(filteredLoads.map((load) => load.matchId))
            setSelectedLoads(newSelectedLoads)
        }
    }

    const eligibleLoadsForRateRequest = useMemo(() => {
        return Array.from(selectedLoads).filter((loadId) => {
            const load = loads.find((l) => l.matchId === loadId)
            return (
                load &&
                load.posterInfo?.contact?.email &&
                (!load.rateInfo?.rateUsd || load.rateInfo.rateUsd <= 0) &&
                !load.isEmailSent &&
                !emailSentLoads.has(loadId)
            )
        }).length
    }, [loads, selectedLoads, emailSentLoads])

    const allSelectedLoadsAreEligible = useMemo(() => {
        return selectedLoads.size > 0 && selectedLoads.size === eligibleLoadsForRateRequest
    }, [selectedLoads.size, eligibleLoadsForRateRequest])

    const handleRequestRateMultiple = async () => {
        setIsRequestRateLoading(true)
        const loadsWithEmail = Array.from(selectedLoads)
            .map((loadId) => loads.find((l) => l.matchId === loadId))
            .filter(
                (load): load is DatabaseLoad =>
                    !!load &&
                    !!load.posterInfo?.contact?.email &&
                    (!load.rateInfo?.rateUsd || load.rateInfo.rateUsd <= 0) &&
                    !load.isEmailSent &&
                    !emailSentLoads.has(load.matchId)
            )

        if (loadsWithEmail.length === 0) {
            setIsRequestRateLoading(false)
            setSnackbar({
                open: true,
                message: 'No loads without a rate and with an email are selected',
                color: 'danger',
            })
            return
        }

        try {
            // Send emails and update status in parallel
            const emailPromises = loadsWithEmail.map((load) =>
                sendInfoEmail(load, threadId || '', projectName || '', (success) => {
                    if (success) {
                        updateEmailStatus(load.matchId)
                    }
                })
            )

            await Promise.all(emailPromises)
            setSnackbar({
                open: true,
                message: `Successfully sent rate request emails to ${loadsWithEmail.length} load${loadsWithEmail.length > 1 ? 's' : ''}`,
                color: 'success',
            })
            setSelectedLoads(new Set())
        } catch (error) {
            console.error('Error sending emails:', error)
            setSnackbar({
                open: true,
                message: 'Failed to send rate requests',
                color: 'danger',
            })
        } finally {
            setIsRequestRateLoading(false)
        }
    }

    const handleSnackbarClose = () => {
        setSnackbar((prev) => ({ ...prev, open: false }))
    }

    const handleEmailClick = async (e: React.MouseEvent, load: DatabaseLoad) => {
        e.stopPropagation()
        if (!load.rateInfo?.rateUsd) {
            // If rate is not available, send info email directly
            setEmailSendingLoadId(load.matchId)
            try {
                await sendInfoEmail(
                    load,
                    threadId || '',
                    projectName || '',
                    (success) => {
                        setSnackbar({
                            open: true,
                            message: success ? 'Email sent successfully!' : 'Failed to send email',
                            color: success ? 'success' : 'danger',
                        })
                    },
                    updateEmailStatus
                )
            } finally {
                setEmailSendingLoadId(null)
            }
        } else {
            // If rate is available, open the modal
            handleRowClick(load)
        }
    }

    const convertPickupDates = (earliestAvailability: string, latestAvailability: string): JSX.Element => {
        const formatDate = (date: Date): string => {
            const month = (date.getMonth() + 1).toString()
            const day = date.getDate().toString()
            return `${month}/${day}`
        }

        const earliestDate = formatDate(new Date(earliestAvailability))
        const latestDate = formatDate(new Date(latestAvailability))

        if (earliestDate === latestDate) {
            return <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{earliestDate}</Typography>
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{earliestDate} -</Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{latestDate}</Typography>
            </Box>
        )
    }

    const open = Boolean(anchorEl)
    const popoverId = open ? 'sort-popover' : undefined
    const colors = tableColors[mode as 'light' | 'dark']

    function handleConfirmationPopoverOpen(e: React.MouseEvent<HTMLButtonElement>, matchId: string): void {
        e.stopPropagation()
        setAnchorEl(e.currentTarget)
        setSelectedLoadId(matchId)
    }

    const handleConfirmationPopoverClose = () => {
        setAnchorEl(null)
        setSelectedLoadId(null)
    }

    const handleConfirm = () => {
        if (selectedLoadId) {
            // Handle the confirmation action here
            const selectedLoad = loads.find((load) => load.matchId === selectedLoadId)
            if (selectedLoad?.bookingUrl) {
                window.open(selectedLoad.bookingUrl, '_blank')
            }
        }
        handleConfirmationPopoverClose()
    }

    return (
        <Box sx={{ width: '100%', position: 'relative' }}>
            <Snackbar
                variant="soft"
                color={snackbar.color}
                open={snackbar.open}
                onClose={handleSnackbarClose}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{
                    maxWidth: '400px',
                    width: '100%',
                    '& .MuiSnackbar-content': {
                        fontSize: '1rem',
                        padding: '1rem',
                        justifyContent: 'center',
                    },
                }}
            >
                {snackbar.message}
            </Snackbar>
            {messagesLoading || onLoad ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: tableColors[mode as 'light' | 'dark'].overlay,
                        zIndex: 1,
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : null}
            {loads && loads.length > 0 ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                        mt: 1.5,
                        height: '100%',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'end', width: '100%', justifyContent: 'space-between', gap: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '1rem',
                                        color: 'text.secondary',
                                        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#2b2b2b' : '#f0f0f0'),
                                        padding: '0.5rem 1rem',
                                        borderRadius: '0.25rem',
                                        fontWeight: 500,
                                    }}
                                >
                                    {filteredLoads.length} Results
                                </Typography>
                                <Box>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Input
                                            size="sm"
                                            placeholder="Phone number"
                                            value={phoneNumberTest}
                                            onChange={(e) => setPhoneNumberTest(e.target.value)}
                                            startDecorator={<CallIcon />}
                                            sx={{ width: 200 }}
                                        />
                                    </Stack>
                                </Box>
                            </Box>
                            {selectedLoads.size > 0 && eligibleLoadsForRateRequest > 0 && (
                                <Tooltip
                                    title={
                                        !allSelectedLoadsAreEligible
                                            ? 'Some selected loads are not eligible (must have email, no rate, and no email sent)'
                                            : `Will send rate request emails to ${eligibleLoadsForRateRequest} selected load(s)`
                                    }
                                    variant="soft"
                                >
                                    <span>
                                        <Button
                                            variant="soft"
                                            color="primary"
                                            disabled={!allSelectedLoadsAreEligible}
                                            onClick={handleRequestRateMultiple}
                                            startDecorator={isRequestRateLoading ? <CircularProgress size="sm" /> : null}
                                        >
                                            Request Rate ({eligibleLoadsForRateRequest})
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center',
                                    backgroundColor: mode === 'light' ? 'rgba(25, 118, 210, 0.08)' : 'rgba(144, 202, 249, 0.08)',
                                    padding: '8px 16px',
                                    borderRadius: '8px',
                                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                        '-webkit-appearance': 'none',
                                        margin: 0,
                                    },
                                    '& input[type=number]': {
                                        '-moz-appearance': 'textfield',
                                    },
                                }}
                            >
                                <Typography level="body-sm" sx={{ color: mode === 'light' ? 'text.secondary' : 'text.primary' }}>
                                    Filters:
                                </Typography>
                                <Input
                                    size="sm"
                                    placeholder="Max miles"
                                    value={maxMiles}
                                    onChange={(e) => setMaxMiles(e.target.value)}
                                    type="number"
                                    sx={{
                                        width: '100px',
                                        backgroundColor: mode === 'light' ? 'background.body' : 'background.level1',
                                    }}
                                    slotProps={{
                                        input: {
                                            min: 0,
                                        },
                                    }}
                                />
                                <Input
                                    size="sm"
                                    placeholder="Min miles"
                                    value={minMiles}
                                    onChange={(e) => setMinMiles(e.target.value)}
                                    type="number"
                                    sx={{
                                        width: '100px',
                                        backgroundColor: mode === 'light' ? 'background.body' : 'background.level1',
                                    }}
                                    slotProps={{
                                        input: {
                                            min: 0,
                                        },
                                    }}
                                />
                                <Input
                                    size="sm"
                                    placeholder="Min rate"
                                    value={minRate}
                                    onChange={(e) => setMinRate(e.target.value)}
                                    type="number"
                                    sx={{
                                        width: '100px',
                                        backgroundColor: mode === 'light' ? 'background.body' : 'background.level1',
                                    }}
                                    slotProps={{
                                        input: {
                                            min: 0,
                                        },
                                    }}
                                />
                                <Button
                                    size="sm"
                                    variant="soft"
                                    color="neutral"
                                    onClick={() => {
                                        setMaxMiles('')
                                        setMinMiles('')
                                        setMinRate('')
                                    }}
                                    sx={{
                                        backgroundColor: mode === 'light' ? 'rgba(25, 118, 210, 0.08)' : 'rgba(144, 202, 249, 0.08)',
                                        '&:hover': {
                                            backgroundColor: mode === 'light' ? 'rgba(25, 118, 210, 0.12)' : 'rgba(144, 202, 249, 0.12)',
                                        },
                                    }}
                                >
                                    Reset
                                </Button>
                            </Box>
                            <Button onClick={handleHideLoads} variant="soft" color="neutral" disabled={selectedLoads.size === 0} sx={{ ml: 1 }}>
                                Hide selected ({selectedLoads.size})
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', maxWidth: '100%', height: '100%', overflow: 'auto', mt: 2 }}>
                        <Sheet
                            variant="outlined"
                            sx={{
                                bgcolor: colors.background,
                                borderRadius: '8px',
                                boxShadow: 'sm',
                                width: '100%',
                                height: 'calc(100vh - 19rem)',
                                maxWidth: '100%',
                                overflow: 'auto',
                            }}
                        >
                            <Table
                                className="loads-table-large"
                                sx={{
                                    '& thead': {
                                        bgcolor: colors.headerBg,
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                    },
                                    '& th': {
                                        color: colors.text,
                                        fontWeight: 'bold',
                                        fontSize: 'sm',
                                        margin: '4px',
                                        position: 'relative',
                                        '& span': {
                                            color: colors.text,
                                        },
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: '50%',
                                            right: 0,
                                            height: '50%',
                                            width: '1px',
                                            backgroundColor: colors.separator,
                                            transform: 'translateY(-50%)',
                                        },
                                        '&:last-of-type:before': {
                                            content: 'none',
                                        },
                                    },
                                    '& td': {
                                        fontSize: 'sm',
                                        margin: '4px',
                                        position: 'relative',
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: '50%',
                                            right: 0,
                                            height: '50%',
                                            width: '1px',
                                            backgroundColor: colors.separator,
                                            transform: 'translateY(-50%)',
                                        },
                                        '&:last-of-type:before': {
                                            content: 'none',
                                        },
                                    },
                                    '& tbody tr:nth-of-type(odd) td': {
                                        bgcolor: colors.rowOddBg,
                                    },
                                    '& tbody tr:nth-of-type(even) td': {
                                        bgcolor: colors.rowEvenBg,
                                    },
                                    '& tbody tr:hover td': {
                                        bgcolor: `${colors.hoverBg} !important`,
                                    },
                                    '--TableCell-height': '40px',
                                    '--Table-headerUnderlineThickness': '1px',
                                    '--TableRow-hoverBackground': colors.hoverBg,
                                    borderRadius: 'lg',
                                    maxWidth: '100%',
                                    overflow: 'auto',
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th onClick={(e) => e.stopPropagation()} style={{ width: 25 }}>
                                            <Tooltip title="Select/Unselect all loads" variant="soft">
                                                <Checkbox
                                                    checked={selectedLoads.size > 0}
                                                    indeterminate={selectedLoads.size > 0 && selectedLoads.size < filteredLoads.length}
                                                    onChange={handleSelectAll}
                                                    sx={{ p: 0 }}
                                                />
                                            </Tooltip>
                                        </th>
                                        <th style={{ width: 50, textAlign: 'center' }}>
                                            <Tooltip title="Source of the load" variant="soft">
                                                <span>Source</span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'age')} style={{ width: 40, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Age of the load posting" variant="soft">
                                                <span>
                                                    Age
                                                    <ArrowDropDownIcon style={getIconStyle('ageNewest', 'age')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'rate')} style={{ width: 50, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Trip rate" variant="soft">
                                                <span>
                                                    Rate <ArrowDropDownIcon style={getIconStyle('rateHighest', 'rate')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th style={{ width: 40, textAlign: 'center' }}>
                                            <Tooltip title="Email status" variant="soft">
                                                <span>Email</span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'factorable')} style={{ width: 30, cursor: 'pointer' }}>
                                            <Tooltip title="Load is eligible for factoring" variant="soft">
                                                <Box display="flex" alignItems="center" justifyContent="center">
                                                    <InfoOutlinedIcon sx={{ fontSize: 20, color: colors.text }} />
                                                    <ArrowDropDownIcon style={getIconStyle('factorableFirst', 'factorable')} fontSize="medium" />
                                                </Box>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'trip')} style={{ width: 50, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Trip distance" variant="soft">
                                                <span>
                                                    Trip <ArrowDropDownIcon style={getIconStyle('tripShortest', 'trip')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'origin')} style={{ width: 95, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Origin city and state" variant="soft">
                                                <span style={{ color: 'inherit' }}>
                                                    Origin <ArrowDropDownIcon style={getIconStyle('originAToZ', 'origin')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'deadheadO')} style={{ width: 50, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Origin deadhead miles" variant="soft">
                                                <span>
                                                    DH-O <ArrowDropDownIcon style={getIconStyle('deadheadOShortest', 'deadheadO')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th style={{ width: 25 }}></th>
                                        <th onClick={(e) => handleHeaderClick(e, 'destination')} style={{ width: 95, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Destination city and state" variant="soft">
                                                <span style={{ color: 'inherit' }}>
                                                    Destination <ArrowDropDownIcon style={getIconStyle('destinationAToZ', 'destination')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'deadheadD')} style={{ width: 50, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Destination deadhead miles" variant="soft">
                                                <span>
                                                    DH-D <ArrowDropDownIcon style={getIconStyle('deadheadDShortest', 'deadheadD')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'pickUp')} style={{ width: 55, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Pick up dates" variant="soft">
                                                <span>
                                                    Pick up <ArrowDropDownIcon style={getIconStyle('pickUpEarliestFirst', 'pickUp')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th style={{ width: 30, textAlign: 'center' }}>
                                            <Tooltip title="Equipment type required" variant="soft">
                                                <span>EQ</span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'length')} style={{ width: 55, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Trip length" variant="soft">
                                                <span>
                                                    Length <ArrowDropDownIcon style={getIconStyle('lengthLongest', 'length')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'weight')} style={{ width: 65, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Load weight" variant="soft">
                                                <span>
                                                    Weight <ArrowDropDownIcon style={getIconStyle('weightLightest', 'weight')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'capacity')} style={{ width: 65, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Truck capacity required" variant="soft">
                                                <span>
                                                    Capacity <ArrowDropDownIcon style={getIconStyle('capacityFullFirst', 'capacity')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'company')} style={{ width: 105, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Company information" variant="soft">
                                                <span>
                                                    Company <ArrowDropDownIcon style={getIconStyle('companyNameAToZ', 'company')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'contact')} style={{ width: 120, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Contact information" variant="soft">
                                                <span>
                                                    Contact <ArrowDropDownIcon style={getIconStyle('contactEmailFirst', 'contact')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'creditScore')} style={{ width: 60, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Credit score and days to pay" variant="soft">
                                                <span>
                                                    CS/DTP <ArrowDropDownIcon style={getIconStyle('creditScoreHighest', 'creditScore')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'book')} style={{ width: 60, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Bookable status" variant="soft">
                                                <span>
                                                    Book <ArrowDropDownIcon style={getIconStyle('bookableFirst', 'book')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <Popover
                                    id={popoverId}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClosePopover}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <List>
                                        {activeColumn === 'age' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('ageNewest')}>
                                                        <ListItemText primary="Newest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'ageNewest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('ageOldest')}>
                                                        <ListItemText primary="Oldest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'ageOldest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'rate' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('rateHighest')}>
                                                        <ListItemText primary="Highest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'rateHighest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('rateLowest')}>
                                                        <ListItemText primary="Lowest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'rateLowest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('ratePerMileHighest')}>
                                                        <ListItemText primary="Per Mile - Highest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'ratePerMileHighest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('ratePerMileLowest')}>
                                                        <ListItemText primary="Per Mile - Lowest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'ratePerMileLowest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'trip' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('tripShortest')}>
                                                        <ListItemText primary="Shortest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'tripShortest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('tripLongest')}>
                                                        <ListItemText primary="Longest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'tripLongest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'origin' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('originAToZ')}>
                                                        <ListItemText primary="A to Z" />
                                                        {sortConfigs[activeColumn || '']?.key === 'originAToZ' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('originZToA')}>
                                                        <ListItemText primary="Z to A" />
                                                        {sortConfigs[activeColumn || '']?.key === 'originZToA' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'deadheadO' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('deadheadOShortest')}>
                                                        <ListItemText primary="Shortest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'deadheadOShortest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('deadheadOLongest')}>
                                                        <ListItemText primary="Longest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'deadheadOLongest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'destination' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('destinationAToZ')}>
                                                        <ListItemText primary="A to Z" />
                                                        {sortConfigs[activeColumn || '']?.key === 'destinationAToZ' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('destinationZToA')}>
                                                        <ListItemText primary="Z to A" />
                                                        {sortConfigs[activeColumn || '']?.key === 'destinationZToA' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'deadheadD' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('deadheadDShortest')}>
                                                        <ListItemText primary="Shortest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'deadheadDShortest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('deadheadDLongest')}>
                                                        <ListItemText primary="Longest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'deadheadDLongest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'pickUp' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('pickUpEarliestFirst')}>
                                                        <ListItemText primary="Earliest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'pickUpEarliestFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('pickUpLatestFirst')}>
                                                        <ListItemText primary="Latest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'pickUpLatestFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'length' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('lengthLongest')}>
                                                        <ListItemText primary="Longest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'lengthLongest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('lengthShortest')}>
                                                        <ListItemText primary="Shortest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'lengthShortest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'weight' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('weightLightest')}>
                                                        <ListItemText primary="Lightest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'weightLightest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('weightHeaviest')}>
                                                        <ListItemText primary="Heaviest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'weightHeaviest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'capacity' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('capacityFullFirst')}>
                                                        <ListItemText primary="Full First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'capacityFullFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('capacityPartialFirst')}>
                                                        <ListItemText primary="Partial First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'capacityPartialFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'company' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('companyNameAToZ')}>
                                                        <ListItemText primary="A to Z" />
                                                        {sortConfigs[activeColumn || '']?.key === 'companyNameAToZ' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('companyNameZToA')}>
                                                        <ListItemText primary="Z to A" />
                                                        {sortConfigs[activeColumn || '']?.key === 'companyNameZToA' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'contact' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('contactEmailFirst')}>
                                                        <ListItemText primary="Email First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'contactEmailFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('contactPhoneFirst')}>
                                                        <ListItemText primary="Phone First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'contactPhoneFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'creditScore' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('creditScoreHighest')}>
                                                        <ListItemText primary="Credit Score - Highest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'creditScoreHighest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('creditScoreLowest')}>
                                                        <ListItemText primary="Credit Score - Lowest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'creditScoreLowest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('daysToPayHighest')}>
                                                        <ListItemText primary="Days to Pay - Highest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'daysToPayHighest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('daysToPayLowest')}>
                                                        <ListItemText primary="Days to Pay - Lowest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'daysToPayLowest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'factorable' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('factorableFirst')}>
                                                        <ListItemText primary="Factorable First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'factorableFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('unfactorableFirst')}>
                                                        <ListItemText primary="Unfactorable First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'unfactorableFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'book' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('bookableFirst')}>
                                                        <ListItemText primary="Bookable First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'bookableFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('unbookableFirst')}>
                                                        <ListItemText primary="Unbookable First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'unbookableFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                    </List>
                                </Popover>
                                <Popover
                                    open={Boolean(anchorEl) && selectedLoadId !== null}
                                    anchorEl={anchorEl}
                                    onClose={handleConfirmationPopoverClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Box sx={{ p: 2 }}>
                                        <Typography sx={{ mb: 2 }}>Are you sure you want to book this load?</Typography>
                                        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                                            <Button variant="outlined" color="neutral" onClick={handleConfirmationPopoverClose}>
                                                Cancel
                                            </Button>
                                            <Button variant="solid" onClick={handleConfirm}>
                                                Confirm
                                            </Button>
                                        </Box>
                                    </Box>
                                </Popover>
                                <tbody style={{ height: '100%', maxHeight: '100%', overflow: 'auto' }}>
                                    {sortedLoads.map((load: DatabaseLoad) => (
                                        <React.Fragment key={load.matchId}>
                                            <tr
                                                key={load.matchId}
                                                style={{
                                                    backgroundColor:
                                                        load.status === 'cancelled'
                                                            ? tableColors[mode as 'light' | 'dark'].cancelled.bg
                                                            : tableColors[mode as 'light' | 'dark'].background,
                                                    cursor: 'default',
                                                    pointerEvents: load.status === 'cancelled' ? 'none' : 'auto',
                                                    opacity: load.status === 'cancelled' ? 0.9 : 1,
                                                    color:
                                                        load.status === 'cancelled'
                                                            ? tableColors[mode as 'light' | 'dark'].cancelled.text
                                                            : tableColors[mode as 'light' | 'dark'].text,
                                                }}
                                                onClick={() => (load.status === 'cancelled' ? null : handleRowClick(load))}
                                            >
                                                <td onClick={(e) => e.stopPropagation()}>
                                                    <Checkbox
                                                        checked={selectedLoads.has(load.matchId)}
                                                        onChange={(e) => handleCheckboxClick(e, load.matchId)}
                                                        sx={{ p: 0 }}
                                                    />
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        {load.type === 'EMAIL' ? (
                                                            <img src="/gmail-svgrepo-com.svg" alt="DAT" style={{ height: '20px' }} />
                                                        ) : (
                                                            <img src="/dat-logo.jpg" alt="DAT" style={{ height: '20px' }} />
                                                        )}
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            {load.postedAt ? timeDisplay(load.postedAt) : '—'}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        {load.rateInfo?.rateUsd && load.rateInfo.rateUsd > 0 ? (
                                                            <Box sx={{ width: '100%' }}>
                                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: 'center' }}>
                                                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                        ${load.rateInfo.rateUsd.toLocaleString()}
                                                                    </Typography>
                                                                    <Typography sx={{ fontSize: '12px' }}>
                                                                        {calculateRatePerMile(load.tripLength, load.rateInfo.rateUsd)}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        ) : (
                                                            '—'
                                                        )}
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        {load.posterInfo?.contact?.email ? (
                                                            emailSentLoads.has(load.matchId) || load.isEmailSent ? (
                                                                <Tooltip title="Email is sent" variant="solid">
                                                                    <CheckCircleIcon color="success" />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="Click to send email" variant="solid">
                                                                    {emailSendingLoadId === load.matchId ? (
                                                                        <CircularProgress size="sm" />
                                                                    ) : (
                                                                        <IconButton size="sm" onClick={(e) => handleEmailClick(e, load)}>
                                                                            <EmailIcon color="primary" />
                                                                        </IconButton>
                                                                    )}
                                                                </Tooltip>
                                                            )
                                                        ) : !load.rateInfo?.rateUsd ? (
                                                            <Tooltip title="Click to call" variant="solid">
                                                                {callingLoadId && callingLoadId === load.matchId ? (
                                                                    <CircularProgress size="sm" />
                                                                ) : (
                                                                    <IconButton
                                                                        size="sm"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            console.log(phoneNumberTest, 'phoneNumberTest')
                                                                            handlePhoneClick(load, phoneNumberTest)
                                                                        }}
                                                                    >
                                                                        <CallIcon color="primary" />
                                                                    </IconButton>
                                                                )}
                                                            </Tooltip>
                                                        ) : load.callHistory && load.callHistory.length > 0 && load.rateInfo?.rateUsd ? (
                                                            <TrunscriptionTooltip maxHeight={500} transcripts={load.callHistory[0].transcripts}>
                                                                <CallEndIcon color="success" />
                                                            </TrunscriptionTooltip>
                                                        ) : (
                                                            '—'
                                                        )}
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        {load.isFactorable ? <CheckIcon sx={{ color: tableColors[mode as 'light' | 'dark'].svgColor }} /> : '—'}
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontWeight: 'bold',
                                                                color: tableColors[mode as 'light' | 'dark'].tripColor,
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {load.tripLength || '-'}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                            {load.origin.city}, {load.origin.stateProv}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>
                                                            {load.originDeadheadMiles?.miles}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <HorizontalDirectionIcon />
                                                </td>
                                                <td>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                            {load.destination.city}, {load.destination.stateProv}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: 1,
                                                        }}
                                                    >
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>
                                                            {load.destinationDeadheadMiles?.miles}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    {load.earliestAvailability && load.latestAvailability
                                                        ? convertPickupDates(load.earliestAvailability, load.latestAvailability)
                                                        : '--'}
                                                </td>
                                                <td>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            flexDirection: 'column',
                                                            gap: 1,
                                                            width: '2rem',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontWeight: 'bold',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {load.equipmentType || '--'}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                            {load.shipmentDetails?.maximumLengthFeet ? load.shipmentDetails?.maximumLengthFeet + ' ft' : '-'}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                            {load.shipmentDetails?.maximumWeightPounds
                                                                ? Number(load.shipmentDetails?.maximumWeightPounds).toLocaleString() + ' lbs'
                                                                : '-'}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                            {load.shipmentDetails?.fullPartial}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td className="contact-td">
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3 }}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            {load.posterInfo?.companyName}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            {load.posterInfo?.contact?.phoneNumber ? (
                                                                <a
                                                                    href={`tel:${load.posterInfo?.contact?.phoneNumber}`}
                                                                    style={{
                                                                        textDecoration: 'underline',
                                                                        color:
                                                                            load.status === 'cancelled'
                                                                                ? '#FFFFFF'
                                                                                : tableColors[mode as 'light' | 'dark'].linkColor,
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    {formatPhoneNumber(load.posterInfo?.contact?.phoneNumber)}
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    href={`mailto:${load.posterInfo?.contact?.email}`}
                                                                    style={{
                                                                        textDecoration: 'underline',
                                                                        color:
                                                                            load.status === 'cancelled'
                                                                                ? '#FFFFFF'
                                                                                : tableColors[mode as 'light' | 'dark'].linkColor,
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    {load.posterInfo?.contact?.email}
                                                                </a>
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                            {load.posterInfo?.credit?.creditScore || '-'} CS
                                                        </Typography>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                            {load.posterInfo?.credit?.daysToPay || '-'} DTP
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        {load.bookingUrl ? (
                                                            <Button
                                                                variant="outlined"
                                                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                                    e.stopPropagation()
                                                                    handleConfirmationPopoverOpen(e, load.matchId)
                                                                }}
                                                            >
                                                                Book
                                                            </Button>
                                                        ) : (
                                                            '—'
                                                        )}
                                                    </Box>
                                                </td>
                                            </tr>
                                            {expandedLoads.has(load.matchId) && (
                                                <tr>
                                                    <td colSpan={colspan}>
                                                        <Box
                                                            sx={{
                                                                p: 2,
                                                                backgroundColor: tableColors[mode as 'light' | 'dark'].accordion.bg,
                                                                borderBottom: '1px solid',
                                                                borderColor: tableColors[mode as 'light' | 'dark'].accordion.border,
                                                                animation: 'expandDown 0.3s ease-out forwards',
                                                                '@keyframes expandDown': {
                                                                    from: {
                                                                        opacity: 0,
                                                                        transform: 'scaleY(0)',
                                                                        transformOrigin: 'top',
                                                                    },
                                                                    to: {
                                                                        opacity: 1,
                                                                        transform: 'scaleY(1)',
                                                                        transformOrigin: 'top',
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {/* Header showing origin and destination */}
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: 1,
                                                                    mb: 2,
                                                                    pb: 2,
                                                                    borderBottom: '1px solid',
                                                                    borderColor: tableColors[mode as 'light' | 'dark'].accordion.border,
                                                                }}
                                                            >
                                                                <Typography
                                                                    level="title-lg"
                                                                    sx={{
                                                                        fontSize: '1.8rem',
                                                                        fontWeight: 'bold',
                                                                        display: 'flex',
                                                                        gap: 2,
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    {load.origin.city}
                                                                    <HorizontalDirectionIcon svgColor={tableColors[mode as 'light' | 'dark'].svgColor} />
                                                                    {load.destination.city}
                                                                </Typography>
                                                                <Typography
                                                                    level="body-sm"
                                                                    sx={{
                                                                        fontSize: '1.3rem',
                                                                        fontWeight: 'bold',
                                                                    }}
                                                                >
                                                                    {load.tripLength && `${load.tripLength} ml`}
                                                                </Typography>
                                                            </Box>

                                                            {/* Accordion Content */}
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    gap: 3,
                                                                    flexWrap: 'wrap',
                                                                }}
                                                            >
                                                                {/* Trip Info and Shipment Details Combined */}
                                                                <Box
                                                                    sx={{
                                                                        flex: '1 1 25%',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        gap: 2,
                                                                        minWidth: '250px',
                                                                    }}
                                                                >
                                                                    {/* Trip Info */}
                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                                        {/* Header */}
                                                                        <Box
                                                                            sx={{
                                                                                backgroundColor: '#f5f5f5',
                                                                                px: 1,
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    fontWeight: 'bold',
                                                                                    fontSize: '0.9rem',
                                                                                    color: tableColors[mode as 'light' | 'dark'].accordion.title,
                                                                                }}
                                                                            >
                                                                                Trip
                                                                            </Typography>
                                                                        </Box>

                                                                        {/* Content */}
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                                                            {/* Icon Section */}
                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                <VerticalDirectionIcon />
                                                                            </Box>

                                                                            {/* Origin and Destination Details */}
                                                                            <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    justifyContent: 'space-between',
                                                                                    gap: 1,
                                                                                }}
                                                                            >
                                                                                {/* Origin */}
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                        alignItems: 'center',
                                                                                        gap: 1,
                                                                                    }}
                                                                                >
                                                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                                        <Typography
                                                                                            level="body-sm"
                                                                                            sx={{
                                                                                                fontWeight: 'bold',
                                                                                                fontSize: '1.1rem',
                                                                                                color: tableColors[mode as 'light' | 'dark'].text,
                                                                                            }}
                                                                                        >
                                                                                            {`${load.origin.city}, ${load.origin.stateProv} (${load.originDeadheadMiles?.miles})`}
                                                                                        </Typography>
                                                                                        <Typography>
                                                                                            {new Date(load.earliestAvailability)
                                                                                                .toDateString()
                                                                                                .split(' ')
                                                                                                .slice(1, 3)
                                                                                                .join(' ') ===
                                                                                            new Date(load.latestAvailability)
                                                                                                .toDateString()
                                                                                                .split(' ')
                                                                                                .slice(1, 3)
                                                                                                .join(' ')
                                                                                                ? new Date(load.earliestAvailability)
                                                                                                      .toDateString()
                                                                                                      .split(' ')
                                                                                                      .slice(1, 3)
                                                                                                      .join(' ')
                                                                                                : `${new Date(load.earliestAvailability).toDateString().split(' ').slice(1, 3).join(' ')} - ${new Date(load.latestAvailability).toDateString().split(' ').slice(1, 3).join(' ')}`}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>

                                                                                {/* Destination */}
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                        alignItems: 'center',
                                                                                        gap: 1,
                                                                                    }}
                                                                                >
                                                                                    <Typography
                                                                                        level="body-sm"
                                                                                        sx={{
                                                                                            fontWeight: 'bold',
                                                                                            fontSize: '1.1rem',
                                                                                            color: tableColors[mode as 'light' | 'dark'].text,
                                                                                        }}
                                                                                    >
                                                                                        {`${load.destination.city}, ${load.destination.stateProv}`}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>

                                                                    {/* Shipment Details */}
                                                                    <Box sx={{ width: '10rem' }}>
                                                                        {/* Section Title */}
                                                                        <Box
                                                                            sx={{
                                                                                mt: 2,
                                                                                mb: 1,
                                                                                fontWeight: 'bold',
                                                                                fontSize: '0.9rem',
                                                                                color: tableColors[mode as 'light' | 'dark'].accordion.sectionTitle,
                                                                            }}
                                                                        >
                                                                            Equipment
                                                                        </Box>
                                                                        {/* Equipment Details */}
                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                                                            {/* Truck */}
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{
                                                                                        fontSize: '0.8rem',
                                                                                        fontWeight: 'bold',
                                                                                        color: tableColors[mode as 'light' | 'dark'].accordion.label,
                                                                                    }}
                                                                                >
                                                                                    Truck
                                                                                </Typography>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{
                                                                                        fontSize: '0.8rem',
                                                                                        color: tableColors[mode as 'light' | 'dark'].accordion.text,
                                                                                    }}
                                                                                >
                                                                                    {load.equipmentType || '—'}
                                                                                </Typography>
                                                                            </Box>
                                                                            {/* Load */}
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{
                                                                                        fontSize: '0.8rem',
                                                                                        fontWeight: 'bold',
                                                                                        color: tableColors[mode as 'light' | 'dark'].accordion.label,
                                                                                    }}
                                                                                >
                                                                                    Load
                                                                                </Typography>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{
                                                                                        fontSize: '0.8rem',
                                                                                        color: tableColors[mode as 'light' | 'dark'].accordion.text,
                                                                                    }}
                                                                                >
                                                                                    {load.shipmentDetails?.fullPartial || '—'}
                                                                                </Typography>
                                                                            </Box>
                                                                            {/* Length */}
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{
                                                                                        fontSize: '0.8rem',
                                                                                        fontWeight: 'bold',
                                                                                        color: tableColors[mode as 'light' | 'dark'].accordion.label,
                                                                                    }}
                                                                                >
                                                                                    Length
                                                                                </Typography>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{
                                                                                        fontSize: '0.8rem',
                                                                                        color: tableColors[mode as 'light' | 'dark'].accordion.text,
                                                                                    }}
                                                                                >
                                                                                    {load.shipmentDetails?.maximumLengthFeet
                                                                                        ? `${load.shipmentDetails?.maximumLengthFeet} ft`
                                                                                        : '—'}
                                                                                </Typography>
                                                                            </Box>
                                                                            {/* Weight */}
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{
                                                                                        fontSize: '0.8rem',
                                                                                        fontWeight: 'bold',
                                                                                        color: tableColors[mode as 'light' | 'dark'].accordion.label,
                                                                                    }}
                                                                                >
                                                                                    Weight
                                                                                </Typography>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{
                                                                                        fontSize: '0.8rem',
                                                                                        color: tableColors[mode as 'light' | 'dark'].accordion.text,
                                                                                    }}
                                                                                >
                                                                                    {load.shipmentDetails?.maximumWeightPounds
                                                                                        ? `${load.shipmentDetails?.maximumWeightPounds.toLocaleString()} lbs`
                                                                                        : '—'}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>

                                                                    {/* Contact Information */}
                                                                    <Box>
                                                                        <Box
                                                                            sx={{
                                                                                mt: 2,
                                                                                fontWeight: 'bold',
                                                                                fontSize: '0.9rem',
                                                                                color: tableColors[mode as 'light' | 'dark'].accordion.sectionTitle,
                                                                            }}
                                                                        >
                                                                            Contact Information
                                                                        </Box>
                                                                        <Box sx={{ mt: 0.5 }}>
                                                                            <Typography
                                                                                level="body-sm"
                                                                                sx={{
                                                                                    fontSize: '0.8rem',
                                                                                    color: tableColors[mode as 'light' | 'dark'].linkColor,
                                                                                    fontWeight: 'bold',
                                                                                    cursor: 'pointer',
                                                                                    textDecoration: 'underline',
                                                                                }}
                                                                                onClick={() => {
                                                                                    const contact = load.posterInfo.contact
                                                                                    if (contact.email) {
                                                                                        window.location.href = `mailto:${contact.email}`
                                                                                    } else if (contact.phoneNumber) {
                                                                                        window.location.href = `tel:${contact.phoneNumber}`
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {load.posterInfo.contact.email || load.posterInfo.contact.phoneNumber || 'N/A'}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>

                                                                    {load.comments && (
                                                                        <Box
                                                                            sx={{
                                                                                width: '100%',
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                mt: 2,
                                                                                fontWeight: 'bold',
                                                                                fontSize: '0.9rem',
                                                                                color: colors.accordion.sectionTitle,
                                                                            }}
                                                                        >
                                                                            <Typography>Comments:</Typography>
                                                                            <Typography>{load.comments}</Typography>
                                                                        </Box>
                                                                    )}
                                                                </Box>

                                                                {/* Rate Information or Email Form */}
                                                                {(load.rateInfo?.rateUsd || load.posterInfo.contact?.email) && (
                                                                    <Box sx={{ flex: '1 1 33%' }}>
                                                                        {load.rateInfo?.rateUsd && (
                                                                            <>
                                                                                <Box
                                                                                    sx={{
                                                                                        backgroundColor: '#f5f5f5',
                                                                                        px: 1,
                                                                                    }}
                                                                                >
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontWeight: 'bold',
                                                                                            fontSize: '0.9rem',
                                                                                            mb: 2,
                                                                                            color: tableColors[mode as 'light' | 'dark'].accordion.title,
                                                                                        }}
                                                                                    >
                                                                                        Rate
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column',
                                                                                        gap: 1,
                                                                                        width: '10rem',
                                                                                        mb: 2,
                                                                                    }}
                                                                                >
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <Typography
                                                                                            level="body-sm"
                                                                                            sx={{
                                                                                                color: tableColors[mode as 'light' | 'dark'].accordion.label,
                                                                                                fontSize: '0.8rem',
                                                                                            }}
                                                                                        >
                                                                                            Total Rate
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            level="body-sm"
                                                                                            sx={{
                                                                                                color: tableColors[mode as 'light' | 'dark'].accordion.text,
                                                                                                fontSize: '0.8rem',
                                                                                            }}
                                                                                        >
                                                                                            ${load.rateInfo.rateUsd.toLocaleString()}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <Typography
                                                                                            level="body-sm"
                                                                                            sx={{
                                                                                                color: tableColors[mode as 'light' | 'dark'].accordion.label,
                                                                                                fontSize: '0.8rem',
                                                                                            }}
                                                                                        >
                                                                                            Rate/Mile
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            level="body-sm"
                                                                                            sx={{
                                                                                                color: tableColors[mode as 'light' | 'dark'].accordion.text,
                                                                                                fontSize: '0.8rem',
                                                                                            }}
                                                                                        >
                                                                                            {calculateRatePerMile(load.tripLength, load.rateInfo.rateUsd)}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </>
                                                                        )}
                                                                        {load.posterInfo.contact?.email && (
                                                                            <Box sx={{ mt: load.rateInfo?.rateUsd ? 2 : 0, width: '100%' }}>
                                                                                <EmailModal
                                                                                    open={true}
                                                                                    onClose={() => {}}
                                                                                    load={load}
                                                                                    embedded={true}
                                                                                    onEmailSent={(success) => {
                                                                                        setSnackbar({
                                                                                            open: true,
                                                                                            message: success
                                                                                                ? 'Email sent successfully!'
                                                                                                : 'Failed to send email',
                                                                                            color: success ? 'success' : 'danger',
                                                                                        })
                                                                                    }}
                                                                                    onLoadUpdate={updateEmailStatus}
                                                                                />
                                                                            </Box>
                                                                        )}
                                                                    </Box>
                                                                )}

                                                                {/* Company Information */}
                                                                <Box
                                                                    sx={{
                                                                        flex: '1 1 25%',
                                                                    }}
                                                                >
                                                                    {/* Header */}
                                                                    <Box
                                                                        sx={{
                                                                            backgroundColor: tableColors[mode as 'light' | 'dark'].accordion.titleBg,
                                                                            mb: 2,
                                                                            px: 1,
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontWeight: 'bold',
                                                                                fontSize: '0.9rem',
                                                                                color: tableColors[mode as 'light' | 'dark'].accordion.title,
                                                                            }}
                                                                        >
                                                                            Company
                                                                        </Typography>
                                                                    </Box>

                                                                    <Typography
                                                                        sx={{
                                                                            fontWeight: 'bold',
                                                                            fontSize: '1rem',
                                                                            mb: 2,
                                                                            color: tableColors[mode as 'light' | 'dark'].text,
                                                                        }}
                                                                    >
                                                                        {load.posterInfo?.companyName || 'Company'}
                                                                    </Typography>

                                                                    <Box>
                                                                        <Typography
                                                                            level="body-sm"
                                                                            sx={{
                                                                                fontSize: '0.8rem',
                                                                                color: tableColors[mode as 'light' | 'dark'].linkColor,
                                                                                fontWeight: 'bold',
                                                                                cursor: 'pointer',
                                                                                textDecoration: 'underline',
                                                                                mb: 1,
                                                                            }}
                                                                            onClick={() => {
                                                                                const contact = load.posterInfo.contact
                                                                                if (contact.email) {
                                                                                    window.location.href = `mailto:${contact.email}`
                                                                                } else if (contact.phoneNumber) {
                                                                                    window.location.href = `tel:${contact.phoneNumber}`
                                                                                }
                                                                            }}
                                                                        >
                                                                            {load.posterInfo.contact?.email || load.posterInfo.contact?.phoneNumber || 'N/A'}
                                                                        </Typography>

                                                                        {/* MC Number and Credit Score */}
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                mb: 1,
                                                                                width: '20rem',
                                                                            }}
                                                                        >
                                                                            <Typography level="body-sm">MC#{load.posterInfo?.mcNumber || '—'}</Typography>
                                                                            <Typography level="body-sm">
                                                                                Credit Score: {load.posterInfo?.credit?.creditScore || '—'}
                                                                            </Typography>
                                                                        </Box>

                                                                        {/* City and Days to Pay */}
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                mb: 1,
                                                                                width: '20rem',
                                                                            }}
                                                                        >
                                                                            <Typography level="body-sm">
                                                                                {`${load.posterInfo?.city || '—'}, ${load.posterInfo?.carrierHomeState || '—'}`}
                                                                            </Typography>
                                                                            <Typography level="body-sm">
                                                                                Days to Pay: {load.posterInfo?.credit?.daysToPay || '—'}
                                                                            </Typography>
                                                                        </Box>
                                                                        {/* Poster ID */}
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                mb: 1,
                                                                                width: '20rem',
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                level="body-sm"
                                                                                sx={{
                                                                                    fontWeight: 'bold',
                                                                                    color: colors.accordion.label,
                                                                                    fontSize: '0.8rem',
                                                                                }}
                                                                            >
                                                                                Load ID
                                                                            </Typography>
                                                                            <Typography
                                                                                level="body-sm"
                                                                                sx={{ color: colors.accordion.text, fontSize: '0.8rem' }}
                                                                            >
                                                                                {load.postersReferenceId || 'N/A'}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </Sheet>
                    </Box>
                </Box>
            ) : (
                <Box
                    p={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        mt: 2,
                        maxWidth: '100%',
                        height: 'calc(100vh - 17.5rem)',
                        overflow: 'auto',
                        borderRadius: '8px',
                        border: '1px solid',
                        borderColor: tableColors[mode as 'light' | 'dark'].headerBg,
                    }}
                >
                    {isSearching ? (
                        <>
                            <Typography level="body-md" color="neutral">
                                No loads found for now. But we're still searching for the loads...
                            </Typography>
                            <CircularProgress size="sm" />
                        </>
                    ) : (
                        <Typography level="body-md" color="neutral">
                            No loads found
                        </Typography>
                    )}
                </Box>
            )}
        </Box>
    )
}
