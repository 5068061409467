import { useState } from 'react'
import { Modal, ModalDialog, DialogTitle, DialogContent, Typography, Button, Box, List, ListItem, ListItemContent, IconButton, Divider } from '@mui/joy'
import { Close as CloseIcon } from '@mui/icons-material'
import axios from 'axios'
import { useAuth } from 'hooks/auth'

interface DispatcherAgentSetupModalProps {
    open: boolean
    onClose: () => void
    onComplete: () => void
}

const BenefitList = () => (
    <List
        marker="disc"
        sx={{
            '--ListItem-paddingY': '0.5rem',
            '.benefit-title': {
                fontWeight: 'bold',
                lineHeight: 1.8,
            },
        }}
    >
        <ListItem>
            <ListItemContent>
                <span className="benefit-title">Stay on Top of Your Emails:</span> We'll keep track of all broker emails for you, so nothing slips through the
                cracks.
            </ListItemContent>
        </ListItem>
        <ListItem>
            <ListItemContent>
                <span className="benefit-title">Save Time with Quick Replies:</span> Respond to emails in seconds using pre-set templates.
            </ListItemContent>
        </ListItem>
        <ListItem>
            <ListItemContent>
                <span className="benefit-title">Get Everything Organized:</span> All your order-related emails in one place for easy management.
            </ListItemContent>
        </ListItem>
    </List>
)

export default function DispatcherAgentSetupModal({ open, onClose, onComplete }: DispatcherAgentSetupModalProps) {
    const [connecting, setConnecting] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const { setApplication } = useAuth()

    const handleAddEmail = async () => {
        try {
            setErrorMsg('')

            const params = new URLSearchParams({
                client_id: process.env.REACT_APP_NYLAS_CLIENT_ID!,
                redirect_uri: process.env.REACT_APP_NYLAS_REDIRECT_URI!,
                response_type: 'code',
                access_type: 'offline', // to get refresh token
            })

            const authUrl = `${process.env.REACT_APP_NYLAS_API_URL}/v3/connect/auth?${params.toString()}`

            const authWindow = window.open(authUrl.toString(), 'Outlook Auth', 'width=500,height=600,scrollbars=yes')

            window.addEventListener('message', async function handleAuthMessage(event) {
                try {
                    if (event.origin !== window.location.origin) return

                    if (event.data.type === 'NYLAS_AUTH_SUCCESS') {
                        window.removeEventListener('message', handleAuthMessage)
                        authWindow?.close()
                        setConnecting(true)

                        const response = await axios.post('n8n/setup', { email: event.data.email })

                        onClose()
                        onComplete()
                        setApplication(response.data.data)
                        setConnecting(false)
                    } else if (event.data.type === 'NYLAS_AUTH_ERROR') {
                        console.log(event.data, 'event.data')

                        window.removeEventListener('message', handleAuthMessage)
                        authWindow?.close()
                        setErrorMsg(event.data.error || 'Failed to add email')
                    }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                    setErrorMsg(error?.message || 'Failed to add email')
                }
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            setErrorMsg(error?.message || 'Failed to add email')
        }
    }

    return (
        <>
            <Modal open={open} onClose={connecting ? undefined : onClose}>
                <ModalDialog
                    variant="outlined"
                    sx={{
                        width: { xs: '90vw', sm: '600px' },
                        maxWidth: '600px',
                        height: { xs: '80vh', sm: '600px' },
                        maxHeight: '90vh',
                        borderRadius: '16px',
                        p: 0,
                        boxShadow: 'lg',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <DialogTitle sx={{ px: 3, py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <Typography level="h4" sx={{ fontWeight: 600 }}>
                                Setup Dispatcher Agent
                            </Typography>
                            <IconButton variant="plain" color="neutral" onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <Divider />
                    <DialogContent sx={{ p: 3, overflow: 'auto' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, height: '100%' }}>
                            <Typography level="body-lg">Welcome! Let's set up your Dispatcher Agent to streamline your email management.</Typography>

                            <BenefitList />

                            <Typography level="body-md" color="neutral">
                                By adding your email, you'll be able to efficiently manage and process all your order-related communications.
                            </Typography>

                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                <Button
                                    loading={connecting}
                                    size="lg"
                                    onClick={handleAddEmail}
                                    sx={{
                                        borderRadius: '24px',
                                        px: 4,
                                        width: '80%',
                                    }}
                                >
                                    Connect Your Email
                                </Button>
                                {errorMsg && (
                                    <Typography level="body-md" color="danger" sx={{ textAlign: 'center' }}>
                                        {errorMsg}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </DialogContent>
                </ModalDialog>
            </Modal>
        </>
    )
}
