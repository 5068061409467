import { useEffect } from 'react'
import axios from 'axios'

export default function NylasCallback() {
    useEffect(() => {
        const code = new URLSearchParams(window.location.search).get('code')

        if (code) {
            // Exchange code for tokens
            axios
                .post('/email/auth/nylas/exchange-token', { code })
                .then(({ data }) => {
                    // Send the tokens back to the parent window
                    window.opener?.postMessage(
                        {
                            type: 'NYLAS_AUTH_SUCCESS',
                            email: data.email,
                        },
                        window.location.origin
                    )
                })
                .catch((error) => {
                    window.opener?.postMessage(
                        {
                            type: 'NYLAS_AUTH_ERROR',
                            error: error.response?.data?.message || 'Failed to authenticate with Nylas',
                        },
                        window.location.origin
                    )
                })
        }
    }, [])

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <p>Authenticating with Nylas... You can close this window after authentication is complete.</p>
        </div>
    )
}
