import { Box, Button, Divider, FormControl, FormLabel, Input } from '@mui/joy'

const AccountDetailsTab = () => {
    return (
        <Box
            sx={{
                pt: 3,
                pb: 10,
                display: 'grid',
                gridTemplateColumns: {
                    xs: '100%',
                    sm: 'minmax(120px, 30%) 1fr',
                    lg: '280px 1fr minmax(120px, 208px)',
                },
                columnGap: { xs: 2, sm: 3, md: 10 },
                rowGap: { xs: 2, sm: 2.5 },
                '& > hr': {
                    gridColumn: '1/-1',
                },
            }}
        >
            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Account Login</FormLabel>
            <Box sx={{ display: { xs: 'contents', sm: 'flex' }, gap: 2 }}>
                <FormControl sx={{ flex: 1 }}>
                    <FormLabel>Email Address</FormLabel>
                    <Input placeholder="Email" type="email" />
                </FormControl>
                <FormControl sx={{ flex: 1 }}></FormControl>
            </Box>
            <Divider role="presentation" />
            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Update Password</FormLabel>
            <Box sx={{ display: { xs: 'contents', sm: 'flex' }, gap: 2 }}>
                <FormControl sx={{ flex: 1 }} disabled>
                    <FormLabel>New Password</FormLabel>
                    <Input placeholder="Password" type="password" />
                </FormControl>
                <FormControl sx={{ flex: 1 }} disabled>
                    <FormLabel>Confirm Password</FormLabel>
                    <Input placeholder="Confirm" type="password" />
                </FormControl>
            </Box>
            <Box
                sx={{
                    gridColumn: '1/-1',
                    justifySelf: 'flex-start',
                    display: 'flex',
                    gap: 1,
                }}
            >
                <Button variant="outlined" color="neutral" size="sm">
                    Cancel
                </Button>
                <Button size="sm">Save</Button>
            </Box>
        </Box>
    )
}
export default AccountDetailsTab
