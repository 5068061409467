/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/joy/Box'
import IconButton from '@mui/joy/IconButton'
import Typography from '@mui/joy/Typography'
import { useSnackbar, ClickAwayListener } from '@mui/base'

// Icons import
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { EmailCard, SelectEmailText } from './components'
import { useState } from 'react'
import { useMediaQuery } from '@mui/material'
import OfferPreview from './components/EmailCard/components/OfferPreview'
import { Email, EmailProcessingResult } from '@numeo/types'
import EmailDetail from './components/EmailCard/components/EmailDetail'

interface EmailContentProps {
    onBack?: () => void
    execution: { email: Email; processingResult: EmailProcessingResult } | null
    refetch: () => Promise<void>
}

export default function EmailContent({ onBack, execution, refetch }: EmailContentProps) {
    const isMobile = useMediaQuery('(max-width:800px)')

    const [open, setOpen] = useState(false)
    const [toastMessage] = useState('')
    const [toastColor] = useState<'success' | 'danger'>('danger')

    const handleClose = () => {
        setOpen(false)
    }

    const { getRootProps, onClickAway } = useSnackbar({
        onClose: handleClose,
        open,
        autoHideDuration: 3000,
    })

    if (!execution) {
        return <SelectEmailText />
    }

    return (
        <Box
            sx={{
                p: 2,
                pb: 0,
                position: 'relative',
                height: '100%',
            }}
        >
            {onBack && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        p: 2,
                        // borderBottom: '1px solid',
                        borderColor: 'divider',
                        width: '100%',
                        position: 'absolute',
                        top: '-60px',
                        left: '20px',
                    }}
                >
                    <IconButton onClick={onBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography level="title-lg">Back</Typography>
                </Box>
            )}
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 'md',
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                            backgroundColor: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'var(--joy-palette-neutral-300)',
                            borderRadius: '4px',
                            '&:hover': {
                                backgroundColor: 'var(--joy-palette-neutral-400)',
                            },
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'var(--joy-palette-background-level1)',
                            borderRadius: '4px',
                        },
                        scrollbarWidth: 'thin',
                        scrollbarColor: 'var(--joy-palette-neutral-300) var(--joy-palette-background-level1)',
                    }}
                >
                    <EmailCard execution={execution} isFromMainSender />
                    {(execution as any).threads?.length ? (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            {(execution as any).threads.map((thread: any) => (
                                <EmailCard
                                    key={thread._id}
                                    isReply
                                    execution={{ email: thread }}
                                    isFromMainSender={thread.emailData.object.from[0].email === execution.email.emailData.object.to[0].email}
                                />
                            ))}
                        </Box>
                    ) : (
                        execution.processingResult &&
                        execution.processingResult.orders?.length > 0 && (
                            <Box>
                                <OfferPreview execution={execution} />
                            </Box>
                        )
                    )}
                </Box>
                <EmailDetail execution={execution} refetch={refetch} />
            </Box>

            {open && (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div
                        {...getRootProps()}
                        style={{
                            position: 'fixed',
                            bottom: 16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: toastColor === 'danger' ? '#d32f2f' : '#2e7d32',
                            color: 'white',
                            padding: '6px 16px',
                            borderRadius: 4,
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            zIndex: 1400,
                        }}
                    >
                        {toastMessage}
                        <button
                            onClick={handleClose}
                            style={{
                                marginLeft: 8,
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                cursor: 'pointer',
                                padding: '4px',
                            }}
                        >
                            ✕
                        </button>
                    </div>
                </ClickAwayListener>
            )}
        </Box>
    )
}
