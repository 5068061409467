import React, { useState, useEffect } from 'react'
import LoadsTableS from './LoadsTableS'
import LoadsTableL from './LoadsTableL'
import { DatabaseLoad } from '@numeo/types'

export default function TruckLoadTable(props: { loads: DatabaseLoad[]; sortOption: string }) {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1800)

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 1800)
        }

        window.addEventListener('resize', handleResize)

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return isLargeScreen ? <LoadsTableL {...props} /> : <LoadsTableS {...props} />
}
