import SvgIcon from '@mui/joy/SvgIcon'

export function HorizontalDirectionIcon({ svgColor = '#0046E0' }: { svgColor?: string }) {
    return (
        <SvgIcon sx={{ width: '22px', height: '12px' }} viewBox="0 0 22 12">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 7.28571C3.71008 7.28571 4.28571 6.71008 4.28571 6C4.28571 5.28992 3.71008 4.71429 3 4.71429C2.28992 4.71429 1.71429 5.28992 1.71429 6C1.71429 6.71008 2.28992 7.28571 3 7.28571ZM3 9C4.65685 9 6 7.65685 6 6C6 4.34315 4.65685 3 3 3C1.34315 3 0 4.34315 0 6C0 7.65685 1.34315 9 3 9Z"
                fill={svgColor}
            />
            <g clipPath="url('#clip0_horizontal')">
                <path d="M15.5266 0L14 1.415L18.9467 6L14 10.585L15.5266 12L22 6L15.5266 0Z" fill={svgColor} />
            </g>
            <rect x="8" y="5" width="2" height="2" fill={svgColor} />
            <rect x="12" y="5" width="2" height="2" fill={svgColor} />
            <defs>
                <clipPath id="clip0_horizontal">
                    <rect width="8" height="12" fill="white" transform="translate(14)" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export function VerticalDirectionIcon({ svgColor = '#0046E0' }: { svgColor?: string }) {
    return (
        <SvgIcon sx={{ width: '16px', height: '100px' }} viewBox="0 0 16 100">
            <path fill={svgColor} stroke="#9FBBF7" strokeWidth=".5" d="M14 2v12H2V2h12z" />
            <rect width="4" height="4" x="6" y="6" stroke="#fff" rx="2" />
            <path fill={svgColor} stroke="#9FBBF7" strokeWidth=".5" d="M14 86v12H2V86h12z" />
            <rect width="4" height="4" x="6" y="90" fill="#fff" stroke="#fff" rx="2" />
            <path
                fill="#636D78"
                fillRule="evenodd"
                d="M7.872 20v1.454h1V20h-1zm0 4.361v2.907h1v-2.907h-1zm0 5.815v2.907h1v-2.907h-1zm0 5.814v2.907h1V35.99h-1zm0 5.815v2.907h1v-2.907h-1zm0 5.815v2.907h1V47.62h-1zm0 5.814v2.907h1v-2.907h-1zm0 5.815v2.907h1V59.25h-1zm0 5.814v2.908h1v-2.908h-1zm0 5.815v2.907h1v-2.907h-1zm1 7.819V75h-1v3.697l-2.129-2.365-.743.67 3 3.333.372.412.371-.412 3-3.334-.743-.669-2.128 2.365z"
                clipRule="evenodd"
            />
        </SvgIcon>
    )
}

export default function DatDirectionIcons({ svgColor }: { svgColor: string }) {
    return (
        <SvgIcon sx={{ width: '20px', height: '40px' }} viewBox="1 2 12 25">
            <path
                fill={svgColor}
                fillRule="evenodd"
                d="M6.5 7.571c.592 0 1.071-.48 1.071-1.071 0-.592-.48-1.071-1.071-1.071-.592 0-1.071.48-1.071 1.071 0 .592.48 1.071 1.071 1.071zM6.5 9C7.88 9 9 7.88 9 6.5S7.88 4 6.5 4 4 5.12 4 6.5 5.12 9 6.5 9z"
                clipRule="evenodd"
            />
            <circle cx="6.5" cy="22.5" r="2" fill={svgColor} stroke={svgColor}></circle>
            <path fill={svgColor} d="M6 10v.923h1V10H6zM6 11.692v.923h1v-.923H6zM6 13.385v.923h1v-.923H6zM6 15.077V16h1v-.923H6z" />
            <g clipPath="url(#prefix__clip0)">
                <path fill={svgColor} d="M9 16.573L8.41 16 6.5 17.855 4.59 16l-.59.573L6.5 19 9 16.573z"></path>
            </g>
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill="#fff" d="M0 0H3V5H0z" transform="rotate(90 -3.5 12.5)"></path>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}
