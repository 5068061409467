/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/joy'
import { BaseCall, CallCenterEventActions, CallState, CallStateActions, DatabaseLoad, IMessageTypes, ThreadDataType, SearchParams } from '@numeo/types'
import { useAuth } from 'hooks/auth/Provider'
import { CallCenterHook } from 'hooks/useCallCenter'
import { useGetQuery } from 'hooks/useGetQuery'
import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AUTH_TOKEN } from 'setup/SetupAxios'

import { wssHostUrl } from 'utils/network'
import spotApi from '../pages/dashboard/pages/dispatcher/pages/spot-finder/api/api'

interface SpotSearchParams {
    origin: string
    destination: string
    radius: number
}

interface SpotSearchExecution {
    startedAt: Date
    completedAt?: Date
    spotsFound: number
    status: 'success' | 'error'
    error?: string
}

interface SpotSearch {
    _id: string
    userId: string
    truckId: string
    status: 'active' | 'stopped' | 'completed'
    searchParams: SpotSearchParams
    totalSpotsFound: number
    startedAt: Date
    nextExecutionAt?: Date
    executions: SpotSearchExecution[]
}

interface SpotSearchState {
    activeSearch: SpotSearch | null
    isSearching: boolean
    error: string | null
    lastUpdate: Date
}

interface ThreadStatus {
    searchStatus: string
    searchTaskId: string
}

interface WebSocketContextProps {
    notifications: string[]
    receivedMessages: { sender: string; message: string }[]
    onMessageReceived: (message: string) => void
    threadList: ThreadDataType[] | null
    setThreadList: (threadList: ThreadDataType[]) => void
    handleSelectThreadMessage: (threadId: string | null) => Promise<void>
    updateData: () => void
    messageList: IMessageTypes[] | null
    truckData: ThreadDataType | null
    setTruckData: Dispatch<SetStateAction<ThreadDataType | null>>
    sendMessageToService: (message: object, threadId: string) => Promise<void>
    currentThreadId: string
    setIsUpdated: Dispatch<SetStateAction<boolean>>
    onLoad: boolean
    loadThreads: boolean
    messagesLoading: boolean
    messageSending: { [threadId: string]: string }
    unreadMessageList: IMessageTypes[] | null
    queryThreadId: string
    socket: WebSocket | null
    spotFinderActions: {
        startSearch: (truckId: string, searchParams: SpotSearchParams) => void
        stopSearch: (searchId: string) => void
    }
    spotSearchState: {
        [truckId: string]: SpotSearchState
    }
    threadSearchStatuses: { [threadId: string]: ThreadStatus }
    stopSearch: (threadId: string, searchId: string) => Promise<void>
    currentLoadsTableData: DatabaseLoad[]
    callCenter: CallCenterHook
    actions?: CallStateActions
    currentSearchParams: SearchParams | null
}

export const WebSocketContext = createContext<WebSocketContextProps | undefined>(undefined)

export const WebSocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const navigate = useNavigate()
    const threadId = useGetQuery('threadId')
    const { user, application } = useAuth()

    const [socket, setSocket] = useState<WebSocket | null>(null)
    const [notifications, setNotifications] = useState<string[]>([])
    const [receivedMessages, setReceivedMessages] = useState<{ sender: string; message: string }[]>([])
    const [threadList, setThreadList] = useState<ThreadDataType[] | null>(null)
    const [messageList, setMessageList] = useState<IMessageTypes[]>([])
    const [unreadMessageList, setUnreadMessageList] = useState<IMessageTypes[] | null>(null)
    const [truckData, setTruckData] = useState<ThreadDataType | null>(null)
    const [currentThreadId, setCurrentThreadId] = useState<string>('')
    const [currentSearchParams, setCurrentSearchParams] = useState<SearchParams | null>(null)
    const [, setIsUpdated] = useState(false)
    const [onLoad, setOnLoad] = useState(false)
    const [messagesLoading, setMessagesLoading] = useState(false)
    const [loadThreads, setLoadThreads] = useState(false)
    const [messageSending, setMessageSending] = useState<{ [threadId: string]: string }>({})
    const [errorMessage, setErrorMessage] = useState('')
    const [spotSearchState, setSpotSearchState] = useState<{
        [truckId: string]: SpotSearchState
    }>({})
    const [currentLoadsTableData, setCurrentLoadsTableData] = useState<DatabaseLoad[]>([])
    const [threadSearchStatuses, setThreadSearchStatuses] = useState<{ [threadId: string]: ThreadStatus }>({})
    const [callCenter, setCallCenter] = useState<CallCenterHook>({
        calls: [],
        selectedCallId: null,
        filters: {
            types: [],
            status: [],
            priority: undefined,
            searchTerm: '',
        },
        stats: {
            total: 0,
            byStatus: {},
            byType: {},
            byPriority: {},
        },
        wsStatus: 'disconnected',
        recentlyUpdated: new Set(),
        updateCall: (callId: string, updates: Partial<BaseCall>) => {
            setCallCenter((prev) => ({
                ...prev!,
                calls: {
                    ...prev!.calls,
                    [callId]: { ...prev!.calls[callId], ...updates },
                },
            }))
        },
        removeCall: (callId: string) => {
            setCallCenter((prev) => {
                const { ...remaining } = prev!.calls
                delete remaining[callId]
                return {
                    ...prev!,
                    calls: remaining,
                }
            })
        },
        setSelectedCall: (callId: string | null) => {
            setCallCenter((prev) => ({
                ...prev!,
                selectedCallId: callId,
            }))
        },
        updateFilters: (filters: Partial<CallState['filters']>) => {
            setCallCenter((prev) => ({
                ...prev!,
                filters: { ...prev!.filters, ...filters },
            }))
        },
        handleCorrectTranscription: (segmentId: string, text: string) => {
            // Implement transcription correction logic
            console.log('Correcting transcription:', segmentId, text)
        },
        handleFlagTranscription: (segmentId: string, reason: string) => {
            // Implement transcription flagging logic
            console.log('Flagging transcription:', segmentId, reason)
        },
        actions: {},
    })

    const url = `${wssHostUrl()}/v1/admin`

    const spotFinderActions = useMemo(
        () => ({
            startSearch: (truckId: string, searchParams: SpotSearchParams) => {
                if (socket && user?.email) {
                    socket.send(
                        JSON.stringify({
                            action: 'startSpotSearch',
                            userId: user.email,
                            truckId,
                            searchParams,
                        })
                    )
                }
            },
            stopSearch: (searchId: string) => {
                if (socket) {
                    socket.send(
                        JSON.stringify({
                            action: 'stopSpotSearch',
                            searchId,
                        })
                    )
                }
            },
        }),
        [socket, user]
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSpotSearchMessage = useCallback((data: any) => {
        const { action, truckId, search, error } = data

        switch (action) {
            case 'spotSearchStarted':
            case 'spotSearchUpdate':
            case 'spotSearchStopped':
            case 'spotSearchCompleted':
            case 'spotSearchError':
                setSpotSearchState((prev) => ({
                    ...prev,
                    [truckId]: {
                        activeSearch: search || prev[truckId]?.activeSearch,
                        isSearching: action !== 'spotSearchStopped' && action !== 'spotSearchCompleted',
                        error: error || null,
                        lastUpdate: new Date(),
                    },
                }))
                break
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const connectWebSocket = useCallback(() => {
        const token = localStorage.getItem(AUTH_TOKEN)!
        const ws = new WebSocket(`${url}?auth_token=${token}`)
        setOnLoad(true)
        ws.onopen = () => {
            setSocket(ws)
            setCallCenter((prev) => ({
                ...prev!,
                wsStatus: 'connected',
            }))

            if (threadId) {
                setMessagesLoading(true)
                const payload = JSON.stringify({ action: 'getMessages', threadId, email: user?.email, projectName: application?.projectName })
                ws?.send(payload)

                const threadStatusPayload = JSON.stringify({ action: 'getThreadStatus', projectName: application?.projectName, threadId })
                ws?.send(threadStatusPayload)

                const searchParamsPayload = JSON.stringify({ action: 'getSearchParams', projectName: application?.projectName, threadId })
                ws?.send(searchParamsPayload)
            }

            ws?.send(JSON.stringify({ action: CallCenterEventActions.GET_ACTIVE_CALLS }))

            if (user?.email) {
                const payload = JSON.stringify({ action: 'getTrucks', email: user?.email, projectName: application?.projectName, threadId })
                ws?.send(payload)
            }
        }

        ws.onmessage = handleMessage

        ws.onerror = (error) => {
            console.error('WebSocket error:', error)
        }

        ws.onclose = () => {
            console.log('WebSocket connection closed')
            setSocket(null)
            setCallCenter((prev) => ({
                ...prev!,
                wsStatus: 'disconnected',
            }))
            if (loadThreads) setLoadThreads(false)
        }
        setOnLoad(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleSpotSearchMessage, url, threadId, user])

    useEffect(() => {
        connectWebSocket()
    }, [connectWebSocket])

    const updateData = () => {
        if (threadId) {
            setMessagesLoading(true)
            const payload = JSON.stringify({ action: 'getMessages', threadId, email: user?.email, projectName: application?.projectName })
            socket?.send(payload)

            const threadStatusPayload = JSON.stringify({ action: 'getThreadStatus', projectName: application?.projectName, threadId })
            socket?.send(threadStatusPayload)

            const searchParamsPayload = JSON.stringify({ action: 'getSearchParams', projectName: application?.projectName, threadId })
            socket?.send(searchParamsPayload)
        }

        if (user?.email) {
            const payload = JSON.stringify({ action: 'getTrucks', email: user?.email, projectName: application?.projectName, threadId })
            socket?.send(payload)
        }
    }

    useEffect(() => {
        if (threadId) {
            setCurrentThreadId(threadId)
        } else {
            setCurrentThreadId('')
            setMessageList([])
            setUnreadMessageList([])
            setMessagesLoading(false)
        }
        updateData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadId])

    const handleSelectThreadMessage = async (threadId: string | null) => {
        setCurrentSearchParams(null)
        if (threadId) {
            navigate({
                pathname: '/dispatcher/spot-finder',
                search: `?threadId=${threadId}`,
            })
            if (unreadMessageList) {
                setUnreadMessageList(unreadMessageList?.filter((msg) => msg.threadId !== currentThreadId))
            }
        } else {
            navigate('/dispatcher/spot-finder')
            setCurrentThreadId('')
            setMessageList([])
        }
    }

    const onMessageReceived = (message: string) => {
        setReceivedMessages((prev) => [...prev, { sender: 'client', message: message }])
        setNotifications((prev) => [...prev, `Received: ${message}`])
    }

    const sendMessageToService = async (message: object, threadId: string) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            try {
                const payload = JSON.stringify({
                    action: 'sendMessage',
                    message,
                    threadId,
                    equipmentType: truckData?.equipmentType.split(','),
                    projectName: application?.projectName,
                    email: user?.email,
                })

                socket.send(payload)
                setMessagesLoading(true)
                if (unreadMessageList) {
                    setUnreadMessageList(unreadMessageList?.filter((msg) => msg.threadId !== threadId))
                }
            } catch (error) {
                console.error('Error sending message via WebSocket:', error)
            }
        } else {
            console.warn('WebSocket is not connected or open')
        }
    }

    const stopSearch = async (threadId: string, searchTaskId: string) => {
        try {
            await spotApi.post(`/trucks/spot-search/${application?.projectName}/${searchTaskId}/${user?.userId}/${threadId}/cancel`, {})
        } catch (error) {
            console.log('Error getting data ', error)
        } finally {
            setThreadSearchStatuses((prev) => {
                const updatedThreadStatuses = { ...prev }
                delete updatedThreadStatuses[threadId]
                return updatedThreadStatuses
            })
        }
    }

    const handleCallCenterMessage = useCallback((event: MessageEvent): boolean => {
        const data = JSON.parse(event.data)
        switch (data.action) {
            case CallCenterEventActions.GET_ACTIVE_CALLS:
                setCallCenter((prev) => ({
                    ...prev,
                    calls: data?.calls,
                }))
                break
            case CallCenterEventActions.CALL_INITIATED: {
                const newCall = data.data
                if (newCall) {
                    setCallCenter((prev) => {
                        const currentCalls = Array.isArray(prev.calls) ? prev.calls : []
                        return {
                            ...prev,
                            calls: [...currentCalls, newCall],
                            recentlyUpdated: new Set([...prev.recentlyUpdated, newCall.callId]),
                        }
                    })
                }
                break
            }

            // we need to update transcription
            case CallCenterEventActions.CALL_UPDATED:
                console.log('HANDLE CALL UPDATED:', data)
                setCallCenter((prev) => {
                    const currentCalls = Array.isArray(prev.calls) ? prev.calls : []
                    return {
                        ...prev,
                        calls: currentCalls.map((call) =>
                            call.callId === data.data.callId
                                ? {
                                      ...call,
                                      transcripts: data.data.transcripts,
                                  }
                                : call
                        ),
                        recentlyUpdated: new Set([...prev.recentlyUpdated, data.data.callId]),
                    }
                })
                break
            // we need to remove call from the state
            case CallCenterEventActions.CALL_ENDED:
                setCallCenter((prev) => ({
                    ...prev,
                    calls: prev.calls.filter((call) => call.callId !== data.data.callId),
                    recentlyUpdated: new Set([...prev.recentlyUpdated, data.callId]),
                }))
                break

            case 'transcription.segment':
                setCallCenter((prev) => ({
                    ...prev,
                    calls: {
                        ...prev.calls,
                        [data.callId]: {
                            ...prev.calls[data.callId],
                            transcription: [...prev.calls[data.callId].transcripts, data.segment],
                            _lastUpdate: Date.now(),
                        },
                    },
                    recentlyUpdated: new Set([...prev.recentlyUpdated, data.callId]),
                }))
                break
            default:
                return false
        }
        return true
    }, [])

    // All Websocket messages go through this function
    const handleMessage = useCallback(
        (event: MessageEvent) => {
            try {
                if (handleCallCenterMessage(event)) {
                    return
                }
                if (handleSpotSearchGeneralMessages(event)) {
                    return
                }
                const data = JSON.parse(event.data)
                console.log(data, 'data')

                // Handle existing message types
                if (data.type === 'thread') {
                    // Existing thread handling logic
                } else if (data.type === 'spot_finder') {
                    // Existing spot finder handling logic
                } else if (data.action.startsWith('call.dispatcher')) {
                    handleCallCenterMessage(event)
                } else if (data.type.startsWith('call.') || data.type === 'transcription.segment') {
                    handleCallCenterMessage(event)
                }
            } catch (error) {
                console.error('Error handling WebSocket message:', error)
            }
        },
        [handleCallCenterMessage]
    )
    const handleSpotSearchGeneralMessages = (event: MessageEvent): boolean => {
        try {
            const data = JSON.parse(event.data)

            if (data.error) {
                setErrorMessage(data.error)
                console.log('Error message:', errorMessage)
                setMessageSending((prev) => ({ ...prev, [data.threadId]: '' }))
            }

            if (data.action === 'getMessages' && data.messages) {
                setCurrentLoadsTableData(data.messages)
                setTruckData(data.truckData)
                setMessagesLoading(false)
                return true
            } else if (data.action === 'getUserNewMessage' && data.message) {
                const newMessage = {
                    id: data.message.id,
                    threadId: data.message.thread_id,
                    role: data.message.role,
                    content: data.message.content[0].text.value,
                    createdAt: data.message.created_at,
                }
                setMessageList((prev) => [...prev, newMessage])
                return true
            } else if (data.action === 'getThreadStatus' && data.status) {
                const threadStatus: { [threadId: string]: ThreadStatus } = {
                    [data.threadId]: { searchStatus: data.status as string, searchTaskId: data.searchTaskId as string },
                }

                setThreadSearchStatuses({ ...threadSearchStatuses, ...threadStatus })
                return true
            } else if (data.action === 'getTrucks' && data.trucks) {
                setThreadList(data.trucks)
                if (loadThreads) setLoadThreads(false)
                return true
            } else if (data.action === 'updateTruckData' && data.driversNames) {
                const updatedTruckData = { ...data.truckData, driversNames: data.driversNames }
                setTruckData(updatedTruckData)
                return true
            } else if (data.action?.startsWith('spotSearch')) {
                handleSpotSearchMessage(data)
                return true
            } else if (data.action === 'getSearchParams' && data.searchParams) {
                setCurrentSearchParams(data.searchParams)
                return true
            }

            if (data.status === 'in_progress') {
                setMessageSending((prev) => ({ ...prev, [data.threadId]: data.message }))
                return true
            } else if (data.status === 'completed') {
                setMessageSending((prev) => ({ ...prev, [data.threadId]: '' }))

                // FIXME: rewrite the logic to make it work for only one message with loads, now it works in chat mode
                if (Array.isArray(data.messages)) {
                    const lastUserMessageTime = data.messages.filter((msg) => msg.role === 'user').sort((a, b) => b.createdAt - a.createdAt)[0]?.createdAt
                    const unreadAssistantMessages = lastUserMessageTime
                        ? data.messages.filter((msg) => msg.role === 'assistant' && msg.createdAt > lastUserMessageTime)
                        : []

                    setUnreadMessageList(unreadAssistantMessages)
                    setCurrentLoadsTableData(data.messages)
                    // setMessageList([...data.messages, ...messageList])
                    setMessagesLoading(false)
                } else {
                    console.warn('Received data.messages is not an array:', data.messages)
                    setUnreadMessageList([])
                    // setMessageList([])
                }
                return true
            } else if (data.status === 'error') {
                setMessageSending((prev) => ({ ...prev, [data.threadId]: data.status }))
                if (loadThreads) setLoadThreads(false)
                return true
            }
            return false
        } catch (error) {
            console.error('Error parsing server message:', error)
            if (loadThreads) setLoadThreads(false)
            return false
        }
    }

    useEffect(() => {
        setCallCenter((prev) => ({
            ...prev,
            wsStatus: socket?.readyState === WebSocket.OPEN ? 'connected' : 'disconnected',
        }))
    }, [socket?.readyState])

    useEffect(() => {
        const interval = setInterval(() => {
            setCallCenter((prev) => ({
                ...prev,
                recentlyUpdated: new Set(),
            }))
        }, 2000) // Clear every 2 seconds

        return () => clearInterval(interval)
    }, [])

    return (
        <WebSocketContext.Provider
            value={{
                notifications,
                receivedMessages,
                onMessageReceived,
                threadList,
                setThreadList,
                handleSelectThreadMessage,
                updateData,
                messageList,
                truckData,
                setTruckData,
                sendMessageToService,
                currentThreadId,
                setIsUpdated,
                onLoad,
                loadThreads,
                messagesLoading,
                messageSending,
                unreadMessageList,
                queryThreadId: threadId || '',
                socket,
                spotFinderActions,
                spotSearchState,
                threadSearchStatuses,
                stopSearch,
                currentLoadsTableData,
                callCenter,
                currentSearchParams,
            }}
        >
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    width: '100%',
                }}
            >
                {children}
            </Box>
            {/* TODO: implement error handling */}
            {/* {errorMessage ? <ErrorNotification text={errorMessage} setErrorMessage={setErrorMessage} /> : null} */}
        </WebSocketContext.Provider>
    )
}

export const useWebSocket = () => {
    const context = useContext(WebSocketContext)
    if (!context) {
        throw new Error('useWebSocket must be used within a WebSocketProvider')
    }
    return context
}
