function toRadians(degrees: number) {
    return degrees * (Math.PI / 180)
}

function haversine(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 3959 // Earth radius in miles
    const dLat = toRadians(lat2 - lat1)
    const dLon = toRadians(lon2 - lon1)

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return R * c // Distance in miles
}

export function reduceCoordinatesByMiles(points: Array<[number, number, number]>, rangeInMiles: number) {
    const selectedPoints: Array<[number, number]> = []
    let lastSelectedPoint: [number, number] | null = null

    points.forEach((point: [number, number, number]) => {
        if (!lastSelectedPoint || haversine(lastSelectedPoint[0], lastSelectedPoint[1], point[0], point[1]) > rangeInMiles) {
            selectedPoints.push([point[0], point[1]])
            lastSelectedPoint = [point[0], point[1]]
        }
    })

    return selectedPoints
}
