import { Box, Typography, useTheme } from '@mui/joy'
import { useWebSocket } from 'context/WebSocketContext'
import React, { useEffect, useRef, useState } from 'react'
import ChatThreads from './ChatThreads'
import SettingModal from './SettingModal'
import { DatabaseLoad } from '@numeo/types'
import LoadsTable from './LoadsTable'
import SearchBar from './SearchBar'

// interface SpotSearchParams {
//     origin: {
//         city: string
//         state: string
//         postalCode?: string
//     }
//     destination: {
//         city: string
//         state: string
//         postalCode?: string
//     }
//     equipmentType: string[]
//     earliestWhen: Date
//     latestWhen: Date
// }
import { useGetQuery } from 'hooks/useGetQuery'

const TruckManagementUI: React.FC = () => {
    const theme = useTheme()
    const threadId = useGetQuery('threadId')
    const messagesEndRef = useRef<HTMLDivElement | null>(null)

    const { messageSending, updateData, currentLoadsTableData } = useWebSocket()

    const [showAddModal, setShowAddModal] = useState(false)
    const [openSettingsModal, setOpenSettingsModal] = useState(false)
    const [radius, setRadius] = useState<number>(200)

    const [editingThread, setEditingThread] = useState<string | null>(null)

    const handleSettingsToggle = () => {
        setOpenSettingsModal(!openSettingsModal)
    }

    const handleSaveSettings = () => {
        setOpenSettingsModal(false)
    }

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
        }
    }

    useEffect(() => {
        updateData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        scrollToBottom()
    }, [messageSending])

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                height: '100%',
                width: '100%',
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                sx={{
                    backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                    height: '100%',
                    width: '100%',
                    padding: 1,
                }}
            >
                <ChatThreads
                    setShowAddModal={setShowAddModal}
                    showAddModal={showAddModal}
                    scrollToBottom={scrollToBottom}
                    setEditingThread={setEditingThread}
                    editingThread={editingThread}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            zIndex: 0,
                        }}
                    >
                        <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'start' }}>
                            {threadId ? (
                                <>
                                    <SearchBar />
                                    <LoadsTable loads={currentLoadsTableData as DatabaseLoad[]} sortOption={'ageNewest'} />
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography level="h3" color="neutral">
                                        Please select a truck to view available loads
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {/*Temporarily commenting out CHAT*/}
                    {/*
                <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        sx={{
                            backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                            height: '100%',
                            width: '20%',
                            position: 'relative',
                            overflow: 'auto',
                        }}
                    >
                        <Box
                            flex={1}
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            sx={{
                                border: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#e0e0e0'}`,
                                borderRadius: '12px',
                                backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                                boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
                                minHeight: '100%',
                                padding: '20px 30px',
                                position: 'relative',
                                maxWidth: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '20px',
                                    zIndex: 1,
                                    width: '15px',
                                    height: '15px',
                                    padding: 0,
                                    cursor: 'pointer',
                                }}
                                onClick={() => setHideSidebar((prev) => !prev)}
                            >
                                <IconButton
                                    variant="outlined"
                                    color="neutral"
                                    size="sm"
                                    sx={{
                                        borderRadius: '7px',
                                        zIndex: 10,
                                        backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                                    }}
                                >
                                    {!hideSidebar ? <FirstPageIcon sx={{ fontSize: '22px' }} /> : <LastPageIcon sx={{ fontSize: '22px' }} />}
                                </IconButton>
                            </Box>

                            {queryThreadId && !messagesLoading && (
                                <Box
                                    color="primary"
                                    sx={{
                                        position: 'absolute',
                                        top: '20px',
                                        right: '40px',
                                        zIndex: 10,
                                        width: '15px',
                                        height: '15px',
                                        padding: 0,
                                        cursor: 'pointer',
                                        display: 'flex',
                                        gap: '10px',
                                        flexDirection: 'column',
                                    }}
                                    onClick={() => setShowRightComponent((prev) => !prev)}
                                >
                                    <IconButton
                                        variant="outlined"
                                        color="neutral"
                                        size="sm"
                                        sx={{ borderRadius: '7px', zIndex: 10, backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff' }}
                                    >
                                        {showRightComponent ? <Close sx={{ fontSize: '22px' }} /> : <MoreVert sx={{ fontSize: '22px' }} />}
                                    </IconButton>
                                    {showRightComponent && (
                                        <IconButton
                                            variant="outlined"
                                            color="neutral"
                                            size="sm"
                                            sx={{ borderRadius: '7px', zIndex: 10, backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff' }}
                                            onClick={() => {
                                                setEditingThread(threadId)
                                            }}
                                        >
                                            <Edit sx={{ fontSize: '22px' }} />
                                        </IconButton>
                                    )}
                                </Box>
                            )}

                            <Box
                                style={{
                                    height: '94%',
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                    scrollbarWidth: 'none',
                                    msOverflowStyle: 'none',
                                    position: 'relative',
                                    maxWidth: '100%',
                                }}
                            >
                                <MessagesList messagesEndRef={messagesEndRef} />
                            </Box>

                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={2}
                                style={{
                                    position: 'absolute',
                                    bottom: '20px',
                                    left: '30px',
                                    right: '30px',
                                }}
                            >
                                {queryThreadId && messageList?.length === 0 && !messagesLoading && (
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        gap={1}
                                        sx={{
                                            mb: 1,
                                            maxHeight: '200px',
                                            overflowY: 'auto',
                                        }}
                                    >
                                        {messageHints.map((hint, index) => (
                                            <Chip
                                                key={index}
                                                // onClick={() => handleHintClick(hint)}
                                                variant="soft"
                                                color="primary"
                                                size="sm"
                                                sx={{
                                                    cursor: 'pointer',
                                                    width: 'fit-content',
                                                    '&:hover': {
                                                        backgroundColor: theme.palette.primary[500],
                                                        color: 'white',
                                                    },
                                                }}
                                            >
                                                {hint}
                                            </Chip>
                                        ))}
                                    </Box>
                                )}

                                {queryThreadId ? (
                                    <Box display="flex" gap={1}>
                                        <Input
                                            placeholder="Type a message..."
                                            fullWidth
                                            sx={{
                                                borderRadius: '5px',
                                                backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#f9f9f9',
                                                boxShadow: 'inset 0px 2px 5px rgba(0, 0, 0, 0.05)',
                                                height: '40px',
                                            }}
                                            // value={messageInput}
                                            // onChange={(e) => setMessageInput(e.target.value)}
                                            // onKeyDown={(e) => {
                                            //     if (e.key === 'Enter') {
                                            //         if (threadSearchStatuses[currentThreadId]?.searchStatus !== 'active') {
                                            //             sendMessageToService(messageInput, threadId as string)
                                            //             e.preventDefault()
                                            //             setMessageInput('')
                                            //             scrollToBottom()
                                            //         } else {
                                            //             stopSearch(currentThreadId as string, threadSearchStatuses[currentThreadId]?.searchTaskId as string)
                                            //         }
                                            //     }
                                            // }}
                                        />
                                        <IconButton
                                            variant="solid"
                                            color="primary"
                                            sx={{ borderRadius: '7px' }}
                                            onClick={() => {
                                                if (threadSearchStatuses[currentThreadId]?.searchStatus !== 'active') {
                                                    sendMessageToService(messageInput as SpotSearchParams, threadId as string)
                                                    // setMessageInput('')
                                                    scrollToBottom()
                                                } else {
                                                    stopSearch(currentThreadId as string, threadSearchStatuses[currentThreadId]?.searchTaskId as string)
                                                }
                                            }}
                                            // disabled={threadSearchStatuses[currentThreadId]?.searchStatus === 'active' ? false : messageInput === ''}
                                        >
                                            {threadSearchStatuses[currentThreadId]?.searchStatus === 'active' ? (
                                                <StopIcon sx={{ fontSize: '16px', color: 'red' }} />
                                            ) : (
                                                <SendIcon sx={{ fontSize: '16px' }} />
                                            )}
                                        </IconButton>
                                    </Box>
                                ) : null}
                            </Box>
                        </Box>

                        {showRightComponent && (
                            <Box
                                flex={1}
                                sx={{
                                    border: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#e0e0e0'}`,
                                    borderRadius: '12px',
                                    p: 2,
                                    backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    maxWidth: '270px',
                                    position: 'absolute',
                                    right: 0,
                                }}
                            >
                                <Typography fontWeight="bold" sx={{ mb: 1, fontSize: '15px', fontWeight: '500' }}>
                                    <b>Truck name:</b> <b>{truckData?.mainInfo && queryThreadId ? truckData?.mainInfo : 'n/a'}</b>
                                </Typography>
                                <Typography fontWeight="bold" sx={{ mb: 1, fontSize: '15px', fontWeight: '500' }}>
                                    <b>Truck ID:</b> <b>{truckData?.truckId && queryThreadId ? truckData?.truckId : 'n/a'}</b>
                                </Typography>
                                <Typography fontWeight="bold" sx={{ mb: 1, fontSize: '15px', fontWeight: '500' }}>
                                    <b>Equipment types:</b> <b>{truckData?.equipmentType && queryThreadId ? truckData?.equipmentType : 'n/a'}</b>
                                </Typography>
                                <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} sx={{ mb: 1 }}>
                                    <Typography fontWeight="bold" sx={{ fontSize: '15px', fontWeight: '500', mb: { xs: 1, sm: 0 }, mr: { xs: 0, sm: 1 } }}>
                                        <b>Drivers Names:</b>
                                    </Typography>
                                    <Typography>
                                        <b>{truckData?.driversNames && queryThreadId ? truckData?.driversNames : 'n/a'}</b>
                                    </Typography>{' '}
                                </Box>
                                <Typography
                                    sx={{
                                        mb: 1,
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                    }}
                                >
                                    <b>Driver Information:</b> There will be truck additional information soon!
                                </Typography>
                            </Box>
                        )}
                    </Box>
                */}
                </Box>

                <SettingModal
                    openSettingsModal={openSettingsModal}
                    handleCloseSettings={handleSettingsToggle}
                    setRadius={setRadius}
                    radius={radius}
                    handleSaveSettings={handleSaveSettings}
                />
            </Box>
        </Box>
    )
}

export default TruckManagementUI
