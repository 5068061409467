/* eslint-disable @typescript-eslint/no-explicit-any */
import { EmailThreadClient } from '../../api/EmailThreadClient'
import { Box, List, Sheet, Typography, CircularProgress, Button, Tooltip } from '@mui/joy'
import EmailCard from './components/EmailCard'
import { EmailTabType } from '../EmailTab'
import { useState, useEffect } from 'react'
import { Replay } from '@mui/icons-material'

interface MailsProps {
    selectedExecutionId: string | null
    onExecutionSelect: (executionId: string) => void
    type: EmailTabType
}

export default function Mails({ selectedExecutionId, onExecutionSelect, type }: MailsProps) {
    const [executions, setExecutions] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [loadingMore, setLoadingMore] = useState(false)

    const fetchEmails = async (resetList = false) => {
        try {
            setLoading(true)
            const result = await EmailThreadClient.getExectuions({
                status: type,
                page: resetList ? 1 : page,
            })

            if (resetList) {
                setExecutions(result.data)
                setPage(1)
            } else {
                setExecutions((prev) => [...prev, ...result.data])
            }

            setHasMore(result.hasMore || false)
        } catch (error) {
            console.error('Failed to fetch emails:', error)
        } finally {
            setLoading(false)
        }
    }

    const loadMore = async () => {
        if (loadingMore || !hasMore) return

        try {
            setLoadingMore(true)
            setPage((prev) => prev + 1)
            await fetchEmails()
        } finally {
            setLoadingMore(false)
        }
    }

    useEffect(() => {
        fetchEmails(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    if (loading && page === 1) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Sheet
            sx={{
                overflow: 'auto',
                height: '100%',
                overflowX: 'hidden',
                msOverflowStyle: 'none', // Hide scrollbar in IE/Edge
                scrollbarWidth: 'none', // Hide scrollbar in Firefox
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }}
        >
            {executions.length === 0 ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <Typography level="body-sm" textColor="text.tertiary">
                        No emails found
                    </Typography>
                </Box>
            ) : (
                <>
                    <List
                        sx={{
                            '--ListItem-paddingY': '1.5rem',
                            '--ListItem-paddingX': '1rem',
                        }}
                    >
                        {executions.map((execution, i) => (
                            <EmailCard
                                key={i}
                                executionId={execution._id}
                                execution={execution}
                                isSelected={execution._id === selectedExecutionId}
                                onClick={() => onExecutionSelect?.(execution.email?._id)}
                                selectedExecutionId={selectedExecutionId}
                                refetchEmails={fetchEmails}
                            />
                        ))}
                    </List>

                    {hasMore && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                            <Tooltip title="Load More">
                                <Button variant="outlined" color="neutral" loading={loadingMore} onClick={loadMore}>
                                    <Replay sx={{ mr: 1 }} />
                                </Button>
                            </Tooltip>
                        </Box>
                    )}
                </>
            )}
        </Sheet>
    )
}
