/* eslint-disable @typescript-eslint/no-explicit-any */
import { Download } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/joy'
import React, { useEffect, useMemo, useState } from 'react'
import { CallListItemProps } from '../../utils/types'
import TranscriptSidebar from './TrancriptSidebar'

// const getStatusIcon = (status: CallStatus) => {
//     switch (status) {
//         case CallStatus.ENDED:
//             return <CallMade sx={{ color: 'success.main' }} />
//         case CallStatus.ERROR:
//             return <Error sx={{ color: 'error.main' }} />
//         case CallStatus.CONNECTED:
//             return <Voicemail sx={{ color: 'success.main' }} />
//         default:
//             return <Schedule sx={{ color: 'warning.main' }} />
//     }
// }

export const CallListItem: React.FC<CallListItemProps> = React.memo(({ call, isUpdated }) => {
    const [selectedCallId, setSelectedCall] = useState('')
    const [showTranscript, setShowTranscript] = React.useState(false)
    const [currentTime, setCurrentTime] = useState(Date.now())

    // Update time every second for active calls
    useEffect(() => {
        if (!('endedAt' in call) || !call.endedAt) {
            const timer = setInterval(() => {
                setCurrentTime(Date.now())
            }, 1000)
            return () => clearInterval(timer)
        }
    }, [call])

    const handleRowClick = () => {
        setSelectedCall(call.callId)
        setShowTranscript(true)
    }

    const handleCloseTranscript = () => {
        setShowTranscript(false)
        setSelectedCall('')
    }

    const handleDownload = async (e: React.MouseEvent) => {
        e.stopPropagation() // Prevent row click event
        try {
            // Add your download logic here
            const response = await fetch(`/api/calls/${call.callId}/download`)
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `call-${call.callId}.mp3` // or whatever extension is appropriate
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
        } catch (error) {
            console.error('Error downloading call:', error)
        }
    }

    const duration = useMemo(() => {
        // For completed calls, use endedAt
        if ('endedAt' in call && call.endedAt) {
            const duration = call.endedAt - call.startedAt
            const seconds = Math.floor(duration / 1000)
            const minutes = Math.floor(seconds / 60)
            const remainingSeconds = seconds % 60
            return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
        }
        // For active calls, use current time
        const duration = currentTime - call.startedAt
        const seconds = Math.floor(duration / 1000)
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
    }, [call, currentTime])

    const latestTranscription = useMemo(() => {
        console.log('last transcript:', call.transcripts[call.transcripts.length - 1])

        return call.transcripts[call.transcripts.length - 1] || null
    }, [call])

    const isSelected = selectedCallId === call.callId

    return (
        <>
            <tr
                onClick={handleRowClick}
                style={{
                    cursor: 'pointer',
                    position: 'relative',
                    backgroundColor: isSelected ? 'var(--joy-palette-primary-softBg)' : isUpdated ? 'var(--joy-palette-warning-softBg)' : 'inherit',
                    transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = 'var(--joy-palette-neutral-softBg)'
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = isSelected
                        ? 'var(--joy-palette-primary-softBg)'
                        : isUpdated
                          ? 'var(--joy-palette-warning-softBg)'
                          : 'inherit'
                }}
            >
                <td style={{ width: '48px', padding: '8px 0' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <Tooltip title={call.status}>{getStatusIcon(call.status as CallStatus)}</Tooltip> */}
                    </Box>
                </td>
                <td>
                    <Typography level="body-sm">{call.to}</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{duration}</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{new Date(call.startedAt).toLocaleTimeString()}</Typography>
                </td>
                <td>
                    <Box
                        sx={{
                            maxWidth: '400px',
                            overflow: 'hidden',
                        }}
                    >
                        <Typography
                            level="body-sm"
                            sx={{
                                color: latestTranscription ? 'text.primary' : 'neutral.500',
                                whiteSpace: 'nowrap',
                                animation: isUpdated ? 'slideIn 0.5s ease-out' : 'none',
                                '@keyframes slideIn': {
                                    from: { transform: 'translateX(100%)', opacity: 0 },
                                    to: { transform: 'translateX(0)', opacity: 1 },
                                },
                            }}
                        >
                            {latestTranscription?.content || 'No transcription yet'}
                        </Typography>
                    </Box>
                </td>
                <td style={{ width: '64px', padding: '8px 0' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Tooltip title="Will be available in a future release">
                            <IconButton size="sm" variant="plain" disabled={true} color="neutral" onClick={handleDownload}>
                                <Download />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </td>
            </tr>
            {showTranscript && (
                <tr>
                    <td colSpan={6} style={{ padding: 0, border: 'none' }}>
                        <TranscriptSidebar call={call} onClose={handleCloseTranscript} />
                    </td>
                </tr>
            )}
        </>
    )
})

CallListItem.displayName = 'CallListItem'

export default CallListItem
