import { Settings } from '@mui/icons-material'
import { IconButton, Stack, Tab, TabList, Tabs, Tooltip } from '@mui/joy'
import Box from '@mui/joy/Box'
import { useEffect, useState } from 'react'
import { TabPanel } from './components/TabPanel'
import { useAuth } from 'hooks/auth'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { EmailsSelector } from './components/EmailsSelector'
import EmailTab from './components/EmailTab'

export default function DispatcherEmailsPage() {
    const { application } = useAuth()
    const [activeTab, setActiveTab] = useState('lane')
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    const handleTabChange = (_event: React.SyntheticEvent | null, newValue: string | number | null) => {
        if (typeof newValue === 'string') {
            setActiveTab(newValue)
            setSearchParams({ type: newValue })
        }
    }

    useEffect(() => {
        const type = searchParams.get('type')
        if (type) {
            setActiveTab(type)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!application?.integrations?.n8n?.dispatcher?.enabled) {
        return <Navigate to="/dispatcher" />
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1, px: 1 }}>
            <Stack direction="row" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="email categories" sx={{ bgcolor: 'transparent' }}>
                    <TabList>
                        <Tab value="lane" aria-controls={`email-tabpanel-0`}>
                            Lane
                        </Tab>
                        <Tab value="spot" aria-controls={`email-tabpanel-1`}>
                            Spot
                        </Tab>
                        <Tab value="lane-spot" aria-controls={`email-tabpanel-2`}>
                            Lane & Spot
                        </Tab>
                        <Tab value="unclassified" aria-controls={`email-tabpanel-2`}>
                            Unclassified
                        </Tab>
                        <Tab value="all" aria-controls={`email-tabpanel-0`}>
                            All
                        </Tab>
                    </TabList>
                </Tabs>
                <Box sx={{ flex: 1 }} />
                <EmailsSelector />
                <IconButton onClick={() => navigate('/settings?tab=email-settings')} variant="plain" sx={{ right: 0, top: 0, zIndex: 1 }}>
                    <Tooltip title="Settings">
                        <Settings />
                    </Tooltip>
                </IconButton>
            </Stack>

            <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                <TabPanel value={activeTab} index="all">
                    <EmailTab type="all" />
                </TabPanel>
                <TabPanel value={activeTab} index="lane">
                    <EmailTab type="lane" />
                </TabPanel>
                <TabPanel value={activeTab} index="spot">
                    <EmailTab type="spot" />
                </TabPanel>
                <TabPanel value={activeTab} index="lane-spot">
                    <EmailTab type="lane-spot" />
                </TabPanel>
                <TabPanel value={activeTab} index="unclassified">
                    <EmailTab type="unclassified" />
                </TabPanel>
            </Box>
        </Box>
    )
}
