/* eslint-disable @typescript-eslint/no-explicit-any */
import { FetchedEmailAttachment, FetchedEmailParticipant } from '@numeo/types'
import axios, { AxiosError } from 'axios'

export interface FetchedEmailObject {
    id: string
    thread_id: string
    subject: string
    snippet: string
    body: string
    from: FetchedEmailParticipant[]
    to: FetchedEmailParticipant[]
    reply_to: FetchedEmailParticipant[]
    date: number
    unread: boolean
    attachments: FetchedEmailAttachment[]
}

export interface FetchedEmailData {
    application_id: string
    object: FetchedEmailObject
}

export interface FetchedEmail {
    _id: string
    applicationId: string
    emailData: FetchedEmailData
    replyCount?: number
}

export interface PaginatedResponse<T> {
    threads: T[]
    pagination: {
        total: number
        page: number
        limit: number
        pages: number
    }
}

interface GetExecutionsParams {
    status?: string
    page?: number
}

interface EmailThreadResponse {
    success: boolean
    data: any[]
    total?: number
    hasMore?: boolean
}

class ApiError extends Error {
    constructor(
        message: string,
        public status?: number
    ) {
        super(message)
        this.name = 'ApiError'
    }
}

export class EmailThreadClient {
    static async sendOfferEmail(payload: { subject: string; body: string; threadId: string; processedResId: string }): Promise<any> {
        try {
            const response = await axios.post(`/n8n/send-email`, {
                subject: payload.subject,
                body: payload.body,
                threadId: payload.threadId,
                processedResId: payload.processedResId,
            })

            return response
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to send offer email', error.response?.status)
            }
            throw error
        }
    }

    static async getExectuions({ status = 'all', page = 1 }: GetExecutionsParams = {}): Promise<EmailThreadResponse> {
        try {
            const response = await axios.get(`/n8n/executions`, {
                params: {
                    status,
                    page,
                },
            })
            return response.data
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to get executions', error.response?.status)
            }
            throw error
        }
    }

    static async getExecution(executionId: string): Promise<any> {
        try {
            const response = await axios.get(`/n8n/executions/${executionId}`)
            return response.data?.data
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to get execution', error.response?.status)
            }
            throw error
        }
    }
}
