import { Grid } from '@mui/joy'
import { FC } from 'react'
import { CallListHeaderProps } from '../../utils/types'
import CallStatistics from '../Statistics'

export const CallListHeader: FC<CallListHeaderProps> = ({ pastCalls, loading }) => {
    return (
        <Grid container spacing={2} sx={{ p: 2 }}>
            <CallStatistics pastCalls={pastCalls} loading={loading} />
            <Grid xs={12} md={12}></Grid>
        </Grid>
    )
}

export default CallListHeader
