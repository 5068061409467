import { Box, Button, Checkbox, Divider, FormLabel, Input, Option, Select, Stack, Typography } from '@mui/joy'
import { FormControl, FormControlLabel } from '@mui/material'
import { PriceProgramSelector, StateSelector } from '../Selectors'
import FilterEmailTriggerUI from '../EmailTriggerUI'

const EmailSettingsTab = ({
    isExclusionList,
    excludedStates,
    setIsExclusionList,
    includedStates,
    setIncludedStates,
    isMountainAreaIncluded,
    setExcludedStates,
    handleCheckboxChange,
    statesList,
    pricesList,
    fuelCost,
    setFuelCost,
    driverCost,
    handleFuelCostChange,
    handleDriverCostChange,
    handleEmailTrigger,
    dispatchEmailSettings,
    loadingSetting,
    setHandleEmailTrigger,
    ratePerMile,
    handleRatePerMileChange,
}) => {
    return (
        <Box
            sx={{
                pt: 3,
                pb: 10,
                display: 'grid',
                gridTemplateColumns: {
                    xs: '100%',
                    sm: 'minmax(120px, 30%) 1fr',
                    lg: '280px 1fr minmax(120px, 208px)',
                },
                columnGap: { xs: 2, sm: 3, md: 10 },
                rowGap: { xs: 2, sm: 2.5 },
                '& > hr': {
                    gridColumn: '1/-1',
                },
            }}
        >
            <Box>
                <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>State information</FormLabel>
                <Typography level="body-xs" sx={{ color: 'text.secondary', mt: 1 }}>
                    Choose whether you want to include or exclude specific states from your service area. This helps define where your services are available.
                </Typography>
            </Box>
            <Box sx={{ display: { xs: 'contents', sm: 'flex' }, gap: 2 }}>
                <FormControl sx={{ flex: 0.5 }}>
                    <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                        <FormLabel sx={{ height: '100%', minWidth: 'max-content' }}>I prefer to have</FormLabel>
                        <Select size="sm" defaultValue="exclusion" onChange={(_, value) => setIsExclusionList(value === 'exclusion')} sx={{ minWidth: 170 }}>
                            <Option value="exclusion">excluded states list</Option>
                            <Option value="inclusion">desired states list</Option>
                        </Select>
                    </Stack>

                    <div>
                        <StateSelector
                            initialData={isExclusionList ? excludedStates : includedStates}
                            setStateData={isExclusionList ? setExcludedStates : setIncludedStates}
                            data={statesList}
                            label={isExclusionList ? 'States to exclude' : 'States to include'}
                        />
                    </div>
                </FormControl>
            </Box>
            <Divider role="presentation" />
            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Mountain Area</FormLabel>
            <Box sx={{ display: { xs: 'contents', sm: 'flex' }, gap: 2 }}>
                <FormControlLabel
                    control={<Checkbox defaultChecked={isMountainAreaIncluded} onChange={handleCheckboxChange} />}
                    label="Exclude mountain area"
                    sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                />
            </Box>
            <Divider role="presentation" />
            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Select Field Type</FormLabel>
            <Box sx={{ display: { xs: 'contents', sm: 'flex', width: '100%' }, gap: 2 }}>
                <FormControl sx={{ flex: 0.5 }}>
                    <Typography gutterBottom>Set average fuel cost per gallon</Typography>
                    <PriceProgramSelector data={pricesList} initialData={fuelCost ? String(fuelCost) : ''} setStateData={setFuelCost} />
                </FormControl>
            </Box>
            <Divider role="presentation" />
            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Average Fuel Cost (USD)</FormLabel>
            <Box sx={{ display: { xs: 'contents', sm: 'flex', width: '100%' }, gap: 2 }}>
                <FormControl sx={{ flex: 0.5 }}>
                    <Typography gutterBottom>Fuel cost per gallon</Typography>
                    <Input
                        variant="outlined"
                        defaultValue={fuelCost as number}
                        value={fuelCost as number}
                        onChange={handleFuelCostChange}
                        fullWidth
                        type="number"
                    />
                </FormControl>
            </Box>
            <Divider role="presentation" />
            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Average Driver Cost (USD)</FormLabel>
            <Box sx={{ display: { xs: 'contents', sm: 'flex', width: '100%' }, gap: 2 }}>
                <FormControl sx={{ flex: 0.5 }}>
                    <Typography gutterBottom>Driver cost per mile </Typography>
                    <Input
                        variant="outlined"
                        defaultValue={driverCost as number}
                        value={driverCost as number}
                        onChange={handleDriverCostChange}
                        fullWidth
                        type="number"
                    />
                </FormControl>
            </Box>
            <Divider role="presentation" />
            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Set Your Rate (USD)</FormLabel>
            <Box sx={{ display: { xs: 'contents', sm: 'flex', width: '100%' }, gap: 2 }}>
                <FormControl sx={{ flex: 0.5 }}>
                    <Typography gutterBottom>Rate per mile</Typography>
                    <Input
                        variant="outlined"
                        defaultValue={ratePerMile as number}
                        value={ratePerMile as number}
                        onChange={handleRatePerMileChange}
                        fullWidth
                        type="number"
                    />
                </FormControl>
            </Box>
            <Divider role="presentation" />
            <FormLabel sx={{ display: 'flex', alignItems: 'start' }}>Enable Filter events</FormLabel>
            <Box sx={{ display: 'flex', maxWidth: '360px', flexDirection: 'column', gap: 2 }}>
                <FormControlLabel
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    control={<Checkbox onChange={(event: React.ChangeEvent<HTMLInputElement>) => setHandleEmailTrigger(event.target.checked)} />}
                    label="Filter events on this trigger"
                />
                <Box>{handleEmailTrigger ? <FilterEmailTriggerUI /> : null}</Box>
            </Box>

            <Box
                sx={{
                    gridColumn: '1/-1',
                    justifySelf: 'flex-start',
                    display: 'flex',
                    gap: 1,
                }}
            >
                <Button variant="outlined" color="neutral" size="sm">
                    Cancel
                </Button>
                <Button size="sm" onClick={dispatchEmailSettings} loading={loadingSetting}>
                    Save
                </Button>
            </Box>
        </Box>
    )
}

export default EmailSettingsTab
