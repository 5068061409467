/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react'
import { Box, Input, Button, Select, Option, Autocomplete } from '@mui/joy'
import { allTypes, subTypes } from '../../../../../../../utils/truckEquipmentTypes'
import { EquipmentType } from '@numeo/types'
import dayjs from 'dayjs'
import { useWebSocket } from '../../../../../../../context/WebSocketContext'
import StopIcon from '@mui/icons-material/Stop'
import { cityStates } from '../assets'
import { DateRangePicker } from './DateRangePicker'
import { useGetQuery } from '../../../../../../../hooks/useGetQuery'

interface DateRange {
    startDate: string
    endDate: string
}

interface SearchState {
    origin: { label: string; id: number }
    destination: { label: string; id: number }
    equipmentType: string
    loadType: string
    length: number | string
    weight: number | string
    maxOriginDeadheadMiles: number | string
    maxDestinationDeadheadMiles: number | string
    dateRange: DateRange
}

interface SearchData {
    latestWhen: string | null
    earliestWhen: string | null
    origin: string
    destination: string
    maxOriginDeadheadMiles: number
    maxDestinationDeadheadMiles: number
    equipmentType: string[]
    length?: number
    weight?: number
}

interface TruckDataType {
    equipmentType?: string
    length?: string | number
    weight?: string | number
    deadHeadOrigin?: string | number
    deadHeadDestination?: string | number
}

const defaultState: SearchState = {
    origin: { label: '', id: 0 },
    destination: { label: '', id: 0 },
    equipmentType: '',
    loadType: 'BOTH',
    length: '',
    weight: '',
    maxOriginDeadheadMiles: 150,
    maxDestinationDeadheadMiles: 150,
    dateRange: {
        startDate: dayjs().format('MM/DD/YYYY'),
        endDate: dayjs().format('MM/DD/YYYY'),
    },
}

const SearchBar: React.FC = () => {
    const threadId = useGetQuery('threadId')
    const { sendMessageToService, currentThreadId, truckData, messagesLoading, threadSearchStatuses, stopSearch, currentSearchParams } = useWebSocket()
    const [availableEquipmentTypes] = useState<EquipmentType[]>([...allTypes, ...subTypes])
    const [searchArgs, setSearchArgs] = useState<SearchState>(defaultState)
    const [isSearching, setIsSearching] = useState(false)
    const prevSearchArgsRef = useRef<SearchState>(searchArgs)

    useEffect(() => {
        if (threadId) {
            setIsSearching(threadSearchStatuses[threadId]?.searchStatus === 'active')
        }
    }, [threadId, threadSearchStatuses])

    // New useEffect to handle searchParams updates
    useEffect(() => {
        // Only update search args when changing trucks (threadId changes) and not searching
        if (currentSearchParams) {
            const {
                origin,
                destination,
                earliestAvailability,
                latestAvailability,
                equipmentType,
                maxOriginDeadheadMiles,
                maxDestinationDeadheadMiles,
                maximumLengthFeet,
                maximumWeightPounds,
            } = currentSearchParams

            setSearchArgs({
                ...defaultState,
                origin: {
                    label: `${origin.city}, ${origin.state}`,
                    id: 0,
                },
                destination: {
                    label: `${destination.city}, ${destination.state}`,
                    id: 0,
                },
                equipmentType: equipmentType,
                maxOriginDeadheadMiles: maxOriginDeadheadMiles,
                maxDestinationDeadheadMiles: maxDestinationDeadheadMiles,
                length: maximumLengthFeet,
                weight: maximumWeightPounds,
                dateRange: {
                    startDate: earliestAvailability,
                    endDate: latestAvailability,
                },
            })
        } else {
            const today = dayjs().format('MM/DD/YYYY')
            setSearchArgs({
                ...defaultState,
                dateRange: {
                    startDate: today,
                    endDate: today,
                },
                equipmentType: truckData?.equipmentType || '', // Use truck's equipment type when searchParams is null
                length: truckData?.length || '',
                weight: truckData?.weight || '',
                maxOriginDeadheadMiles: truckData?.deadHeadOrigin || 150,
                maxDestinationDeadheadMiles: truckData?.deadHeadDestination || 150,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadId, currentSearchParams])

    const isSearchDisabled = () => {
        const requiredFieldsFilled =
            searchArgs.origin?.label &&
            searchArgs.destination?.label &&
            searchArgs.equipmentType &&
            searchArgs.dateRange.startDate &&
            searchArgs.dateRange.endDate

        // If searching, button should always be enabled to allow stopping
        if (isSearching) {
            return false
        }

        // If not searching, check if required fields are filled
        return !requiredFieldsFilled
    }

    const handleSearch = () => {
        // If already searching, stop the search
        if (isSearching) {
            setIsSearching(false)
            stopSearch(currentThreadId as string, threadSearchStatuses[currentThreadId]?.searchTaskId as string)
            return
        }

        // Start new search
        const { length, weight, ...rest } = searchArgs
        const data: SearchData = {
            ...rest,
            latestWhen: searchArgs.dateRange.endDate ? dayjs(searchArgs.dateRange.endDate).format('MM/DD/YYYY') : null,
            earliestWhen: searchArgs.dateRange.startDate ? dayjs(searchArgs.dateRange.startDate).format('MM/DD/YYYY') : null,
            origin: searchArgs.origin?.label,
            destination: searchArgs.destination?.label,
            maxOriginDeadheadMiles: Number(searchArgs.maxOriginDeadheadMiles) <= 450 ? Number(searchArgs.maxOriginDeadheadMiles) : 450,
            maxDestinationDeadheadMiles: Number(searchArgs.maxDestinationDeadheadMiles) <= 450 ? Number(searchArgs.maxDestinationDeadheadMiles) : 450,
            equipmentType: searchArgs.equipmentType.split(','),
        }

        // Only include length and weight if they are provided
        if (length && length.toString().trim() !== '') {
            data.length = Number(length)
        }
        if (weight && weight.toString().trim() !== '') {
            data.weight = Number(weight)
        }

        setIsSearching(true)
        prevSearchArgsRef.current = { ...searchArgs }
        if (sendMessageToService && currentThreadId) {
            sendMessageToService(data, currentThreadId)
        }
    }

    // Update search status when threadSearchStatuses changes
    useEffect(() => {
        if (currentThreadId && threadSearchStatuses[currentThreadId]?.searchStatus !== 'active') {
            setIsSearching(false)
        }
    }, [threadSearchStatuses, currentThreadId])

    const handleChange = (prop) => (eventOrValue) => {
        const value = eventOrValue && eventOrValue.target ? eventOrValue.target.value : eventOrValue
        setSearchArgs({ ...searchArgs, [prop]: value })
    }

    const handleAutocompleteChange = (prop) => (_, newValue) => {
        setSearchArgs({ ...searchArgs, [prop]: newValue })
    }

    const handleDateRangeChange = (newValue: DateRange) => {
        setSearchArgs((prev) => ({
            ...prev,
            dateRange: newValue,
        }))
    }

    // Initialize equipment type when truck data first loads
    useEffect(() => {
        if (truckData && !searchArgs.equipmentType) {
            setSearchArgs((prev) => ({
                ...defaultState,
                ...prev,
                equipmentType: truckData.equipmentType,
                length: (truckData as TruckDataType).length || '',
                weight: (truckData as TruckDataType).weight || '',
                maxOriginDeadheadMiles: (truckData as TruckDataType).deadHeadOrigin || '150',
                maxDestinationDeadheadMiles: (truckData as TruckDataType).deadHeadDestination || '150',
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [truckData])

    return (
        <Box
            sx={(theme) => ({
                display: 'grid',
                gap: 2,
                width: '100%',
                p: 2,
                backgroundColor: theme.palette.mode === 'dark' ? '#2b2b2b' : '#f7f7f7',
                borderRadius: '0.5rem',

                // Three rows (< 1280px)
                '@media (max-width: 1279px)': {
                    gridTemplateColumns: 'repeat(100, 1fr)',
                    gap: 1,
                    // First row
                    '& > .origin-group': {
                        gridColumn: 'span 50',
                        order: 1,
                    },
                    '& > .destination-group': {
                        gridColumn: 'span 50',
                        order: 2,
                    },
                    // Second row
                    '& > .equipment-type': {
                        gridColumn: 'span 40',
                        order: 3,
                    },
                    '& > .load-type': {
                        gridColumn: 'span 30',
                        order: 4,
                    },
                    '& > .length-input': {
                        gridColumn: 'span 30',
                        order: 5,
                    },
                    // Third row
                    '& > .weight-input': {
                        gridColumn: 'span 30',
                        order: 6,
                    },
                    '& > .date-range': {
                        gridColumn: 'span 45',
                        order: 7,
                    },
                    '& > .search-button': {
                        gridColumn: 'span 25',
                        order: 8,
                    },
                },

                // Two rows (1280px - 1919px)
                '@media (min-width: 1280px) and (max-width: 1919px)': {
                    gridTemplateColumns: 'repeat(100, 1fr)',
                    gap: 1,
                    '& > .origin-group': {
                        gridColumn: 'span 50',
                        order: 1,
                    },
                    '& > .destination-group': {
                        gridColumn: 'span 50',
                        order: 2,
                    },
                    '& > .length-input': {
                        gridColumn: 'span 14',
                        order: 3,
                    },
                    '& > .weight-input': {
                        gridColumn: 'span 14',
                        order: 4,
                    },
                    '& > .load-type': {
                        gridColumn: 'span 15',
                        order: 5,
                    },
                    '& > .equipment-type': {
                        gridColumn: 'span 22',
                        order: 6,
                    },
                    '& > .date-range': {
                        gridColumn: 'span 23',
                        order: 7,
                    },
                    '& > .search-button': {
                        gridColumn: 'span 12',
                        order: 8,
                    },
                },

                // One row (≥ 1920px)
                '@media (min-width: 1920px)': {
                    gridTemplateColumns: 'repeat(100, 1fr)',
                    gap: 1,
                    '& > .origin-group, & > .destination-group': {
                        gridColumn: 'span 18',
                    },
                    '& > .length-input, & > .weight-input': {
                        gridColumn: 'span 8',
                    },
                    '& > .equipment-type': {
                        gridColumn: 'span 14',
                    },
                    '& > .load-type': {
                        gridColumn: 'span 10',
                    },
                    '& > .date-range': {
                        gridColumn: 'span 16',
                    },
                    '& > .search-button': {
                        gridColumn: 'span 8',
                    },
                },

                '& > div': {
                    margin: 0,
                    '& .MuiInput-root, & .MuiAutocomplete-root, & .MuiSelect-root, & .MuiButton-root, & .date-range-picker': {
                        height: '2.5rem',
                        minHeight: '2.5rem',
                        maxHeight: '2.5rem',
                        '--Input-minHeight': '2.5rem',
                        '--Input-height': '2.5rem',
                        '--Input-paddingInline': '0.5rem',
                        '--Input-gap': '0.5rem',
                        '--Input-decoratorChildHeight': '2.5rem',
                    },
                    '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                    },
                    '& input[type="number"]': {
                        '-moz-appearance': 'textfield',
                    },
                },
            })}
        >
            <Box className="origin-group">
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Autocomplete
                        placeholder="Origin"
                        options={cityStates}
                        value={searchArgs.origin}
                        onChange={handleAutocompleteChange('origin')}
                        sx={{ flex: 1 }}
                    />
                    <Input
                        type="number"
                        value={searchArgs.maxOriginDeadheadMiles as number}
                        onChange={(e) => {
                            const value = Number(parseInt(e.target.value)) || ''
                            if (!isNaN(value as number)) {
                                handleChange('maxOriginDeadheadMiles')({ target: { value } })
                            } else {
                                handleChange('maxOriginDeadheadMiles')({ target: { value: '' } })
                            }
                        }}
                        placeholder="DH"
                        sx={{ width: '4.5rem' }}
                    />
                </Box>
            </Box>

            <Box className="destination-group">
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Autocomplete
                        placeholder="Destination"
                        options={cityStates}
                        value={searchArgs.destination}
                        onChange={handleAutocompleteChange('destination')}
                        sx={{ flex: 1 }}
                    />
                    <Input
                        type="number"
                        value={searchArgs.maxDestinationDeadheadMiles as number}
                        onChange={(e) => {
                            const value = parseInt(e.target.value) || ''
                            if (!isNaN(value as number)) {
                                handleChange('maxDestinationDeadheadMiles')({ target: { value } })
                            } else {
                                handleChange('maxDestinationDeadheadMiles')({ target: { value: '' } })
                            }
                        }}
                        placeholder="DH"
                        sx={{ width: '4.5rem' }}
                    />
                </Box>
            </Box>

            <Box className="length-input">
                <Input
                    type="number"
                    value={searchArgs.length || ''}
                    onChange={(e) => {
                        const value = parseInt(e.target.value) || 0
                        handleChange('length')({ target: { value } })
                    }}
                    placeholder="Length (ft)"
                    sx={{ width: '100%' }}
                />
            </Box>

            <Box className="weight-input">
                <Input
                    type="number"
                    value={searchArgs.weight || ''}
                    onChange={(e) => {
                        const value = parseInt(e.target.value) || 0
                        handleChange('weight')({ target: { value } })
                    }}
                    placeholder="Weight (lbs)"
                    sx={{ width: '100%' }}
                />
            </Box>

            <Box className="equipment-type">
                <Select
                    multiple
                    value={searchArgs.equipmentType ? searchArgs.equipmentType.split(',') : []}
                    onChange={(_, newValue) => handleChange('equipmentType')(newValue.join(','))}
                    placeholder="Equipment Type"
                    sx={{ width: '100%' }}
                >
                    {availableEquipmentTypes.map((type) => (
                        <Option key={type.code} value={type.code}>
                            {type.name}
                        </Option>
                    ))}
                </Select>
            </Box>

            <Box className="load-type">
                <Select
                    value={searchArgs.loadType}
                    onChange={(_, newValue) => handleChange('loadType')(newValue)}
                    placeholder="Load Type"
                    sx={{ width: '100%' }}
                >
                    <Option value="BOTH">Full & Partial</Option>
                    <Option value="FULL">Full</Option>
                    <Option value="PARTIAL">Partial</Option>
                </Select>
            </Box>

            <Box className="date-range">
                <DateRangePicker value={searchArgs.dateRange} onChange={handleDateRangeChange} sx={{ width: '100%' }} />
            </Box>

            <Box className="search-button">
                <Button
                    variant="solid"
                    color="primary"
                    loading={messagesLoading}
                    disabled={isSearchDisabled()}
                    onClick={handleSearch}
                    endDecorator={
                        isSearching && !messagesLoading ? (
                            <StopIcon
                                sx={{
                                    fontSize: '1.25rem',
                                    color: 'red',
                                    animation: 'pulse 1.5s infinite',
                                    '@keyframes pulse': {
                                        '0%': {
                                            opacity: 1,
                                        },
                                        '50%': {
                                            opacity: 0.5,
                                        },
                                        '100%': {
                                            opacity: 1,
                                        },
                                    },
                                }}
                            />
                        ) : null
                    }
                    sx={{
                        width: '100%',
                        '& .MuiButton-endDecorator': {
                            margin: 0,
                        },
                    }}
                >
                    {isSearching && !messagesLoading ? 'Stop Searching' : 'Search'}
                </Button>
            </Box>
        </Box>
    )
}

export default SearchBar
