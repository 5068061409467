export const BASE_STYLES = `
    body {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        font-size: 14px;
        line-height: 1.5;
        color: #1C2025;
        margin: 0;
        padding: 0;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    a {
        color: #0B6BCB;
        text-decoration: none;
    }
    blockquote {
        border-left: 3px solid #CDD7E1;
        margin: 0;
        padding-left: 1em;
        color: #4B5259;
    }
`

export const HTML_TEMPLATE = (content: string, mode: string = 'light') => `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <style>

      body {
        margin: 0;
        padding: 16px;
        font-family: system-ui, -apple-system, sans-serif;
        background-color: ${mode === 'dark' ? '#0B0D0E' : '#ffffff'};
        color: ${mode === 'dark' ? '#9FA6AD' : '#000'} !important;
      }

      p,h1,h2,h3,a,span{
        color: ${mode === 'dark' ? '#9FA6AD' : '#000'} !important;
      }

      a {
        color: ${mode === 'dark' ? 'var(--joy-palette-primary-400)' : 'var(--joy-palette-primary-500)'} !important;
      }

      pre,
      code {
        background-color: ${mode === 'dark' ? 'var(--joy-palette-background-level1)' : 'var(--joy-palette-background-level1)'} !important;
        padding: 8px;
        border-radius: 4px;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      blockquote {
        border-left: 3px solid ${mode === 'dark' ? 'var(--joy-palette-neutral-400)' : 'var(--joy-palette-neutral-300)'} !important;
        margin: 0;
        padding-left: 16px;
        color:${mode === 'dark' ? 'var(--joy-palette-text-secondary)' : 'var(--joy-palette-text-secondary)'} !important;
      }
    </style>
  </head>
  <body>${content}</body>
</html>
`

export const SANITIZE_OPTIONS = {
    FORBID_TAGS: ['script', 'iframe', 'object', 'embed'],
    ADD_ATTR: ['target'], // Allow target attribute for links
    ADD_TAGS: ['iframe'], // Allow specific iframes if needed
    SANITIZE_DOM: true,
    ALLOW_DATA_ATTR: false,
}
