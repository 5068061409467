import axios from 'axios'

class spotFinder {
    async get(endpoint: string) {
        try {
            const response = await axios.get(endpoint)
            return response.data
        } catch (error) {
            console.log('Error getting data ', error)
        }
    }

    async post<T>(endpoint: string, data: T) {
        try {
            const response = await axios.post(endpoint, data)
            return response.data
        } catch (error) {
            console.log('Error getting data ', error)
        }
    }
    async delete(endpoint: string) {
        try {
            const response = await axios.delete(endpoint)
            return response.data
        } catch (error) {
            console.log('Error getting data ', error)
            throw error
        }
    }
    async put<T>(endpoint: string, data: T) {
        try {
            const response = await axios.put(endpoint, data)
            return response.data
        } catch (error) {
            console.log('Error getting data ', error)
        }
    }
}

const spotApi = new spotFinder()
export default spotApi
