export const tableColors = {
    light: {
        background: '#f5f5f5',
        headerBg: '#e0e0e0',
        text: '#1a1a1a',
        hoverBg: '#e9effe',
        linkColor: '#1976d2',
        svgColor: '#0046e0',
        tripColor: '#0046e0',
        separator: '#e0e0e0',
        rowOddBg: '#ffffff',
        rowEvenBg: '#f5f5f5',
        overlay: 'rgba(255, 255, 255, 0.7)',
        cancelled: {
            bg: '#ffebee',
            text: '#c62828',
        },
        accordion: {
            bg: '#fff',
            border: '#e0e0e0',
            title: '#333333',
            label: '#333333',
            text: '#555555',
            sectionTitle: '#333333',
            titleBg: '#f5f5f5',
        },
    },
    dark: {
        background: '#333333',
        headerBg: '#424242',
        text: '#ffffff',
        hoverBg: '#424242',
        linkColor: '#90caf9',
        svgColor: '#90caf9',
        tripColor: '#4695ff',
        separator: '#555555',
        rowOddBg: '#333333',
        rowEvenBg: '#2b2b2b',
        overlay: 'rgba(0, 0, 0, 0.7)',
        cancelled: {
            bg: '#4a1515',
            text: '#ff5252',
        },
        accordion: {
            bg: '#444444',
            border: '#666666',
            title: '#333333',
            label: '#e0e0e0',
            text: '#cccccc',
            sectionTitle: '#ffffff',
            titleBg: '#ffffff',
        },
    },
}
