import { Box, Sheet, Table, Tooltip, Typography, useColorScheme, CircularProgress, Checkbox, Button, IconButton, Input, Snackbar } from '@mui/joy'
import React, { useEffect, useState, useMemo } from 'react'
import DatDirectionIcons from 'assets/icons/DatDirectionIcons'
import { HorizontalDirectionIcon, VerticalDirectionIcon } from 'assets/icons/DatDirectionIcons'
import { DatabaseLoad } from '@numeo/types'
import { useWebSocket } from 'context/WebSocketContext'
import CheckIcon from '@mui/icons-material/Check'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import EmailModal from './EmailModal'
import { Popover, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import EmailIcon from '@mui/icons-material/Email'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import './assets/styles.css'
import { calculateRatePerMile, formatPhoneNumber, sendInfoEmail, sortLoads, timeDisplay, useColspanManager } from './assets/helpers'
import { tableColors } from './assets/utils'
import { useAuth } from 'hooks/auth/Provider'
import { useGetQuery } from 'hooks/useGetQuery'

export default function LoadsTableS({ loads }: { loads: DatabaseLoad[]; sortOption: string }) {
    const { mode } = useColorScheme()
    const { messagesLoading, onLoad } = useWebSocket()
    const { application } = useAuth()
    const threadId = useGetQuery('threadId')
    const projectName = application?.projectName

    const [selectedLoads, setSelectedLoads] = useState<Set<string>>(new Set())
    const [hiddenLoads, setHiddenLoads] = useState<Set<string>>(new Set())
    const [isRequestRateLoading, setIsRequestRateLoading] = useState(false)
    const [snackbar, setSnackbar] = useState<{
        open: boolean
        message: string
        color: 'success' | 'danger'
    }>({
        open: false,
        message: '',
        color: 'success',
    })
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [confirmationAnchorEl, setConfirmationAnchorEl] = useState<null | HTMLElement>(null)
    const [activeColumn, setActiveColumn] = useState<string | null>(null)
    const [sortConfigs, setSortConfigs] = useState<{ [key: string]: { key: string; direction: 'asc' | 'desc' | null } }>({})
    const [expandedLoads, setExpandedLoads] = useState<Set<string>>(new Set())
    const [maxMiles, setMaxMiles] = useState<string>('')
    const [minMiles, setMinMiles] = useState<string>('')
    const [minRate, setMinRate] = useState<string>('')
    const [popoverOpen, setPopoverOpen] = useState<string | null>(null)
    const [emailSendingLoadId, setEmailSendingLoadId] = useState<string | null>(null)
    const [emailSentLoads, setEmailSentLoads] = useState<Set<string>>(new Set())

    useEffect(() => {
        const sentLoads = new Set(loads.filter((load) => load.isEmailSent).map((load) => load.matchId))
        setEmailSentLoads(sentLoads)
    }, [loads])

    useEffect(() => {
        setSelectedLoads(new Set())
    }, [threadId])

    const updateEmailStatus = (loadId: string) => {
        setEmailSentLoads((prev) => new Set([...prev, loadId]))
    }

    // Use the colspan manager hook
    const colspan = useColspanManager('.loads-table-small')

    useEffect(() => {
        const sentLoads = new Set(loads.filter((load) => load.isEmailSent).map((load) => load.matchId))
        setEmailSentLoads(sentLoads)
    }, [loads])

    useEffect(() => {
        setSelectedLoads(new Set())
    }, [threadId])

    const filteredLoads = loads.filter((load) => {
        const tripLength = load.tripLength
        const rate = load.rateInfo?.rateUsd

        if (maxMiles && tripLength > parseFloat(maxMiles)) return false
        if (minMiles && tripLength < parseFloat(minMiles)) return false
        if (minRate && (!rate || rate < parseFloat(minRate))) return false

        return !hiddenLoads.has(load.matchId)
    })

    const handleRowClick = (load: DatabaseLoad) => {
        if (load.status === 'cancelled') return
        setExpandedLoads((prev) => {
            const newSet = new Set(prev)
            if (newSet.has(load.matchId)) {
                newSet.delete(load.matchId)
            } else {
                newSet.add(load.matchId)
            }
            return newSet
        })
    }

    const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>, loadMatchId: string) => {
        e.stopPropagation()
        setSelectedLoads((prev) => {
            const newSet = new Set(prev)
            if (newSet.has(loadMatchId)) {
                newSet.delete(loadMatchId)
            } else {
                newSet.add(loadMatchId)
            }
            return newSet
        })
    }

    const handleHideLoads = () => {
        setHiddenLoads((prev) => new Set([...prev, ...selectedLoads]))
        setSelectedLoads(new Set())
    }

    const handleHeaderClick = (event: React.MouseEvent<HTMLElement>, columnName: string) => {
        setAnchorEl(event.currentTarget)
        setActiveColumn(columnName)
    }

    const handleSortOptionChange = (option: string) => {
        // Reset all sorting configs and set only the active column's sort
        setSortConfigs({
            [activeColumn || '']: { key: option, direction: 'asc' },
        })
        handleClosePopover()
    }

    const sortedLoads = useMemo(() => {
        return Object.entries(sortConfigs).reduce((loads, [, config]) => {
            return sortLoads(loads, config.key)
        }, filteredLoads)
    }, [filteredLoads, sortConfigs])

    const handleClosePopover = () => {
        setAnchorEl(null)
    }

    const handleResetFilters = () => {
        setMaxMiles('')
        setMinMiles('')
        setMinRate('')
    }

    const handleConfirmationPopoverOpen = (event: React.MouseEvent<HTMLElement>, loadId: string) => {
        event.stopPropagation()
        setPopoverOpen(loadId)
        setConfirmationAnchorEl(event.currentTarget)
    }

    const handleConfirmationPopoverClose = () => {
        setPopoverOpen(null)
        setConfirmationAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const colors = tableColors[mode as 'light' | 'dark']

    const convertPickupDates = (earliestWhen: string, latestWhen: string): JSX.Element => {
        const formatDate = (date: Date): string => {
            const month = (date.getMonth() + 1).toString()
            const day = date.getDate().toString()
            return `${month}/${day}`
        }

        const earliestDate = formatDate(new Date(earliestWhen))
        const latestDate = formatDate(new Date(latestWhen))

        if (earliestDate === latestDate) {
            return <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{earliestDate}</Typography>
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{earliestDate}</Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}> {latestDate}</Typography>
            </Box>
        )
    }

    const getIconStyle = (option: string, column?: string) => {
        return {
            transform: sortConfigs[column || '']?.key === option ? 'rotate(180deg)' : 'rotate(0deg)',
            color: sortConfigs[column || '']?.key === option ? '#1976d2' : '#757575',
            verticalAlign: 'middle',
            marginLeft: '-5px',
        }
    }

    // Handle select all loads
    const handleSelectAll = () => {
        if (selectedLoads.size > 0) {
            // If any loads are selected, unselect all
            setSelectedLoads(new Set())
        } else {
            // If no loads are selected, select all
            const newSelectedLoads = new Set(filteredLoads.map((load) => load.matchId))
            setSelectedLoads(newSelectedLoads)
        }
    }

    const eligibleLoadsForRateRequest = useMemo(() => {
        return Array.from(selectedLoads).filter((loadId) => {
            const load = loads.find((l) => l.matchId === loadId)
            return (
                load &&
                load.posterInfo?.contact?.email &&
                (!load.rateInfo?.rateUsd || load.rateInfo.rateUsd <= 0) &&
                !load.isEmailSent &&
                !emailSentLoads.has(loadId)
            )
        }).length
    }, [loads, selectedLoads, emailSentLoads])

    const allSelectedLoadsAreEligible = useMemo(() => {
        return selectedLoads.size > 0 && selectedLoads.size === eligibleLoadsForRateRequest
    }, [selectedLoads.size, eligibleLoadsForRateRequest])

    const handleRequestRateMultiple = async () => {
        setIsRequestRateLoading(true)
        const loadsWithEmail = Array.from(selectedLoads)
            .map((loadId) => loads.find((l) => l.matchId === loadId))
            .filter(
                (load): load is DatabaseLoad =>
                    !!load &&
                    !!load.posterInfo?.contact?.email &&
                    (!load.rateInfo?.rateUsd || load.rateInfo.rateUsd <= 0) &&
                    !load.isEmailSent &&
                    !emailSentLoads.has(load.matchId)
            )

        if (loadsWithEmail.length === 0) {
            setIsRequestRateLoading(false)
            setSnackbar({
                open: true,
                message: 'No loads without a rate and with an email are selected',
                color: 'danger',
            })
            return
        }

        try {
            // Send emails and update status in parallel
            const emailPromises = loadsWithEmail.map((load) =>
                sendInfoEmail(load, threadId || '', projectName || '', (success) => {
                    if (success) {
                        updateEmailStatus(load.matchId)
                    }
                })
            )

            await Promise.all(emailPromises)
            setSnackbar({
                open: true,
                message: `Successfully sent rate request emails to ${loadsWithEmail.length} load${loadsWithEmail.length > 1 ? 's' : ''}`,
                color: 'success',
            })
            setSelectedLoads(new Set())
        } catch (error) {
            console.error('Error sending emails:', error)
            setSnackbar({
                open: true,
                message: 'Failed to send rate requests',
                color: 'danger',
            })
        } finally {
            setIsRequestRateLoading(false)
        }
    }

    const handleSnackbarClose = () => {
        setSnackbar((prev) => ({ ...prev, open: false }))
    }

    const handleEmailClick = async (e: React.MouseEvent, load: DatabaseLoad) => {
        e.stopPropagation()
        if (!load.rateInfo?.rateUsd) {
            // If rate is not available, send info email directly
            setEmailSendingLoadId(load.matchId)
            try {
                await sendInfoEmail(
                    load,
                    threadId || '',
                    projectName || '',
                    (success) => {
                        setSnackbar({
                            open: true,
                            message: success ? 'Email sent successfully!' : 'Failed to send email',
                            color: success ? 'success' : 'danger',
                        })
                    },
                    updateEmailStatus
                )
            } finally {
                setEmailSendingLoadId(null)
            }
        } else {
            // If rate is available, open the modal
            handleRowClick(load)
        }
    }

    return (
        <Box sx={{ width: '100%', position: 'relative' }}>
            {messagesLoading || onLoad ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: colors.overlay,
                        zIndex: 1,
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : null}
            {loads && loads.length > 0 ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                        mt: 1.5,
                        height: '100%',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'end', width: '100%', justifyContent: 'space-between', gap: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                    color: 'text.secondary',
                                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#2b2b2b' : '#f0f0f0'),
                                    padding: '0.5rem 1rem',
                                    borderRadius: '0.25rem',
                                    fontWeight: 500,
                                }}
                            >
                                {filteredLoads.length} Results
                            </Typography>
                            {selectedLoads.size > 0 && eligibleLoadsForRateRequest > 0 && (
                                <Tooltip
                                    title={
                                        !allSelectedLoadsAreEligible
                                            ? 'Some selected loads are not eligible (must have email, no rate, and no email sent)'
                                            : `Will send rate request emails to ${eligibleLoadsForRateRequest} selected load(s)`
                                    }
                                    variant="solid"
                                >
                                    <span>
                                        <Button
                                            variant="soft"
                                            color="primary"
                                            disabled={!allSelectedLoadsAreEligible}
                                            onClick={handleRequestRateMultiple}
                                            startDecorator={isRequestRateLoading ? <CircularProgress size="sm" /> : null}
                                        >
                                            Request Rate ({eligibleLoadsForRateRequest})
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center',
                                    backgroundColor: mode === 'light' ? 'rgba(25, 118, 210, 0.08)' : 'rgba(144, 202, 249, 0.08)',
                                    padding: '8px 16px',
                                    borderRadius: '8px',
                                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                        '-webkit-appearance': 'none',
                                        margin: 0,
                                    },
                                    '& input[type=number]': {
                                        '-moz-appearance': 'textfield',
                                    },
                                }}
                            >
                                <Typography level="body-sm" sx={{ color: mode === 'light' ? 'text.secondary' : 'text.primary' }}>
                                    Filter by:
                                </Typography>
                                <Input
                                    size="sm"
                                    placeholder="Max Miles"
                                    value={maxMiles}
                                    onChange={(e) => setMaxMiles(e.target.value)}
                                    type="number"
                                    sx={{
                                        width: '100px',
                                        backgroundColor: mode === 'light' ? 'background.body' : 'background.level1',
                                    }}
                                    slotProps={{
                                        input: {
                                            min: 0,
                                        },
                                    }}
                                />
                                <Input
                                    size="sm"
                                    placeholder="Min Miles"
                                    value={minMiles}
                                    onChange={(e) => setMinMiles(e.target.value)}
                                    type="number"
                                    sx={{
                                        width: '100px',
                                        backgroundColor: mode === 'light' ? 'background.body' : 'background.level1',
                                    }}
                                    slotProps={{
                                        input: {
                                            min: 0,
                                        },
                                    }}
                                />
                                <Input
                                    size="sm"
                                    placeholder="Min Rate"
                                    value={minRate}
                                    onChange={(e) => setMinRate(e.target.value)}
                                    type="number"
                                    sx={{
                                        width: '100px',
                                        backgroundColor: mode === 'light' ? 'background.body' : 'background.level1',
                                    }}
                                    slotProps={{
                                        input: {
                                            min: 0,
                                        },
                                    }}
                                />
                                <Button
                                    size="sm"
                                    variant="soft"
                                    color="neutral"
                                    onClick={handleResetFilters}
                                    sx={{
                                        backgroundColor: mode === 'light' ? 'rgba(25, 118, 210, 0.08)' : 'rgba(144, 202, 249, 0.08)',
                                        '&:hover': {
                                            backgroundColor: mode === 'light' ? 'rgba(25, 118, 210, 0.12)' : 'rgba(144, 202, 249, 0.12)',
                                        },
                                    }}
                                >
                                    Reset
                                </Button>
                            </Box>
                            <Button onClick={handleHideLoads} variant="soft" color="neutral" disabled={selectedLoads.size === 0} sx={{ ml: 1 }}>
                                Hide Selected Loads ({selectedLoads.size})
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', maxWidth: '100%', height: '100%', overflow: 'auto', mt: 2 }}>
                        <Sheet
                            variant="outlined"
                            sx={{
                                bgcolor: colors.background,
                                borderRadius: '8px',
                                boxShadow: 'sm',
                                width: '100%',
                                height: 'calc(100vh - 19rem)',
                                maxWidth: '100%',
                                overflow: 'auto',
                            }}
                        >
                            <Table
                                className="loads-table-small"
                                sx={{
                                    '& thead': {
                                        bgcolor: colors.headerBg,
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 1,
                                    },
                                    '& th': {
                                        color: colors.text,
                                        fontWeight: 'bold',
                                        fontSize: 'sm',
                                        margin: '4px',
                                        position: 'relative',
                                        '& span': {
                                            color: colors.text,
                                        },
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: '50%',
                                            right: 0,
                                            height: '50%',
                                            width: '1px',
                                            backgroundColor: colors.separator,
                                            transform: 'translateY(-50%)',
                                        },
                                        '&:last-of-type:before': {
                                            content: 'none',
                                        },
                                    },
                                    '& td': {
                                        fontSize: 'sm',
                                        margin: '4px',
                                        position: 'relative',
                                        '&:before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: '50%',
                                            right: 0,
                                            height: '50%',
                                            width: '1px',
                                            backgroundColor: colors.separator,
                                            transform: 'translateY(-50%)',
                                        },
                                        '&:last-of-type:before': {
                                            content: 'none',
                                        },
                                    },
                                    '& tbody tr:nth-of-type(odd) td': {
                                        bgcolor: colors.rowOddBg,
                                    },
                                    '& tbody tr:nth-of-type(even) td': {
                                        bgcolor: colors.rowEvenBg,
                                    },
                                    '& tbody tr:hover td': {
                                        bgcolor: `${colors.hoverBg} !important`,
                                    },
                                    '--TableCell-height': '40px',
                                    '--Table-headerUnderlineThickness': '1px',
                                    '--TableRow-hoverBackground': colors.hoverBg,
                                    borderRadius: 'lg',
                                    maxWidth: '100%',
                                    overflow: 'auto',
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th onClick={(e) => e.stopPropagation()} style={{ width: 30 }}>
                                            <Tooltip title="Select/Unselect all loads" variant="soft">
                                                <Checkbox
                                                    checked={selectedLoads.size > 0}
                                                    indeterminate={selectedLoads.size > 0 && selectedLoads.size < filteredLoads.length}
                                                    onChange={handleSelectAll}
                                                    sx={{ p: 0 }}
                                                />
                                            </Tooltip>
                                        </th>
                                        <th style={{ width: 50, textAlign: 'center' }}>
                                            <Tooltip title="Load source" variant="soft">
                                                <span>Source</span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'age')} style={{ width: 50, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Age of load posting" variant="soft">
                                                <span>
                                                    Age <ArrowDropDownIcon style={getIconStyle('ageNewest', 'age')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'rate')} style={{ width: 100, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Trip rate" variant="soft">
                                                <span>
                                                    Rate <ArrowDropDownIcon style={getIconStyle('rateHighest', 'rate')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th style={{ width: 40, textAlign: 'center' }}>
                                            <Tooltip title="Email status" variant="soft">
                                                <span>Email</span>
                                            </Tooltip>
                                        </th>
                                        <th style={{ width: 30, cursor: 'pointer' }}>
                                            <Tooltip title="Load is eligible for factoring" variant="soft">
                                                <Box display="flex" alignItems="center" justifyContent="center">
                                                    <InfoOutlinedIcon sx={{ fontSize: 20, color: colors.text }} />{' '}
                                                </Box>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'trip')} style={{ width: 200, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Trip distance" variant="soft">
                                                <span style={{ color: 'inherit' }}>
                                                    Trip <ArrowDropDownIcon style={getIconStyle('tripShortest', 'trip')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'deadheadO')} style={{ width: 80, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Origin and destination deadhead miles" variant="soft">
                                                <span>
                                                    DH-O/D <ArrowDropDownIcon style={getIconStyle('deadheadOShortest', 'deadheadO')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'pickup')} style={{ width: 80, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Pickup dates" variant="soft">
                                                <span>
                                                    Pickup <ArrowDropDownIcon style={getIconStyle('pickUpEarliestFirst', 'pickup')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th
                                            onClick={(e) => handleHeaderClick(e, 'equipment')}
                                            className="equipment-th"
                                            style={{ width: 150, textAlign: 'center', cursor: 'pointer' }}
                                        >
                                            <Tooltip title="Required equipment type" variant="soft">
                                                <span>
                                                    Equipment <ArrowDropDownIcon style={getIconStyle('maxLengthAsc', 'equipment')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th
                                            onClick={(e) => handleHeaderClick(e, 'contact')}
                                            style={{ width: 310, textAlign: 'center', cursor: 'pointer' }}
                                            className="contact-th"
                                        >
                                            <Tooltip title="Contact information" variant="soft">
                                                <span style={{ color: 'inherit' }}>
                                                    Contact <ArrowDropDownIcon style={getIconStyle('creditScoreHighest', 'contact')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                        <th onClick={(e) => handleHeaderClick(e, 'book')} style={{ width: 60, textAlign: 'center', cursor: 'pointer' }}>
                                            <Tooltip title="Bookable status" variant="soft">
                                                <span>
                                                    Book <ArrowDropDownIcon style={getIconStyle('bookableFirst', 'book')} fontSize="medium" />
                                                </span>
                                            </Tooltip>
                                        </th>
                                    </tr>
                                </thead>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClosePopover}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <List>
                                        {activeColumn === 'age' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('ageNewest')}>
                                                        <ListItemText primary="Newest first" />
                                                        {sortConfigs[activeColumn || '']?.key === 'ageNewest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('ageOldest')}>
                                                        <ListItemText primary="Oldest first" />
                                                        {sortConfigs[activeColumn || '']?.key === 'ageOldest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'rate' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('rateHighest')}>
                                                        <ListItemText primary="Highest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'rateHighest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('rateLowest')}>
                                                        <ListItemText primary="Lowest First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'rateLowest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('ratePerMileHighest')}>
                                                        <ListItemText primary="Per Mile - Highest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'ratePerMileHighest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('ratePerMileLowest')}>
                                                        <ListItemText primary="Per Mile - Lowest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'ratePerMileLowest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'trip' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('tripShortest')}>
                                                        <ListItemText primary="Shortest distance" />
                                                        {sortConfigs[activeColumn || '']?.key === 'tripShortest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('tripLongest')}>
                                                        <ListItemText primary="Longest distance" />
                                                        {sortConfigs[activeColumn || '']?.key === 'tripLongest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('originAToZ')}>
                                                        <ListItemText primary="Origin A to Z" />
                                                        {sortConfigs[activeColumn || '']?.key === 'originAToZ' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('originZtoA')}>
                                                        <ListItemText primary="Origin Z to A" />
                                                        {sortConfigs[activeColumn || '']?.key === 'originZtoA' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('destinationAtoZ')}>
                                                        <ListItemText primary="Destination A to Z" />
                                                        {sortConfigs[activeColumn || '']?.key === 'destinationAtoZ' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('destinationZtoA')}>
                                                        <ListItemText primary="Destination Z to A" />
                                                        {sortConfigs[activeColumn || '']?.key === 'destinationZtoA' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'deadheadO' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('deadheadOShortest')}>
                                                        <ListItemText primary="Origin deadhead - Shortest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'deadheadOShortest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('deadheadOLongest')}>
                                                        <ListItemText primary="Origin deadhead - Longest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'deadheadOLongest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('deadheadDShortest')}>
                                                        <ListItemText primary="Destination deadhead - Shortest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'deadheadDShortest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('deadheadDLongest')}>
                                                        <ListItemText primary="Destination deadhead - Longest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'deadheadDLongest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'pickup' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('pickUpEarliestFirst')}>
                                                        <ListItemText primary="Earliest pickup date" />
                                                        {sortConfigs[activeColumn || '']?.key === 'pickUpEarliestFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('pickUpLatestFirst')}>
                                                        <ListItemText primary="Latest pickup date" />
                                                        {sortConfigs[activeColumn || '']?.key === 'pickUpLatestFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'equipment' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('lengthShortest')}>
                                                        <ListItemText primary="Length - Shortest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'lengthShortest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('lengthLongest')}>
                                                        <ListItemText primary="Length - Longest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'lengthLongest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('weightLightest')}>
                                                        <ListItemText primary="Weight - Lightest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'weightLightest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('weightHeaviest')}>
                                                        <ListItemText primary="Weight - Heaviest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'weightHeaviest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('capacityFullFirst')}>
                                                        <ListItemText primary="Capacity - FULL First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'capacityFullFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('capacityPartialFirst')}>
                                                        <ListItemText primary="Capacity - PARTIAL First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'capacityPartialFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'contact' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('companyNameAtoZ')}>
                                                        <ListItemText primary="Company Name - A to Z" />
                                                        {sortConfigs[activeColumn || '']?.key === 'companyNameAtoZ' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('companyNameZToA')}>
                                                        <ListItemText primary="Company Name - Z to A" />
                                                        {sortConfigs[activeColumn || '']?.key === 'companyNameZToA' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('contactEmailFirst')}>
                                                        <ListItemText primary="Contact - Email First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'contactEmailFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('contactPhoneFirst')}>
                                                        <ListItemText primary="Contact - Phone First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'contactPhoneFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('creditScoreLowest')}>
                                                        <ListItemText primary="Credit Score - Lowest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'creditScoreLowest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('creditScoreHighest')}>
                                                        <ListItemText primary="Credit Score - Highest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'creditScoreHighest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('daysToPayLowest')}>
                                                        <ListItemText primary="Days to Pay - Lowest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'daysToPayLowest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('daysToPayHighest')}>
                                                        <ListItemText primary="Days to Pay - Highest" />
                                                        {sortConfigs[activeColumn || '']?.key === 'daysToPayHighest' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                        {activeColumn === 'book' && (
                                            <>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('bookableFirst')}>
                                                        <ListItemText primary="Bookable First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'bookableFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => handleSortOptionChange('unbookableFirst')}>
                                                        <ListItemText primary="Unbookable First" />
                                                        {sortConfigs[activeColumn || '']?.key === 'unbookableFirst' && <CheckIcon />}
                                                    </ListItemButton>
                                                </ListItem>
                                            </>
                                        )}
                                    </List>
                                </Popover>

                                {/*TODO: it is bad practice to put index in key, needs to be fixed with ids*/}
                                <tbody style={{ height: '100%', maxHeight: '100%', overflow: 'auto' }}>
                                    {sortedLoads.map((load: DatabaseLoad, index) => (
                                        <React.Fragment key={index}>
                                            <Popover
                                                key={index}
                                                id={`confirmation-popover-${load.matchId}`}
                                                open={popoverOpen === load.matchId}
                                                anchorEl={confirmationAnchorEl}
                                                onClose={handleConfirmationPopoverClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <Box sx={{ p: 2 }}>
                                                    <Typography level="h4">Confirmation</Typography>
                                                    <Typography>Rate: {load.rateInfo?.rateUsd || '--'}</Typography>
                                                    <Typography>Trip: {load.tripLength} miles</Typography>
                                                    <Typography>
                                                        Origin: {load.origin.city}, {load.origin.stateProv}
                                                    </Typography>
                                                    <Typography>
                                                        Destination: {load.destination.city}, {load.destination.stateProv}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                        <Button variant="outlined" onClick={handleConfirmationPopoverClose}>
                                                            Cancel
                                                        </Button>
                                                        <a target="_blank" rel="noopener noreferrer" href={load.bookingUrl || ''}>
                                                            <Button>Book</Button>
                                                        </a>
                                                    </Box>
                                                </Box>
                                            </Popover>
                                            <tr
                                                key={load.matchId}
                                                style={{
                                                    backgroundColor: load.status === 'cancelled' ? colors.cancelled.bg : colors.background,
                                                    cursor: 'default',
                                                    pointerEvents: load.status === 'cancelled' ? 'none' : 'auto',
                                                    opacity: load.status === 'cancelled' ? 0.9 : 1,
                                                    color: load.status === 'cancelled' ? colors.cancelled.text : colors.text,
                                                }}
                                                onClick={() => (load.status === 'cancelled' ? null : handleRowClick(load))}
                                            >
                                                <td onClick={(e) => e.stopPropagation()}>
                                                    <Checkbox
                                                        checked={selectedLoads.has(load.matchId)}
                                                        onChange={(e) => handleCheckboxClick(e, load.matchId)}
                                                        sx={{ mr: 1 }}
                                                    />
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        {load.source ? (
                                                            <Typography sx={{ fontSize: '14px' }}>{load.source}</Typography>
                                                        ) : (
                                                            <img src="/dat-logo.jpg" alt="DAT" style={{ height: '20px' }} />
                                                        )}
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize: '14px',
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            {timeDisplay(load.postedAt)}
                                                        </Typography>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        {load.rateInfo?.rateUsd && load.rateInfo.rateUsd > 0 ? (
                                                            <Box sx={{ width: '100%' }}>
                                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: 'center' }}>
                                                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                        ${load.rateInfo.rateUsd.toLocaleString()}
                                                                    </Typography>
                                                                    <Typography sx={{ fontSize: '12px' }}>
                                                                        {calculateRatePerMile(load.tripLength, load.rateInfo.rateUsd)}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        ) : (
                                                            '—'
                                                        )}
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        {load.posterInfo?.contact?.email ? (
                                                            emailSentLoads.has(load.matchId) || load.isEmailSent ? (
                                                                <Tooltip title="Email is sent" variant="solid">
                                                                    <CheckCircleIcon color="success" />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="Click to send email" variant="solid">
                                                                    {emailSendingLoadId === load.matchId ? (
                                                                        <CircularProgress size="sm" />
                                                                    ) : (
                                                                        <IconButton size="sm" onClick={(e) => handleEmailClick(e, load)}>
                                                                            <EmailIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </Tooltip>
                                                            )
                                                        ) : (
                                                            '—'
                                                        )}
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        {load.isFactorable && <CheckIcon sx={{ color: tableColors[mode as 'light' | 'dark'].svgColor }} />}
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: colors.tripColor }}>
                                                            {load.tripLength}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                gap: 0.2,
                                                            }}
                                                        >
                                                            <Box className="vertical-icon">
                                                                <DatDirectionIcons svgColor={colors.svgColor} />
                                                            </Box>

                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                }}
                                                            >
                                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                    {load.origin.city}, {load.origin.stateProv}
                                                                </Typography>
                                                                <Box className="horizontal-icon" sx={{ display: 'none' }}>
                                                                    <HorizontalDirectionIcon svgColor={colors.svgColor} />
                                                                </Box>
                                                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                    {load.destination.city}, {load.destination.stateProv}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: 1,
                                                            }}
                                                        >
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                ({load.originDeadheadMiles?.miles})
                                                            </Typography>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                ({load.destinationDeadheadMiles?.miles})
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </td>
                                                <td>{convertPickupDates(load.earliestAvailability, load.latestAvailability)}</td>
                                                <td className="equipment-td">
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                flexDirection: 'column',
                                                                gap: 1,
                                                                width: '2rem',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {load.equipmentType!}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}
                                                        >
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                {Number(load.shipmentDetails?.maximumWeightPounds).toLocaleString() || '-'} lbs
                                                            </Typography>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                {load.shipmentDetails?.maximumLengthFeet || '-'} ft - {load.shipmentDetails?.fullPartial}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </td>
                                                <td className="contact-td">
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3 }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: 0.5,
                                                                width: '70%',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {load.posterInfo?.companyName}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {load.posterInfo?.contact?.phoneNumber ? (
                                                                    <a
                                                                        href={`tel:${load.posterInfo?.contact?.phoneNumber}`}
                                                                        style={{
                                                                            textDecoration: 'underline',
                                                                            color: load.status === 'cancelled' ? '#FFFFFF' : colors.linkColor,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        {formatPhoneNumber(load.posterInfo?.contact?.phoneNumber)}
                                                                    </a>
                                                                ) : (
                                                                    <a
                                                                        href={`mailto:${load.posterInfo?.contact?.email}`}
                                                                        style={{
                                                                            textDecoration: 'underline',
                                                                            color: load.status === 'cancelled' ? '#FFFFFF' : colors.linkColor,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        {load.posterInfo?.contact?.email}
                                                                    </a>
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                {load.posterInfo?.credit?.creditScore || '-'} CS
                                                            </Typography>
                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                                {load.posterInfo?.credit?.daysToPay || '-'} DTP
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        {load.bookingUrl ? (
                                                            <Button variant="outlined" onClick={(e) => handleConfirmationPopoverOpen(e, load.matchId)}>
                                                                Book
                                                            </Button>
                                                        ) : (
                                                            '—'
                                                        )}
                                                    </Box>
                                                </td>
                                            </tr>
                                            {expandedLoads.has(load.matchId) && (
                                                <tr>
                                                    <td colSpan={colspan}>
                                                        <Box
                                                            sx={{
                                                                p: 2,
                                                                backgroundColor: colors.accordion.bg,
                                                                borderBottom: '1px solid',
                                                                borderColor: colors.accordion.border,
                                                                animation: 'expandDown 0.3s ease-out forwards',
                                                                '@keyframes expandDown': {
                                                                    from: {
                                                                        opacity: 0,
                                                                        transform: 'scaleY(0)',
                                                                        transformOrigin: 'top',
                                                                    },
                                                                    to: {
                                                                        opacity: 1,
                                                                        transform: 'scaleY(1)',
                                                                        transformOrigin: 'top',
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {/* Header showing origin and destination */}
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: 1,
                                                                    mb: 2,
                                                                    pb: 2,
                                                                    borderBottom: '1px solid',
                                                                    borderColor: colors.accordion.border,
                                                                }}
                                                            >
                                                                <Typography
                                                                    level="title-lg"
                                                                    sx={{
                                                                        fontSize: '1.8rem',
                                                                        fontWeight: 'bold',
                                                                        display: 'flex',
                                                                        gap: 2,
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    {load.origin.city}
                                                                    <HorizontalDirectionIcon svgColor={colors.svgColor} />
                                                                    {load.destination.city}
                                                                </Typography>
                                                                <Typography
                                                                    level="body-sm"
                                                                    sx={{
                                                                        fontSize: '1.3rem',
                                                                        fontWeight: 'bold',
                                                                    }}
                                                                >{`${load.tripLength} ml`}</Typography>
                                                            </Box>

                                                            {/* Accordion Content */}
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    gap: 3,
                                                                    flexWrap: 'wrap',
                                                                    '@media (max-width: 1250px)': {
                                                                        flexDirection: 'column',
                                                                        '& > *': {
                                                                            flex: '1 1 90% !important',
                                                                            maxWidth: '90% !important',
                                                                            justifyContent: 'center',
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                {/* Trip Info and Shipment Details Combined */}
                                                                <Box
                                                                    sx={{
                                                                        flex: '1 1 25%',
                                                                    }}
                                                                >
                                                                    {/* Trip Info */}
                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                                        {/* Header */}
                                                                        <Box
                                                                            sx={{
                                                                                backgroundColor: '#f5f5f5',
                                                                                px: 1,
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{ fontWeight: 'bold', fontSize: '0.9rem', color: colors.accordion.title }}
                                                                            >
                                                                                Trip
                                                                            </Typography>
                                                                        </Box>

                                                                        {/* Content */}
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                                                            {/* Icon Section */}
                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                <VerticalDirectionIcon />
                                                                            </Box>

                                                                            {/* Origin and Destination Details */}
                                                                            <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    justifyContent: 'space-between',
                                                                                    gap: 1,
                                                                                }}
                                                                            >
                                                                                {/* Origin */}
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                        alignItems: 'center',
                                                                                        gap: 1,
                                                                                    }}
                                                                                >
                                                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                                        <Typography
                                                                                            level="body-sm"
                                                                                            sx={{
                                                                                                fontWeight: 'bold',
                                                                                                fontSize: '1.1rem',
                                                                                                color: colors.text,
                                                                                            }}
                                                                                        >
                                                                                            {`${load.origin.city}, ${load.origin.stateProv} (${load.originDeadheadMiles.miles})`}
                                                                                        </Typography>
                                                                                        <Typography>
                                                                                            {new Date(load.earliestAvailability)
                                                                                                .toDateString()
                                                                                                .split(' ')
                                                                                                .slice(1, 3)
                                                                                                .join(' ') ===
                                                                                            new Date(load.latestAvailability)
                                                                                                .toDateString()
                                                                                                .split(' ')
                                                                                                .slice(1, 3)
                                                                                                .join(' ')
                                                                                                ? new Date(load.earliestAvailability)
                                                                                                      .toDateString()
                                                                                                      .split(' ')
                                                                                                      .slice(1, 3)
                                                                                                      .join(' ')
                                                                                                : `${new Date(load.earliestAvailability).toDateString().split(' ').slice(1, 3).join(' ')} - ${new Date(load.latestAvailability).toDateString().split(' ').slice(1, 3).join(' ')}`}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>

                                                                                {/* Destination */}
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                        alignItems: 'center',
                                                                                        gap: 1,
                                                                                    }}
                                                                                >
                                                                                    <Typography
                                                                                        level="body-sm"
                                                                                        sx={{
                                                                                            fontWeight: 'bold',
                                                                                            fontSize: '1.1rem',
                                                                                            color: colors.text,
                                                                                        }}
                                                                                    >
                                                                                        {`${load.destination.city}, ${load.destination.stateProv} (${load.destinationDeadheadMiles.miles})`}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>

                                                                    {/* Shipment Details */}
                                                                    <Box sx={{ width: '10rem' }}>
                                                                        {/* Section Title */}
                                                                        <Box
                                                                            sx={{
                                                                                mt: 2,
                                                                                mb: 1,
                                                                                fontWeight: 'bold',
                                                                                fontSize: '0.9rem',
                                                                                color: colors.accordion.sectionTitle,
                                                                            }}
                                                                        >
                                                                            Equipment
                                                                        </Box>
                                                                        {/* Equipment Details */}
                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                                                            {/* Truck */}
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{
                                                                                        fontSize: '0.8rem',
                                                                                        fontWeight: 'bold',
                                                                                        color: colors.accordion.label,
                                                                                    }}
                                                                                >
                                                                                    Truck
                                                                                </Typography>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{ fontSize: '0.8rem', color: colors.accordion.text }}
                                                                                >
                                                                                    {load.equipmentType || '—'}
                                                                                </Typography>
                                                                            </Box>
                                                                            {/* Load */}
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{
                                                                                        fontSize: '0.8rem',
                                                                                        fontWeight: 'bold',
                                                                                        color: colors.accordion.label,
                                                                                    }}
                                                                                >
                                                                                    Load
                                                                                </Typography>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{ fontSize: '0.8rem', color: colors.accordion.text }}
                                                                                >
                                                                                    {load.shipmentDetails?.fullPartial || '—'}
                                                                                </Typography>
                                                                            </Box>
                                                                            {/* Length */}
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{
                                                                                        fontSize: '0.8rem',
                                                                                        fontWeight: 'bold',
                                                                                        color: colors.accordion.label,
                                                                                    }}
                                                                                >
                                                                                    Length
                                                                                </Typography>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{ fontSize: '0.8rem', color: colors.accordion.text }}
                                                                                >
                                                                                    {load.shipmentDetails?.maximumLengthFeet
                                                                                        ? `${load.shipmentDetails?.maximumLengthFeet} ft`
                                                                                        : '—'}
                                                                                </Typography>
                                                                            </Box>
                                                                            {/* Weight */}
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{
                                                                                        fontSize: '0.8rem',
                                                                                        fontWeight: 'bold',
                                                                                        color: colors.accordion.label,
                                                                                    }}
                                                                                >
                                                                                    Weight
                                                                                </Typography>
                                                                                <Typography
                                                                                    level="body-sm"
                                                                                    sx={{ fontSize: '0.8rem', color: colors.accordion.text }}
                                                                                >
                                                                                    {load.shipmentDetails?.maximumWeightPounds
                                                                                        ? `${load.shipmentDetails?.maximumWeightPounds.toLocaleString()} lbs`
                                                                                        : '—'}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>

                                                                    {/* Contact Information */}
                                                                    <Box>
                                                                        <Box
                                                                            sx={{
                                                                                mt: 2,
                                                                                fontWeight: 'bold',
                                                                                fontSize: '0.9rem',
                                                                                color: colors.accordion.sectionTitle,
                                                                            }}
                                                                        >
                                                                            Contact Information
                                                                        </Box>
                                                                        <Box sx={{ mt: 0.5 }}>
                                                                            <Typography
                                                                                level="body-sm"
                                                                                sx={{
                                                                                    fontSize: '0.8rem',
                                                                                    color: colors.linkColor,
                                                                                    fontWeight: 'bold',
                                                                                    cursor: 'pointer',
                                                                                    textDecoration: 'underline',
                                                                                }}
                                                                                onClick={() => {
                                                                                    const contact = load.posterInfo.contact
                                                                                    if (contact.email) {
                                                                                        window.location.href = `mailto:${contact.email}`
                                                                                    } else if (contact.phoneNumber) {
                                                                                        window.location.href = `tel:${contact.phoneNumber}`
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {load.posterInfo.contact.email || load.posterInfo.contact.phoneNumber || 'N/A'}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>

                                                                    {load.comments && (
                                                                        <Box
                                                                            sx={{
                                                                                width: '100%',
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                mt: 2,
                                                                                fontWeight: 'bold',
                                                                                fontSize: '0.9rem',
                                                                                color: colors.accordion.sectionTitle,
                                                                            }}
                                                                        >
                                                                            <Typography>Comments:</Typography>
                                                                            <Typography>{load.comments}</Typography>
                                                                        </Box>
                                                                    )}
                                                                </Box>

                                                                {/* Rate Information or Email Form */}
                                                                {(load.rateInfo?.rateUsd || load.posterInfo.contact?.email) && (
                                                                    <Box sx={{ flex: '1 1 33%' }}>
                                                                        {load.rateInfo?.rateUsd && (
                                                                            <>
                                                                                <Box
                                                                                    sx={{
                                                                                        backgroundColor: '#f5f5f5',
                                                                                        px: 1,
                                                                                    }}
                                                                                >
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontWeight: 'bold',
                                                                                            fontSize: '0.9rem',
                                                                                            mb: 2,
                                                                                            color: colors.accordion.title,
                                                                                        }}
                                                                                    >
                                                                                        Rate
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column',
                                                                                        gap: 1,
                                                                                        width: '10rem',
                                                                                        mb: 2,
                                                                                    }}
                                                                                >
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <Typography
                                                                                            level="body-sm"
                                                                                            sx={{ color: colors.accordion.label, fontSize: '0.8rem' }}
                                                                                        >
                                                                                            Total Rate
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            level="body-sm"
                                                                                            sx={{ color: colors.accordion.text, fontSize: '0.8rem' }}
                                                                                        >
                                                                                            ${load.rateInfo.rateUsd.toLocaleString()}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <Typography
                                                                                            level="body-sm"
                                                                                            sx={{ color: colors.accordion.label, fontSize: '0.8rem' }}
                                                                                        >
                                                                                            Rate/Mile
                                                                                        </Typography>
                                                                                        <Typography
                                                                                            level="body-sm"
                                                                                            sx={{ color: colors.accordion.text, fontSize: '0.8rem' }}
                                                                                        >
                                                                                            {calculateRatePerMile(load.tripLength, load.rateInfo.rateUsd)}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            </>
                                                                        )}
                                                                        {load.posterInfo.contact?.email && (
                                                                            <Box sx={{ mt: load.rateInfo?.rateUsd ? 2 : 0, width: '100%' }}>
                                                                                <EmailModal
                                                                                    open={true}
                                                                                    onClose={() => {}}
                                                                                    load={load}
                                                                                    embedded={true}
                                                                                    onEmailSent={() => {}}
                                                                                    onLoadUpdate={updateEmailStatus}
                                                                                />
                                                                            </Box>
                                                                        )}
                                                                    </Box>
                                                                )}

                                                                {/* Company Information */}
                                                                <Box
                                                                    sx={{
                                                                        flex: '1 1 25%',
                                                                    }}
                                                                >
                                                                    {/* Header */}
                                                                    <Box
                                                                        sx={{
                                                                            backgroundColor: tableColors[mode as 'light' | 'dark'].accordion.titleBg,
                                                                            mb: 2,
                                                                            px: 1,
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{ fontWeight: 'bold', fontSize: '0.9rem', color: colors.accordion.title }}
                                                                        >
                                                                            Company
                                                                        </Typography>
                                                                    </Box>

                                                                    <Typography
                                                                        sx={{
                                                                            fontWeight: 'bold',
                                                                            fontSize: '1rem',
                                                                            mb: 2,
                                                                            color: colors.text,
                                                                        }}
                                                                    >
                                                                        {load.posterInfo?.companyName || 'Company'}
                                                                    </Typography>

                                                                    <Box>
                                                                        <Typography
                                                                            level="body-sm"
                                                                            sx={{
                                                                                fontSize: '0.8rem',
                                                                                color: colors.linkColor,
                                                                                fontWeight: 'bold',
                                                                                cursor: 'pointer',
                                                                                textDecoration: 'underline',
                                                                                mb: 1,
                                                                            }}
                                                                            onClick={() => {
                                                                                const contact = load.posterInfo.contact
                                                                                if (contact.email) {
                                                                                    window.location.href = `mailto:${contact.email}`
                                                                                } else if (contact.phoneNumber) {
                                                                                    window.location.href = `tel:${contact.phoneNumber}`
                                                                                }
                                                                            }}
                                                                        >
                                                                            {load.posterInfo.contact?.email || load.posterInfo.contact?.phoneNumber || 'N/A'}
                                                                        </Typography>

                                                                        {/* MC Number and Credit Score */}
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                mb: 1,
                                                                                width: '20rem',
                                                                            }}
                                                                        >
                                                                            <Typography level="body-sm">MC#{load.posterInfo?.mcNumber || '—'}</Typography>
                                                                            <Typography level="body-sm">
                                                                                Credit Score: {load.posterInfo?.credit?.creditScore || '—'}
                                                                            </Typography>
                                                                        </Box>

                                                                        {/* City and Days to Pay */}
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                mb: 1,
                                                                                width: '20rem',
                                                                            }}
                                                                        >
                                                                            <Typography level="body-sm">
                                                                                {`${load.posterInfo?.city || '—'}, ${load.posterInfo?.carrierHomeState || '—'}`}
                                                                            </Typography>
                                                                            <Typography level="body-sm">
                                                                                Days to Pay: {load.posterInfo?.credit?.daysToPay || '—'}
                                                                            </Typography>
                                                                        </Box>

                                                                        {/* Poster ID */}
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                mb: 1,
                                                                                width: '20rem',
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                level="body-sm"
                                                                                sx={{
                                                                                    fontWeight: 'bold',
                                                                                    color: colors.accordion.label,
                                                                                    fontSize: '0.8rem',
                                                                                }}
                                                                            >
                                                                                Load ID
                                                                            </Typography>
                                                                            <Typography
                                                                                level="body-sm"
                                                                                sx={{ color: colors.accordion.text, fontSize: '0.8rem' }}
                                                                            >
                                                                                {load.postersReferenceId || 'N/A'}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </Sheet>
                        <Snackbar
                            variant="soft"
                            color={snackbar.color}
                            open={snackbar.open}
                            onClose={handleSnackbarClose}
                            autoHideDuration={3000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            sx={{
                                maxWidth: '400px',
                                width: '100%',
                                '& .MuiSnackbar-content': {
                                    fontSize: '1rem',
                                    padding: '1rem',
                                    justifyContent: 'center',
                                },
                            }}
                        >
                            {snackbar.message}
                        </Snackbar>
                    </Box>
                </Box>
            ) : (
                <Box
                    p={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        mt: 2,
                        maxWidth: '100%',
                        height: 'calc(100vh - 17.5rem)',
                        overflow: 'auto',
                        borderRadius: '8px',
                        border: '1px solid',
                        borderColor: colors.headerBg,
                    }}
                >
                    No loads found
                </Box>
            )}
        </Box>
    )
}
