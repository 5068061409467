import { Navigate, Route, Routes } from 'react-router-dom'
import { DispatcherDashboardPage, DispatcherEmailsPage } from './pages'
import SpotFinderComponent from './pages/spot-finder'
import CallCenterPage from './pages/call-center'

export default function DispatcherRoutes() {
    return (
        <Routes>
            <Route index element={<DispatcherDashboardPage />} />
            <Route path="emails" element={<DispatcherEmailsPage />} />
            <Route path="spot-finder" element={<SpotFinderComponent />} />
            <Route path="call-center" element={<CallCenterPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}
