import { ActiveCall, BaseCall, CallState } from '@numeo/types'
import { useContext } from 'react'
import { WebSocketContext } from '../context/WebSocketContext'

export interface CallCenterHook {
    calls: ActiveCall[]
    selectedCallId: string | null
    filters: CallState['filters']
    stats: {
        total: number
        byStatus: Record<string, number>
        byType: Record<string, number>
        byPriority: Record<string, number>
    }
    wsStatus: 'connected' | 'disconnected'
    recentlyUpdated: Set<string>
    updateCall: (callId: string, updates: Partial<BaseCall>) => void
    removeCall: (callId: string) => void
    setSelectedCall: (callId: string | null) => void
    updateFilters: (filters: Partial<CallState['filters']>) => void
    handleCorrectTranscription: (segmentId: string, text: string) => void
    handleFlagTranscription: (segmentId: string, reason: string) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    actions: any
}

export const useCallCenter = (): CallCenterHook => {
    const context = useContext(WebSocketContext)
    if (!context) {
        throw new Error('useCallCenter must be used within a WebSocketProvider')
    }

    const {
        callCenter: { calls, selectedCallId, filters, actions, stats, wsStatus, recentlyUpdated },
    } = context

    return {
        calls,
        selectedCallId,
        filters,
        stats,
        wsStatus,
        recentlyUpdated,
        ...actions,
    }
}
