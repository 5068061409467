export const filterProperties = ['from', 'to', 'cc', 'bss', 'subject', 'mime-type', 'body', 'replay to', 'labels']
export const filterConditions = [
    'contains',
    'equals',
    'start with',
    'end width',
    'does not contain',
    'does not equal',
    'does not start with',
    'does not end with',
    'is empty',
    'is not empty',
]
