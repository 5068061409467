/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Chip, Divider, Typography, Accordion, AccordionDetails, AccordionSummary, IconButton, Input, Tooltip, CircularProgress } from '@mui/joy'
import { LocalShipping, AttachMoney, LocationOn, ExpandMore, NorthEast, Route, Edit, Save } from '@mui/icons-material'
import { useState, useRef } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { Email, EmailProcessingResult, OrderSchema } from '@numeo/types'
import OrderRouteMap from './OrderRouteMap'
import axios from 'axios'

dayjs.extend(duration)

interface EmailDetailProps {
    execution: { email: Email; processingResult: EmailProcessingResult }
    refetch: () => Promise<void>
}

export default function EmailDetail({ execution, refetch }: EmailDetailProps) {
    const [expandedOrder, setExpandedOrder] = useState<string>((execution?.processingResult?.orders?.[0] as any)?._id || '')
    const [editingPriceId, setEditingPriceId] = useState<string | null>(null)
    const [pricesPerMile, setPricesPerMile] = useState<Record<string, any>>({})
    const [editingPrice, setEditingPrice] = useState<boolean>(false)
    const priceInputRef = useRef<HTMLInputElement>(null)

    const handleAccordionChange = (orderId: string) => {
        setExpandedOrder(expandedOrder === orderId ? '' : orderId)
    }

    const handlePriceEdit = (orderId: string) => {
        setEditingPriceId(orderId)
        setTimeout(() => {
            if (priceInputRef.current) {
                priceInputRef.current.focus()
            }
        }, 0)
    }

    const handlePriceSave = async () => {
        setEditingPrice(true)
        await axios.put(`/n8n/processing-results/${(execution.processingResult as any)._id}`, {
            ordersRate: Object.entries(pricesPerMile).map(([orderId, ratePerMile]) => ({
                routeId: execution.processingResult?.orders?.find((order: any) => order._id === orderId)?.routeId,
                ratePerMile: +ratePerMile,
            })),
        })
        await refetch()
        setEditingPrice(false)
        setEditingPriceId(null)
    }

    const handlePriceChange = (orderId: string, value: string) => {
        console.log(value, 'value')

        setPricesPerMile((prev) => ({
            ...prev,
            [orderId]: value,
        }))
    }

    const handlePriceBlur = () => {
        handlePriceSave()
        setEditingPriceId(null)
    }

    const getPricePerMile = (order: any) => {
        if (pricesPerMile[order._id] !== undefined) {
            return pricesPerMile[order._id]
        }

        return +(order.revenue.chargeAmount / order.length).toFixed(2)
    }

    if (!execution?.processingResult?.orders?.length) return null

    return (
        <Card variant="outlined" sx={{ p: 3, height: '100%', minWidth: 350 }}>
            <Typography level="title-lg" startDecorator={<LocalShipping />} sx={{ mb: 2 }}>
                Orders ({execution.processingResult?.orders?.length})
            </Typography>

            <Box>
                {execution.processingResult?.orders?.map((order: OrderSchema) => (
                    <Accordion
                        key={(order as any)._id}
                        expanded={expandedOrder === (order as any)._id}
                        onChange={() => handleAccordionChange((order as any)._id)}
                    >
                        <AccordionSummary
                            indicator={<ExpandMore />}
                            sx={{
                                p: 1.5,
                                '& .MuiAccordionSummary-expandIconWrapper': {
                                    transform: 'rotate(0deg)',
                                    transition: '0.2s',
                                    '&.Mui-expanded': {
                                        transform: 'rotate(180deg)',
                                    },
                                },
                                '&:hover': {
                                    bgcolor: 'background.level1',
                                },
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, width: '100%', overflow: 'hidden' }}>
                                <LocationOn color="primary" sx={{ flexShrink: 0 }} />
                                <Tooltip title={order.origin.city} variant="soft">
                                    <Typography
                                        level="title-sm"
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            minWidth: 0,
                                            flex: 1,
                                        }}
                                    >
                                        {order.origin.city}, {order.origin.state}
                                    </Typography>
                                </Tooltip>
                                <NorthEast sx={{ transform: 'rotate(45deg)', color: 'neutral.500', flexShrink: 0 }} />
                                <Tooltip title={order.destination.city} variant="soft">
                                    <Typography
                                        level="title-sm"
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            minWidth: 0,
                                            flex: 1,
                                        }}
                                    >
                                        {order.destination.city}, {order.destination.state}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {expandedOrder === (order as any)._id && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography level="title-sm" startDecorator={<AttachMoney />} sx={{ mb: 2 }}>
                                        Financial Details
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1, mb: 2, flexWrap: 'wrap' }}>
                                        {/* <Chip size="md" variant="soft" color="neutral" startDecorator={<Timeline />}>
                                            {order.} miles
                                        </Chip>
                                        <Chip size="md" variant="soft" color="neutral" startDecorator={<Timeline />}>
                                            {dayjs.duration(order.duration, 'hours').humanize()}
                                        </Chip> */}
                                        {order.length && (
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                <Chip size="md" variant="soft" color="primary" startDecorator={<AttachMoney />}>
                                                    {editingPriceId === (order as any)._id ? (
                                                        <Input
                                                            size="sm"
                                                            type="number"
                                                            ref={priceInputRef}
                                                            value={getPricePerMile(order)}
                                                            onChange={(e) => handlePriceChange((order as any)._id, e.target.value)}
                                                            onBlur={handlePriceBlur}
                                                            sx={{
                                                                width: '60px',
                                                                '--Input-paddingInline': '0.4rem',
                                                            }}
                                                        />
                                                    ) : (
                                                        `$${getPricePerMile(order)}/mile`
                                                    )}
                                                </Chip>
                                                {editingPrice ? (
                                                    <CircularProgress size="sm" />
                                                ) : editingPriceId === (order as any)._id ? (
                                                    <IconButton size="sm" variant="plain" color="neutral" onClick={() => handlePriceSave()}>
                                                        <Save />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton size="sm" variant="plain" color="neutral" onClick={() => handlePriceEdit((order as any)._id)}>
                                                        <Edit />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography level="body-sm">Driver Cost:</Typography>
                                            <Typography level="body-sm">${order.costs.driver?.toFixed(2)}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography level="body-sm">Fuel Cost:</Typography>
                                            <Typography level="body-sm">${order.costs.fuel?.toFixed(2)}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography level="body-sm">Toll Cost:</Typography>
                                            <Typography level="body-sm">${order.costs.toll.total?.toFixed(2)}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography level="title-sm">Total Cost:</Typography>
                                            <Typography level="title-sm">${order.costs.total?.toFixed(2)}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography level="title-sm">Revenue:</Typography>
                                            <Typography level="title-sm" color="success">
                                                ${order.revenue.chargeAmount?.toFixed(2)}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography level="title-sm">Profit Margin:</Typography>
                                            <Typography level="title-sm" color="success">
                                                ${order.revenue.profitMargin?.toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Divider sx={{ my: 2 }} />

                                    <Typography level="title-sm" startDecorator={<Route />} sx={{ mb: 2 }}>
                                        Route Details
                                    </Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <OrderRouteMap polyline={order.polyline} />
                                    </Box>
                                </Box>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Card>
    )
}
