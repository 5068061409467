import { Box, Tooltip, Typography } from '@mui/joy'

export const TrunscriptionTooltip = ({ children, maxHeight = 300, transcripts }) => {
    return (
        <Tooltip
            sx={{
                padding: 0.4,
                backgroundColor: 'gray.400',
                '& .MuiTooltip-arrow': {
                    color: 'var(--variant-solidBg, #0b6bcb);',
                },
            }}
            title={
                <Box
                    sx={{
                        maxHeight: `${maxHeight}px`,
                        overflowY: 'auto',
                        width: '500px',
                        backgroundColor: 'background.level1',
                        borderRadius: '6px',
                        // backgroundColor: '#2a2a2a',
                        // borderRadius: '6px',
                        '&::-webkit-scrollbar': {
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: '#2a2a2a',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#444',
                            borderRadius: '4px',
                            '&:hover': {
                                background: '#555',
                            },
                        },
                    }}
                >
                    {transcripts.map((transcript, index) => (
                        <Box
                            key={index}
                            sx={{
                                mb: 1,
                                p: 1,
                                borderRadius: 'md',
                                display: 'flex',
                                justifyContent: transcript.role === 'assistant' ? 'right' : 'left',
                            }}
                        >
                            <Box sx={{ bgcolor: 'background.surface', px: 2, py: 1, borderRadius: 'md', width: '80%', boxShadow: 'sm' }}>
                                <Typography level="body-sm">{Array.isArray(transcript.content) ? transcript.content.join(' ') : transcript.content}</Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            }
            placement="right-start"
            arrow
        >
            <Box sx={{ cursor: 'pointer' }}>{children}</Box>
        </Tooltip>
    )
}
