import { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import { chipClasses } from '@mui/joy/Chip'
import Tabs from '@mui/joy/Tabs'
import { Stack, TabPanel, TabList, tabClasses, Tab, FormLabel, Typography, Divider } from '@mui/joy'
import { getEmailSettings, getFooterSettings, saveEmailSettings, saveFooterSettings } from './api/EmailSettings'
import { useAuth } from 'hooks/auth'
import axios from 'axios'
import { ApplicationSettingsStatesListType } from '@numeo/types'
import { STATES_LIST } from './const'
import AccountDetailsTab from './components/Tabs/AccountDetailsTab'
import EmailSettingsTab from './components/Tabs/EmailSettingsTab'
import PickupsDestinationsUI from './components/Tabs/PickupsDestinations'
import { useSearchParams } from 'react-router-dom'
import { RichTextEditor } from 'components/common'

export default function SettingsPage() {
    const { application } = useAuth()

    const [fuelCost, setFuelCost] = useState<number | null>(null)
    const [driverCost, setDriverCost] = useState<number | null>(null)
    const [pricesList, setPricesList] = useState<null | { value: string; label: string }[]>(null)
    const [excludedStates, setExcludedStates] = useState<string[]>(application?.temp?.emailSettings?.states?.excluded || [])
    const [includedStates, setIncludedStates] = useState<string[]>(application?.temp?.emailSettings?.states?.included || [])
    const [isMountainAreaIncluded, setIsMountainAreaIncluded] = useState(application?.temp?.emailSettings?.mountainArea || false)
    const [loadingSetting, setLoadingSetting] = useState(false)
    const [handleEmailTrigger, setHandleEmailTrigger] = useState(false)
    const [isExclusionList, setIsExclusionList] = useState(application?.temp?.emailSettings?.states?.activeList !== 'included')
    const [emailClosingData, setEmailClosingData] = useState<string>('')
    const [initialEmailClosingData, setInitialEmailClosingData] = useState<string>('')
    const [ratePerMile, setRatePerMile] = useState<number>(application?.temp?.emailSettings?.ratePerMile || 3)
    const [searchParams] = useSearchParams()

    const statesList = useMemo(
        () =>
            STATES_LIST.map((state) => ({
                value: state.value,
                label:
                    state.label +
                    ((isExclusionList ? includedStates : excludedStates).includes(state.value) ? ` (${isExclusionList ? 'included' : 'excluded'})` : ''),
                disabled: (isExclusionList ? includedStates : excludedStates).includes(state.value),
            })),
        [isExclusionList, excludedStates, includedStates]
    )

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsMountainAreaIncluded(event.target.checked)
    }

    // Handle change in the input field
    const handleFuelCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value)
        if (!isNaN(value)) {
            setFuelCost(value)
        } else if (event.target.value === '') {
            setFuelCost(null)
        }
    }
    const handleDriverCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value)
        if (!isNaN(value)) {
            setDriverCost(value)
        } else if (event.target.value === '') {
            setDriverCost(null)
        }
    }

    const handleRatePerMileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value)
        if (!isNaN(value)) {
            setRatePerMile(value)
        } else if (event.target.value === '') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setRatePerMile('' as any)
        }
    }

    useEffect(() => {
        const driverCost = Number(application?.temp?.emailSettings?.driverCost) || 0.6
        setDriverCost(driverCost)
    }, [application?.temp?.emailSettings?.driverCost])

    const dispatchEmailSettings = async () => {
        const emailSettings = {
            states: {
                included: includedStates,
                excluded: excludedStates,
                activeList: isExclusionList ? ApplicationSettingsStatesListType.EXCLUDED : ApplicationSettingsStatesListType.INCLUDED,
            },
            mountainArea: isMountainAreaIncluded,
            pricePerGallon: fuelCost,
            ratePerMile,
            driverCost,
        }
        try {
            setLoadingSetting(true)
            const response = await saveEmailSettings(emailSettings)
            if (response) {
                console.log(response, 'response')
            }
        } catch (error) {
            console.error('Failed to disconnect Telegram:', error)
        } finally {
            setLoadingSetting(false)
        }
    }

    // arrow function to save footer settings
    const upsertEmailFooterData = async () => {
        try {
            setLoadingSetting(true)
            const response = await saveFooterSettings({ closing: emailClosingData })

            if (response?.status === 200) {
                setInitialEmailClosingData(emailClosingData)
            } else {
                console.error('Failed to save footer settings')
            }
        } catch (error) {
            console.error('Error saving footer settings:', error)
        } finally {
            setLoadingSetting(false)
        }
    }

    const fetchEIAGasPrice = useCallback(async () => {
        const url = 'https://api.eia.gov/v2/petroleum/pri/gnd/data/'
        const params = {
            frequency: 'weekly',
            'data[0]': 'value',
            'sort[0][column]': 'period',
            'sort[0][direction]': 'desc',
            facets: {
                product: ['EPD2D', 'EPD2DM10', 'EPD2DXL0'],
            },
            offset: 0,
            length: 10,
            api_key: 'ezd4pHdaR9ymoqGDtWirUdxyHmH0mYkw5cExDneP',
        }
        try {
            const response = await axios.get(url, { params })
            const updatedData = response.data.response.data.map((item) => {
                return {
                    value: item.value,
                    label: item['area-name'] + ' ' + item['series-description'].split(' No 2')[0],
                }
            })
            setFuelCost(Number(application?.temp?.emailSettings?.pricePerGallon) || updatedData[0].value)
            setPricesList(updatedData)
        } catch (err) {
            console.log(err, 'error fetching EIA Gas Price')
        }
    }, [application?.temp?.emailSettings?.pricePerGallon])

    useEffect(() => {
        fetchEIAGasPrice()
    }, [fetchEIAGasPrice])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getEmailSettings()
                const footerResponse = await getFooterSettings()

                if (data.status === 200) {
                    const { state, mountainArea, price } = data.data
                    setIncludedStates(state.included)
                    setExcludedStates(state.excluded)
                    setIsMountainAreaIncluded(mountainArea)
                    setFuelCost(price)
                }

                if (footerResponse?.status === 200 && footerResponse?.data) {
                    const { closing } = footerResponse.data
                    const footerContent = closing || ''
                    setEmailClosingData(footerContent)
                    setInitialEmailClosingData(footerContent)
                }
            } catch (error) {
                console.error('Failed to fetch settings:', error)
            }
        }

        fetchData()
    }, [])

    const handleEmailClosingChange = (value: string) => {
        setEmailClosingData(value)
    }

    const handleCancel = () => {
        setEmailClosingData(initialEmailClosingData)
    }

    return (
        <Stack spacing={4}>
            <Box sx={{ flex: 1, width: '100%', mx: 'auto' }}>
                <Tabs defaultValue={searchParams.get('tab') === 'email-settings' ? 1 : 0} sx={{ bgcolor: 'transparent' }}>
                    <Box
                        sx={{
                            '--_shadow-height': '16px',
                            height: 0,
                            position: 'sticky',
                            top: 'calc(48px - var(--main-paddingTop, 0px) + var(--Header-height, 0px) - (var(--_shadow-height) / 2))',
                            zIndex: 1,
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'relative',
                                zIndex: 1,
                                height: 'var(--_shadow-height)',
                                background: 'radial-gradient(closest-side, rgba(0 0 0 / 0.12), transparent 100%)',
                            },
                        }}
                    />
                    <TabList
                        sticky="top"
                        variant="plain"
                        sx={(theme) => ({
                            '--Chip-minHeight': '20px',
                            '--ListItem-minHeight': '48px',
                            top: 'calc(-1 * (var(--main-paddingTop, 0px) - var(--Header-height, 0px)))',
                            zIndex: 10,
                            width: '100%',
                            overflow: 'auto hidden',
                            alignSelf: 'flex-start',
                            scrollSnapType: 'inline',
                            '&::after': {
                                pointerEvents: 'none',
                                display: { xs: 'block', sm: 'none' },
                                content: '""',
                                position: 'sticky',
                                top: 0,
                                width: 40,
                                flex: 'none',
                                zIndex: 1,
                                right: 0,
                                borderBottom: '1px solid transparent',
                                background: `linear-gradient(to left, ${theme.vars.palette.background.body}, rgb(0 0 0 / 0))`,
                                backgroundClip: 'content-box',
                            },
                            '&::-webkit-scrollbar': {
                                width: 0,
                                display: 'none',
                            },
                            [`& .${tabClasses.root}`]: {
                                '--focus-outline-offset': '-2px',
                                '&:first-of-type': {
                                    ml: 'calc(-1 * var(--ListItem-paddingX))',
                                },
                                scrollSnapAlign: 'start',
                                bgcolor: 'transparent',
                                flex: 'none',
                                '&:hover': {
                                    bgcolor: 'transparent',
                                },
                                [`&.${tabClasses.selected}`]: {
                                    color: 'primary.plainColor',
                                    bgcolor: 'transparent',
                                    [`& .${chipClasses.root}`]: theme.variants.solid.primary,
                                },
                            },
                        })}
                    >
                        <Tab indicatorInset value={0}>
                            Account Details
                        </Tab>
                        <Tab indicatorInset value={1}>
                            Email Settings
                        </Tab>
                        <Tab indicatorInset value={2}>
                            Pickups and Destinations
                        </Tab>
                        <Tab indicatorInset value={3}>
                            Email Footer Settings
                        </Tab>
                    </TabList>
                    <TabPanel value={0}>
                        <AccountDetailsTab />
                    </TabPanel>
                    <TabPanel value={1}>
                        <EmailSettingsTab
                            isExclusionList={isExclusionList}
                            excludedStates={excludedStates}
                            setIsExclusionList={setIsExclusionList}
                            includedStates={includedStates}
                            setIncludedStates={setIncludedStates}
                            isMountainAreaIncluded={isMountainAreaIncluded}
                            setExcludedStates={setExcludedStates}
                            handleCheckboxChange={handleCheckboxChange}
                            statesList={statesList}
                            pricesList={pricesList}
                            fuelCost={fuelCost}
                            setFuelCost={setFuelCost}
                            driverCost={driverCost}
                            handleFuelCostChange={handleFuelCostChange}
                            handleDriverCostChange={handleDriverCostChange}
                            handleEmailTrigger={handleEmailTrigger}
                            setHandleEmailTrigger={setHandleEmailTrigger}
                            dispatchEmailSettings={dispatchEmailSettings}
                            loadingSetting={loadingSetting}
                            ratePerMile={ratePerMile}
                            handleRatePerMileChange={handleRatePerMileChange}
                        />
                    </TabPanel>
                    <TabPanel value={2}>
                        <PickupsDestinationsUI />
                    </TabPanel>
                    <TabPanel value={3}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                maxWidth: '1200px',
                                p: 3,
                                bgcolor: 'background.surface',
                                borderRadius: 'sm',
                                boxShadow: 'sm',
                            }}
                        >
                            <Box sx={{ mb: 3 }}>
                                <Typography level="h4" component="h2" sx={{ mb: 1 }}>
                                    Email Footer Settings
                                </Typography>
                                <Typography level="body-sm">
                                    Customize the footer that will appear at the bottom of all outgoing emails. You can use the editor below to format your
                                    text.
                                </Typography>
                            </Box>

                            <Divider sx={{ my: 2 }} />

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <FormLabel>Footer Content</FormLabel>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                        p: 2,
                                        bgcolor: 'background.level1',
                                        borderRadius: 'sm',
                                    }}
                                >
                                    <RichTextEditor
                                        value={emailClosingData}
                                        onChange={handleEmailClosingChange}
                                        placeholder="Enter your email footer content..."
                                        minHeight="auto"
                                        showToolbar={true}
                                    />
                                    <Typography level="body-sm" sx={{ mt: 1 }}>
                                        Use the toolbar above to format your text. You can add headers, lists, and basic text styling.
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        gap: 1,
                                        mt: 2,
                                    }}
                                >
                                    <Button variant="outlined" color="neutral" size="sm" onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                    <Button size="sm" onClick={upsertEmailFooterData} loading={loadingSetting}>
                                        Save Changes
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </TabPanel>
                </Tabs>
            </Box>
        </Stack>
    )
}
