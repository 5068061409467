import { Box, Button, Input, Modal, Typography, useTheme } from '@mui/joy'

const SettingModal = ({ openSettingsModal, handleCloseSettings, setRadius, radius, handleSaveSettings }) => {
    const theme = useTheme()
    return (
        <Modal
            open={openSettingsModal}
            onClose={handleCloseSettings}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    p: 4,
                    maxWidth: '400px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#333' : 'white',
                    borderRadius: '12px',
                    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 8,
                    width: '600px',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Typography
                        fontWeight="bold"
                        sx={{
                            mb: 3,
                            color: theme.palette.mode === 'dark' ? '#fff' : '#333',
                        }}
                    >
                        Search Radius Settings
                    </Typography>

                    <Input
                        type="number"
                        value={radius}
                        onChange={(e) => setRadius(Number(e.target.value))}
                        placeholder="Enter search radius in miles..."
                        fullWidth
                        sx={{
                            borderRadius: '8px',
                            backgroundColor: theme.palette.mode === 'dark' ? '#444' : '#f9f9f9',
                            mb: 3,
                            padding: '12px 16px',
                            '& .MuiInputBase-root': {
                                fontSize: '16px',
                                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                            },
                        }}
                    />
                </Box>

                <Box
                    display="flex"
                    gap={2}
                    justifyContent="center"
                    sx={{
                        width: '100%',
                    }}
                >
                    <Button
                        variant="outlined"
                        color="neutral"
                        onClick={handleCloseSettings}
                        sx={{
                            width: '48%',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="solid"
                        color="primary"
                        onClick={handleSaveSettings}
                        sx={{
                            width: '48%',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                        }}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
export default SettingModal
