import React, { useEffect, useState } from 'react'
import { Box, Typography, Button, Sheet } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { RichTextEditor } from 'components/common/RichTextEditor'

export default function TermsPage() {
    const [termsContent, setTermsContent] = useState<string>('')
    const navigate = useNavigate()

    useEffect(() => {
        fetch('/service-terms.html')
            .then((response) => response.text())
            .then((html) => setTermsContent(html))
            .catch((error) => console.error('Error loading terms of service:', error))
    }, [])

    const handleBack = () => {
        navigate(-1)
    }

    const handleUnderstand = () => {
        navigate(-1)
    }

    return (
        <Box
            component="main"
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
                maxWidth: 800,
                mx: 'auto',
                px: 3,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <Button variant="plain" color="neutral" startDecorator={<ArrowBackIcon />} onClick={handleBack} sx={{ alignSelf: 'flex-start' }}>
                    Back
                </Button>
                <Typography level="h3">Terms of Service</Typography>
            </Box>

            <Sheet
                variant="outlined"
                sx={{
                    p: 3,
                    borderRadius: 'sm',
                    maxHeight: '60vh',
                    overflowY: 'auto',
                }}
            >
                <RichTextEditor value={termsContent} onChange={() => {}} readOnly showToolbar={false} minHeight="auto" />
            </Sheet>

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
                <Button size="lg" onClick={handleUnderstand} sx={{ minWidth: 200 }}>
                    I understand
                </Button>
            </Box>
        </Box>
    )
}
