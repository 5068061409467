import { boolean, object, ObjectSchema, string } from 'yup'
import { ForgotPasswordData, ResetPasswordData, SignInData, SignUpData } from './types'

// Common validation rules
const emailSchema = string().email('Invalid email format').required('Email is required')

const passwordSchema = string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .required('Password is required')

const projectNameSchema = string()
    .min(2, 'Project name must be at least 2 characters')
    .matches(/^\S*$/, 'Project name must not contain spaces')
    .required('Project name is required')

// Type-safe request schemas
export const signInSchema: ObjectSchema<SignInData> = object({
    email: emailSchema,
    password: passwordSchema,
    persistent: boolean().optional().default(false),
})

export const signUpSchema: ObjectSchema<SignUpData> = object({
    email: emailSchema,
    password: passwordSchema,
    projectName: projectNameSchema,
})

export const forgotPasswordSchema: ObjectSchema<ForgotPasswordData> = object({
    email: emailSchema,
})

export const resetPasswordSchema: ObjectSchema<ResetPasswordData> = object({
    password: passwordSchema,
})
