/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/joy/Box'
import { useState, useCallback, useEffect } from 'react'
import { useMediaQuery } from '@mui/material'
import { EmailContent, Mails } from '../'
import { EmailThreadClient } from '../../api/EmailThreadClient'
import { CircularProgress } from '@mui/joy'
import { useSearchParams } from 'react-router-dom'
import { Email, EmailProcessingResult } from '@numeo/types'

export type EmailTabType = 'all' | 'lane' | 'spot' | 'lane-spot' | 'unclassified' | 'processing' | 'error'

interface EmailTabProps {
    type: EmailTabType
}

export default function EmailTab({ type }: EmailTabProps) {
    const [selectedExecutionId, setSelectedExecutionId] = useState<string | null>(null)
    const [selectedExecution, setSelectedExecution] = useState<{ email: Email; processingResult: EmailProcessingResult } | null>(null)
    const [loading, setLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const isMobile = useMediaQuery('(max-width:800px)')

    const handleExecutionSelect = useCallback(
        async (executionId: string, skipClosing: boolean = false) => {
            if (executionId === selectedExecutionId && !skipClosing) {
                setSelectedExecutionId(null)
                setSelectedExecution(null)
                return
            }

            setLoading(true)
            try {
                setSelectedExecutionId(executionId)
                const execution = await EmailThreadClient.getExecution(executionId)
                setSelectedExecution(execution)
                setSearchParams({ type, executionId })
            } catch (error) {
                console.error('Failed to fetch thread replies:', error)
            } finally {
                setLoading(false)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [type, selectedExecutionId]
    )

    const handleBack = () => {
        setSelectedExecutionId(null)
        setSelectedExecution(null)
        setSearchParams({ type })
    }

    useEffect(() => {
        const executionId = searchParams.get('executionId')
        if (executionId) {
            handleExecutionSelect(executionId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box sx={{ display: 'flex', overflow: 'hidden', height: 'calc(100vh - 5rem)', borderRadius: 'md' }}>
            {(!isMobile || !selectedExecutionId) && (
                <Box
                    sx={{
                        width: selectedExecutionId ? 65 : 400,
                        borderRight: '1px solid',
                        borderColor: 'divider',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                    }}
                >
                    <Mails selectedExecutionId={selectedExecutionId} onExecutionSelect={handleExecutionSelect} type={type} />
                </Box>
            )}
            {(!isMobile || selectedExecutionId) &&
                (loading ? (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1,
                        }}
                    >
                        <CircularProgress size="lg" />
                    </Box>
                ) : (
                    <Box sx={{ flex: 1, height: '100%', overflow: 'hidden' }}>
                        <EmailContent
                            onBack={isMobile || selectedExecutionId ? handleBack : undefined}
                            execution={selectedExecution}
                            refetch={async () => await handleExecutionSelect(selectedExecutionId || '', true)}
                        />
                    </Box>
                ))}
        </Box>
    )
}
