export class OrderRouteMapClient {
    static async getWeatherByCoordinates(latitude: number, longitude: number) {
        const response = await fetch(
            `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${process.env.REACT_APP_WEATHER_API_KEY}`,
            {
                method: 'GET',
            }
        )
        const data = await response.json()

        return data
    }
}
