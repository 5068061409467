import { Box, Button, Typography, Card, useTheme, CircularProgress, IconButton } from '@mui/joy'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useEffect, useState, useRef, useCallback } from 'react'
import spotApi from '../api/api'
import { TruckDataType, ThreadDataType } from '@numeo/types'
import { useGetQuery } from 'hooks/useGetQuery'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ActionDropdown from './Dropdown'
import Badge from '@mui/joy/Badge'
import TruckModal from './TruckModal'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TruckInfoModal from './TruckInfoModal'

const tempTruckData: TruckDataType = {
    mainInfo: '',
    truckId: '',
    driversNames: '',
    equipmentType: '',
    length: '',
    weight: '',
    deadHeadOrigin: '',
    deadHeadDestination: '',
}

const ChatThreads = ({ setShowAddModal, showAddModal, scrollToBottom, editingThread, setEditingThread, onThreadsUpdate }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const threadId = useGetQuery('threadId')
    const [truckDataTemp, setTruckDataTemp] = useState<TruckDataType>(tempTruckData)
    const [activeActionsThreadId, setActiveActionsThreadId] = useState<string | null>(null)
    const [latestThread, setLatestThread] = useState<ThreadDataType[]>([])
    const [truckUpsertLoading, setTruckUpsertLoading] = useState(false)
    const [infoModalThreadId, setInfoModalThreadId] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [loadingThreadId, setLoadingThreadId] = useState<string | null>(null)
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)

    const fetchThreads = useCallback(async () => {
        try {
            setIsLoading(true)
            const response = await spotApi.get('/trucks')
            if (response) {
                const sortedThreads = [...response].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                setLatestThread(sortedThreads)
                onThreadsUpdate?.(sortedThreads)
            }
        } catch (error) {
            console.error('Error fetching threads:', error)
        } finally {
            setIsLoading(false)
            setIsInitialLoad(false)
        }
    }, [onThreadsUpdate])

    const handleSelectThreadMessage = async (selectedThreadId: string | null) => {
        if (selectedThreadId) {
            try {
                setLoadingThreadId(selectedThreadId)
                const newSearchParams = new URLSearchParams(searchParams)
                newSearchParams.set('threadId', selectedThreadId)
                navigate(`?${newSearchParams.toString()}`, { replace: true })
            } catch (error) {
                console.error('Error selecting thread:', error)
            } finally {
                setLoadingThreadId(null)
            }
        } else {
            const newSearchParams = new URLSearchParams(searchParams)
            newSearchParams.delete('threadId')
            navigate(`?${newSearchParams.toString()}`, { replace: true })
        }
    }

    const handleDeleteSuccess = useCallback(() => {
        // Remove threadId from URL params
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.delete('threadId')
        setSearchParams(newSearchParams)

        // Fetch updated threads
        fetchThreads()
    }, [searchParams, setSearchParams, fetchThreads])

    useEffect(() => {
        if (isInitialLoad) {
            fetchThreads()
        }
    }, [isInitialLoad, fetchThreads])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (latestThread) {
            scrollToBottom()
        }
    }, [latestThread, scrollToBottom])

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' })
        }
    }

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' })
        }
    }

    const createATruckThread = async (driversNames: string) => {
        setTruckUpsertLoading(true)
        try {
            const requestData = {
                ...truckDataTemp,
                driversNames,
                deadHeadOrigin: truckDataTemp.deadHeadOrigin === '' ? 150 : truckDataTemp.deadHeadOrigin,
                deadHeadDestination: truckDataTemp.deadHeadDestination === '' ? 150 : truckDataTemp.deadHeadDestination,
            }
            const response = await spotApi.post('/trucks', requestData)
            setShowAddModal(false)
            setTruckDataTemp(tempTruckData)
            await fetchThreads()
            handleSelectThreadMessage(response.threadId.id)
        } catch (error) {
            console.log('Error getting data ', error)
        } finally {
            setTruckUpsertLoading(false)
        }
    }

    useEffect(() => {
        if (editingThread) {
            const threadData = latestThread?.find((thread) => thread.threadId === editingThread)
            if (threadData) {
                setTruckDataTemp({
                    ...threadData,
                    length: '',
                    weight: '',
                    deadHeadOrigin: '',
                    deadHeadDestination: '',
                })
            }
        }
    }, [editingThread, latestThread])

    const editTruckThread = async (driversNames: string, truckData: TruckDataType) => {
        setTruckUpsertLoading(true)
        try {
            await spotApi.put(`/trucks/${editingThread}`, {
                ...truckData,
                driversNames,
                threadId: editingThread,
                createdAt: latestThread?.find((thread) => thread.threadId === editingThread)?.createdAt,
                updatedAt: new Date().toISOString(),
            })
            setTruckDataTemp(tempTruckData)
            await fetchThreads()
            handleSelectThreadMessage(editingThread)
            setEditingThread(null)
        } catch (error) {
            console.log('Error getting data ', error)
        } finally {
            setTruckUpsertLoading(false)
        }
    }

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                position: 'relative',
                display: 'block',
                mt: 0.2,
            }}
        >
            {isLoading && isInitialLoad && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 1,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <TruckModal
                open={showAddModal}
                onClose={() => {
                    setTruckDataTemp(tempTruckData)
                    setShowAddModal(false)
                }}
                isEditing={false}
                truckDataTemp={truckDataTemp}
                setTruckDataTemp={setTruckDataTemp}
                onSave={(driversNames: string) => createATruckThread(driversNames)}
                loading={truckUpsertLoading}
            />

            <TruckModal
                open={!!editingThread}
                onClose={() => {
                    setTruckDataTemp(tempTruckData)
                    setEditingThread(null)
                }}
                isEditing={true}
                truckDataTemp={truckDataTemp}
                setTruckDataTemp={setTruckDataTemp}
                onSave={(driversNames: string) => editTruckThread(driversNames, truckDataTemp)}
                loading={truckUpsertLoading}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                        border: `1px solid ${theme.palette.mode === 'dark' ? '#000' : '#dfdede'}`,
                        width: '30px',
                        height: '30px',
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                        '&:hover': {
                            backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#f0faff',
                            borderColor: '#4782bd',
                        },
                    }}
                    onClick={scrollLeft}
                >
                    <ChevronLeftIcon />
                </IconButton>

                <Box
                    ref={scrollContainerRef}
                    sx={{
                        scrollBehavior: 'smooth',
                        width: '100%',
                        display: 'flex',
                        gap: 1.5,
                        overflowX: 'auto',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        '-ms-overflow-style': 'none',
                        'scrollbar-width': 'none',
                    }}
                >
                    {latestThread && latestThread.length === 0 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography sx={{ fontSize: '1rem', fontWeight: '500' }}>No threads yet</Typography>
                        </Box>
                    ) : isLoading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                flexDirection: 'column',
                                gap: 2,
                                width: '100%',
                            }}
                        >
                            <CircularProgress size="sm" />
                        </Box>
                    ) : (
                        latestThread.map((thread, index) => {
                            return (
                                <Card
                                    key={index}
                                    variant="outlined"
                                    sx={{
                                        width: '200px !important',
                                        maxWidth: '200px !important',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: 5,
                                        py: 0,
                                        pl: 2,
                                        pr: 0,
                                        borderRadius: '6px',
                                        backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                                        cursor: 'pointer',
                                        border: `0.5px solid ${thread.threadId === threadId ? '#007FFF' : '#dfdede'}`,
                                        '&:hover': {
                                            backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#f0faff',
                                            borderColor: '#4782bd',
                                        },
                                        '&:hover .icon-button': {
                                            opacity: 1,
                                            visibility: 'visible',
                                        },
                                    }}
                                    onClick={() => {
                                        handleSelectThreadMessage(thread.threadId)
                                        scrollToBottom()
                                    }}
                                >
                                    {thread.threadId === loadingThreadId && (
                                        <CircularProgress
                                            size="sm"
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                        />
                                    )}
                                    <Badge
                                        sx={{
                                            width: '180px !important',
                                            maxWidth: '180px !important',
                                        }}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        badgeContent={0}
                                        invisible={true}
                                        color="primary"
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                gap: 1,
                                                width: '100%',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    alignItems: 'between',
                                                    flexDirection: 'column',
                                                    gap: 1,
                                                    width: '50%',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        textTransform: 'capitalize',
                                                        fontSize: '0.8rem',
                                                        fontWeight: '600',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        maxWidth: '200px',
                                                    }}
                                                >
                                                    {thread.mainInfo}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'end',
                                                    alignItems: 'center',
                                                    width: '50%',
                                                }}
                                            >
                                                <Box
                                                    className="icon-button"
                                                    sx={{
                                                        display: 'flex',
                                                        opacity: 0,
                                                        visibility: 'hidden',
                                                    }}
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    <TruckInfoModal
                                                        threadData={thread}
                                                        activeActionsThreadId={activeActionsThreadId}
                                                        setActiveActionsThreadId={setActiveActionsThreadId}
                                                        infoModalThreadId={infoModalThreadId}
                                                        setInfoModalThreadId={setInfoModalThreadId}
                                                    />
                                                    <ActionDropdown
                                                        infoModalThreadId={infoModalThreadId}
                                                        setInfoModalThreadId={setInfoModalThreadId}
                                                        activeActionsThreadId={activeActionsThreadId}
                                                        setActiveActionsThreadId={setActiveActionsThreadId}
                                                        threadId={thread.threadId}
                                                        onEdit={() => setEditingThread(thread.threadId)}
                                                        threadList={latestThread}
                                                        onDeleteSuccess={handleDeleteSuccess}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Badge>
                                </Card>
                            )
                        })
                    )}
                </Box>
                <IconButton
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                        border: `1px solid ${theme.palette.mode === 'dark' ? '#000' : '#dfdede'}`,
                        width: '30px',
                        height: '30px',
                        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                        '&:hover': {
                            backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#f0faff',
                            borderColor: '#4782bd',
                        },
                    }}
                    onClick={scrollRight}
                >
                    <ChevronRightIcon />
                </IconButton>
                <Box sx={{ maxWidth: '100%' }}>
                    <Button
                        variant="soft"
                        color="primary"
                        sx={{
                            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                            width: '100%',
                            '.MuiButton-startDecorator': {
                                margin: 0,
                                padding: 0,
                            },
                            '--Button-gap': '0',
                            'padding-inline': '0.5rem',
                        }}
                        onClick={() => setShowAddModal(true)}
                        startDecorator={<AddCircleOutlineIcon sx={{ color: '#007FFF', fontSize: '1.5rem', padding: 0, margin: 0 }} />}
                    ></Button>
                </Box>
            </Box>

            {/*
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '2%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '90%',
                }}
            >
                <Button
                    variant="soft"
                    color="primary"
                    onClick={handleOpenSettings}
                    sx={{
                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                    }}
                    startDecorator={<Settings />}
                >
                    Settings
                </Button>
            </Box>
            */}
        </Box>
    )
}
export default ChatThreads
