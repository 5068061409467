/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Avatar,
    Box,
    ListDivider,
    ListItem,
    ListItemButton,
    ListItemDecorator,
    Typography,
    Select,
    Option,
    Modal,
    ModalDialog,
    Snackbar,
    Button,
    Tooltip,
    useColorScheme,
    CircularProgress,
} from '@mui/joy'
import { ArrowDownward, KeyboardArrowDown, KeyboardDoubleArrowDown, LocationOff, SouthEast, Warning, Refresh } from '@mui/icons-material'
import React from 'react'
import axios from 'axios'
import { Email } from '@numeo/types'
import { useAuth } from 'hooks/auth'

type OrderType = 'spot' | 'lane' | 'lane-spot' | 'unclassified' | 'error' | 'processing'

interface EmailCardProps {
    executionId: string
    execution: {
        email: Email
        emailData: Email['emailData']
        orderType?: OrderType
        processingResult?: any
    }
    isSelected: boolean
    onClick?: () => void
    selectedExecutionId: string | null
    onTypeChange?: (type: OrderType) => void
    refetchEmails?: (resetList?: boolean) => void
}

const orderTypes = [
    { value: 'spot', label: 'Spot' },
    { value: 'lane', label: 'Lane' },
    { value: 'lane-spot', label: 'Lane & Spot' },
]

const getUnderratedIcon = (level: string) => {
    switch (level) {
        case 'high':
            return <KeyboardDoubleArrowDown sx={{ color: 'red' }} />
        case 'medium':
            return <ArrowDownward sx={{ color: 'orange' }} />
        case 'low':
            return <SouthEast sx={{ color: 'green' }} />
        default:
            return <></>
    }
}

export default function EmailCard({ execution, isSelected, onClick, selectedExecutionId, onTypeChange, refetchEmails }: EmailCardProps) {
    const from = execution.email.emailData?.object.from[0]
    const senderName = from?.name || from?.email || ''
    const initial = senderName[0]?.toUpperCase() || ''
    const { mode } = useColorScheme()
    const { application } = useAuth()

    const getInitialOrderType = (): OrderType => {
        const type = execution?.processingResult?.orderType as OrderType
        switch (type) {
            case 'lane':
            case 'spot':
            case 'lane-spot':
            case 'unclassified':
            case 'processing':
            case 'error':
                return type
            default:
                return 'unclassified'
        }
    }

    const [orderType, setOrderType] = React.useState<OrderType>(getInitialOrderType())
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)
    const [newOrderType, setNewOrderType] = React.useState<OrderType>(execution.processingResult?.orderType || 'unclassified')
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false)
    const [isProcessing, setIsProcessing] = React.useState(false)

    const handleOrderTypeChange = (event: React.SyntheticEvent | null, value: OrderType | null) => {
        if (value) {
            setNewOrderType(value)
            setOpenConfirmDialog(true)
        }
    }

    const handleConfirmChange = async () => {
        setIsProcessing(true)
        try {
            await axios.put(`/n8n/processing-results/${execution.processingResult._id}`, {
                orderType: newOrderType,
            })

            setOrderType(newOrderType)
            setOpenConfirmDialog(false)
            setShowSuccessMessage(true)
            if (onTypeChange) {
                onTypeChange(newOrderType)
            }

            await refetchEmails?.(true)
        } catch (error) {
            console.error('Error reprocessing email:', error)
        } finally {
            setIsProcessing(false)
        }
    }

    const handleRetry = async () => {
        if (!application?.integrations?.n8n?.dispatcher?.emailReceiverWorkflowId) {
            return alert('Email receiver workflow is not configured') // FIXME: show error with toast component
        }

        setIsProcessing(true)
        setOrderType('processing')
        const { totalFuelPrice: fuelCostPerGallon, driverCost: driverCostPerMile, ratePerMile } = application?.temp?.emailSettings || {}

        try {
            const payload = {
                body: {
                    email: {
                        subject: execution.email.emailData.object.subject,
                        body: execution.email.emailData.object.body,
                        threadId: execution.email.emailData.object.thread_id,
                    },
                    settings: {
                        ratePerMile,
                        driverCostPerMile,
                        fuelCostPerGallon,
                        states: application?.temp?.emailSettings?.states || {
                            excluded: [],
                            included: [],
                            activeList: 'included',
                        },
                        applicationName: application?.projectName,
                    },
                    result: {},
                },
            }

            await axios.post(`https://dev-backoffice.numeo.ai/webhook/${application.integrations.n8n.dispatcher.emailReceiverWorkflowId}`, payload)
            await refetchEmails?.(true)
        } catch (error) {
            console.error('Error retrying email processing:', error)
        } finally {
            setIsProcessing(false)
        }
    }

    const getChipColor = () => {
        switch (orderType) {
            case 'spot':
                return 'success'
            case 'lane':
                return 'primary'
            case 'lane-spot':
                return 'warning'
            default:
                return 'neutral'
        }
    }

    const renderOrderTypeControl = (orderType: OrderType, handleOrderTypeChange: any, handleRetry: () => void) => {
        switch (orderType) {
            case 'processing':
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircularProgress size="sm" />
                        <Typography level="body-sm" color="neutral">
                            In Progress
                        </Typography>
                    </Box>
                )
            case 'error':
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography level="body-sm" color="danger">
                            Failed
                        </Typography>
                        <Box
                            onClick={(e) => {
                                e.stopPropagation()
                                handleRetry()
                            }}
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            <Refresh sx={{ width: 16, height: 16 }} />
                        </Box>
                    </Box>
                )
            default:
                return (
                    <Select
                        variant="soft"
                        color={getChipColor()}
                        value={orderType}
                        onChange={handleOrderTypeChange}
                        size="sm"
                        indicator={<KeyboardArrowDown />}
                        sx={{
                            minWidth: 110,
                            '--Select-decoratorChildHeight': '20px',
                            fontSize: 'xs',
                            '& .Select-indicator': { fontSize: '1rem' },
                        }}
                        slotProps={{
                            button: {
                                onClick: (e: React.MouseEvent) => e.stopPropagation(),
                            },
                        }}
                    >
                        {orderTypes.map((type) => (
                            <Option key={type.value} value={type.value}>
                                {type.label}
                            </Option>
                        ))}
                    </Select>
                )
        }
    }

    return (
        <React.Fragment>
            <Tooltip
                arrow
                sx={{
                    '& .MuiTooltip-tooltip': {
                        fontSize: '0.8rem',
                    },
                    borderRadius: 'sm',
                    bgcolor: mode === 'dark' ? 'primary.700' : 'primary.100',
                }}
                title={
                    selectedExecutionId && (
                        <Box sx={{ width: 300 }}>
                            <Typography>{execution.email.emailData.object.from[0].email}</Typography>
                            <Typography sx={{ fontSize: '0.8rem' }}>{execution.email.emailData.object.subject}</Typography>
                        </Box>
                    )
                }
                placement="left-start"
            >
                <ListItem>
                    <ListItemButton
                        selected={isSelected}
                        onClick={onClick}
                        sx={{
                            p: 2,
                            transition: 'all 0.2s ease-in-out',
                            bgcolor: isSelected ? 'primary.50' : 'background.surface',
                        }}
                    >
                        <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
                            <Avatar
                                variant="soft"
                                color="neutral"
                                sx={{
                                    '--Avatar-size': '32px',
                                }}
                            >
                                {initial}
                            </Avatar>
                        </ListItemDecorator>
                        <Box sx={{ pl: 2, width: '100%' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: 0.5,
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography
                                        level="title-sm"
                                        sx={{
                                            fontWeight: 600,
                                            color: 'text.secondary',
                                            width: selectedExecutionId ? 200 : 'auto',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {senderName}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography
                                        level="body-xs"
                                        sx={{
                                            color: 'text.tertiary',
                                        }}
                                    >
                                        {selectedExecutionId ? null : new Date(execution.email.emailData.object.date * 1000).toLocaleDateString()}
                                    </Typography>
                                    {orderType && (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(e) => e.stopPropagation()}>
                                            {renderOrderTypeControl(orderType, handleOrderTypeChange, handleRetry)}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Typography
                                level="body-sm"
                                sx={{
                                    fontWeight: 500,
                                    color: 'text.secondary',
                                    mb: 0.5,
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                }}
                            >
                                {selectedExecutionId ? null : execution.email.emailData.object.subject}
                            </Typography>
                            <Typography
                                level="body-sm"
                                sx={{
                                    color: 'text.secondary',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                }}
                            >
                                {selectedExecutionId ? null : execution.email.emailData.object.snippet}
                            </Typography>
                            <Box>
                                {execution?.email?.locationMismatch && orderType !== 'unclassified' && (
                                    <Tooltip title="Location mismatch">
                                        <LocationOff />
                                    </Tooltip>
                                )}
                                {orderType === 'unclassified' && (
                                    <Tooltip title={`Unclassified email: ${execution?.processingResult?.unclassificationReason || 'could not find reason'}`}>
                                        <Warning />
                                    </Tooltip>
                                )}
                                {execution?.processingResult?.underratingLevel && (
                                    <Tooltip title={`Offering rate: $${execution?.processingResult?.offeringRate}`}>
                                        {getUnderratedIcon(execution?.processingResult?.underratingLevel)}
                                    </Tooltip>
                                )}
                            </Box>
                        </Box>
                    </ListItemButton>
                </ListItem>
            </Tooltip>
            <ListDivider />

            <Modal open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                <ModalDialog variant="outlined" role="alertdialog" aria-labelledby="alert-dialog-modal-title" aria-describedby="alert-dialog-modal-description">
                    <Typography id="alert-dialog-modal-title" component="h2">
                        Confirm Type Change
                    </Typography>
                    <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
                        This action will reprocess the email. Are you sure you want to continue?
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
                        <Button variant="plain" color="neutral" onClick={() => setOpenConfirmDialog(false)}>
                            Cancel
                        </Button>
                        <Button variant="solid" color="primary" onClick={handleConfirmChange} loading={isProcessing}>
                            Confirm
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>

            <Snackbar
                variant="soft"
                color="success"
                autoHideDuration={3000}
                open={showSuccessMessage}
                onClose={() => setShowSuccessMessage(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                Email reprocessing completed successfully
            </Snackbar>
        </React.Fragment>
    )
}
