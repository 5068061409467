import React, { useEffect, useMemo } from 'react'
import { Box, Button, Modal, ModalDialog, ModalClose, Select, Option, Input, Typography, CircularProgress } from '@mui/joy'
import { allTypes, subTypes } from '../../../../../../../utils/truckEquipmentTypes'
import { TruckDataType, EquipmentType } from '@numeo/types'

export interface TruckModalProps {
    open: boolean
    onClose: () => void
    isEditing: boolean
    truckDataTemp: TruckDataType
    setTruckDataTemp: React.Dispatch<React.SetStateAction<TruckDataType>>
    onSave: (driversNames: string, finalTruckData: TruckDataType) => void
    loading: boolean
}

const TruckModal: React.FC<TruckModalProps> = ({ open, onClose, isEditing, truckDataTemp, setTruckDataTemp, onSave, loading }) => {
    const [availableTypes, setAvailableTypes] = React.useState<EquipmentType[]>([...allTypes, ...subTypes])
    const [firstDriversNames, setFirstDriversNames] = React.useState('')
    const [secondDriversNames, setSecondDriversNames] = React.useState('')

    const isFormValid = useMemo(() => {
        return !!(truckDataTemp?.mainInfo?.trim() && truckDataTemp?.truckId?.trim() && truckDataTemp?.equipmentType?.split(',').filter(Boolean).length)
    }, [truckDataTemp?.mainInfo, truckDataTemp?.truckId, truckDataTemp?.equipmentType])

    useEffect(() => {
        if (truckDataTemp?.driversNames?.split(',').length > 1) {
            setFirstDriversNames(truckDataTemp.driversNames.split(',')[0])
            setSecondDriversNames(truckDataTemp.driversNames.split(',')[1])
        } else if (truckDataTemp?.driversNames?.split(',').length === 1) {
            setFirstDriversNames(truckDataTemp.driversNames.split(',')[0])
            setSecondDriversNames('')
        }
    }, [truckDataTemp?.driversNames])

    const handleTypeChange = (_, value: string[]) => {
        setTruckDataTemp({ ...truckDataTemp, equipmentType: value.join(','), driversNames: `${firstDriversNames},${secondDriversNames}` })
        if (value.length === 0) {
            setAvailableTypes([...allTypes, ...subTypes])
        } else if (value.find((type) => type.length === 1)) {
            setAvailableTypes(availableTypes.filter((type) => type.code.length === 1))
        } else if (value.find((type) => type.length === 2)) {
            setAvailableTypes(availableTypes.filter((type) => type.code.length === 2))
        }
    }

    const handleSave = () => {
        // Create a copy for sending to the server, don't modify truckDataTemp directly
        const finalTruckData = { ...truckDataTemp }

        // Validate required fields
        const truckIdTrimmed = finalTruckData.truckId?.trim()
        const truckTypes = finalTruckData.equipmentType?.split(',')

        if (!truckIdTrimmed || !truckTypes || truckTypes.length === 0) {
            alert('Please enter truck ID, and select at least one equipment type.')
            return
        }

        // Format drivers names
        let driversNames = ''
        const firstDriversNamesTrimmed = firstDriversNames.trim()
        const secondDriversNamesTrimmed = secondDriversNames.trim()

        if (firstDriversNamesTrimmed === '' && secondDriversNamesTrimmed === '') {
            driversNames = ''
        } else if (firstDriversNamesTrimmed === '' || secondDriversNamesTrimmed === '') {
            driversNames = secondDriversNamesTrimmed || firstDriversNamesTrimmed
        } else {
            driversNames = `${firstDriversNamesTrimmed}, ${secondDriversNamesTrimmed}`
        }

        // Set default value of 150 for empty deadhead fields in the request data
        const requestData = {
            ...finalTruckData,
            deadHeadOrigin: finalTruckData.deadHeadOrigin === '' ? 150 : finalTruckData.deadHeadOrigin,
            deadHeadDestination: finalTruckData.deadHeadDestination === '' ? 150 : finalTruckData.deadHeadDestination,
        }

        onSave(driversNames, requestData)
    }

    return (
        <Modal
            open={open}
            onClose={(e, reason) => {
                // Prevent closing on backdrop click or escape key
                if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                    return
                }
                onClose()
            }}
        >
            <ModalDialog
                sx={{
                    maxWidth: 500,
                    borderRadius: 'md',
                    p: 3,
                    boxShadow: 'lg',
                }}
            >
                <ModalClose
                    variant="outlined"
                    onClick={(e) => {
                        // Prevent the default close behavior
                        e.preventDefault()
                    }}
                    sx={{
                        top: 'calc(-1/4 * var(--IconButton-size))',
                        right: 'calc(-1/4 * var(--IconButton-size))',
                        boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                        borderRadius: '50%',
                        bgcolor: 'background.surface',
                    }}
                />
                <Typography level="h4" component="h2">
                    {isEditing ? 'Edit Truck Details' : 'Add New Truck'}
                </Typography>
                <Box mt={2} display="flex" flexDirection="column" gap={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'row',
                        }}
                    >
                        <Input
                            placeholder="Enter truck name"
                            value={truckDataTemp?.mainInfo}
                            onChange={(e) => setTruckDataTemp({ ...truckDataTemp, mainInfo: e.target.value })}
                            required
                        />
                        <Input
                            placeholder="Enter truck ID"
                            value={truckDataTemp?.truckId}
                            onChange={(e) => setTruckDataTemp({ ...truckDataTemp, truckId: e.target.value })}
                            required
                        />
                    </Box>
                    <Input placeholder="1st Driver's Name" value={firstDriversNames} onChange={(e) => setFirstDriversNames(e.target.value)} />
                    <Input placeholder="2nd Driver's Name" value={secondDriversNames} onChange={(e) => setSecondDriversNames(e.target.value)} />

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'row',
                        }}
                    >
                        <Input
                            placeholder="Enter length"
                            value={truckDataTemp?.length || ''}
                            onChange={(e) => setTruckDataTemp({ ...truckDataTemp, length: e.target.value })}
                            sx={{ flex: 1 }}
                        />
                        <Input
                            placeholder="Enter weight"
                            value={truckDataTemp?.weight || ''}
                            onChange={(e) => setTruckDataTemp({ ...truckDataTemp, weight: e.target.value })}
                            sx={{ flex: 1 }}
                        />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            flexDirection: 'row',
                        }}
                    >
                        <Input
                            placeholder="Deadhead origin"
                            value={truckDataTemp?.deadHeadOrigin || ''}
                            onChange={(e) => setTruckDataTemp({ ...truckDataTemp, deadHeadOrigin: Number(e.target.value) })}
                            sx={{ flex: 1 }}
                        />
                        <Input
                            placeholder="Deadhead destination"
                            value={truckDataTemp?.deadHeadDestination || ''}
                            onChange={(e) => setTruckDataTemp({ ...truckDataTemp, deadHeadDestination: Number(e.target.value) })}
                            sx={{ flex: 1 }}
                        />
                    </Box>

                    <Select placeholder="Equipment Type" multiple value={truckDataTemp?.equipmentType?.split(',')} onChange={handleTypeChange} required>
                        {availableTypes.map((type, index) => (
                            <Option key={index} value={type.code}>
                                {type.name}
                            </Option>
                        ))}
                    </Select>
                    <Box display="flex" gap={1} justifyContent="flex-end">
                        <Button
                            variant="outlined"
                            color="neutral"
                            onClick={onClose}
                            sx={{
                                mr: 1,
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="solid"
                            color="primary"
                            onClick={handleSave}
                            disabled={loading || !isFormValid}
                            endDecorator={loading && <CircularProgress size="sm" variant="soft" />}
                            sx={{
                                minWidth: '100px',
                                position: 'relative',
                            }}
                        >
                            {loading ? 'Saving...' : isEditing ? 'Save Changes' : 'Save'}
                        </Button>
                    </Box>
                </Box>
            </ModalDialog>
        </Modal>
    )
}

export default TruckModal
