import { Box } from '@mui/joy'
import TruckManagementUI from './components/TruckManagementUI'

const SpotFinderComponent = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                overflow: 'hidden !important',
            }}
        >
            <TruckManagementUI />
        </Box>
    )
}
export default SpotFinderComponent
