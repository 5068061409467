import { Box, Sheet, Stack } from '@mui/joy'
import React from 'react'
import { CallList } from './components/CallList'

const CallCenterPage: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                gap: 2,
                p: 2,
                overflow: 'auto',
                height: '100%',
            }}
        >
            <Sheet variant="outlined" sx={{ borderRadius: 'sm', overflow: 'hidden' }}>
                <Stack spacing={2} sx={{ height: '100%' }}>
                    <CallList />
                </Stack>
            </Sheet>
        </Box>
    )
}

export default CallCenterPage
