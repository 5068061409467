import { Select, Typography, Option, FormControl, Box, Button, Input } from '@mui/joy'
import { useState } from 'react'
import { filterConditions, filterProperties } from '../statiscs'

const FilterEmailTriggerUI = () => {
    const [conditions, setConditions] = useState<Array<{ property: string; condition: string; text: string; logicalOperator: string }>>([
        { property: '', condition: '', text: '', logicalOperator: '' },
    ])

    const handleAddCondition = () => {
        setConditions([
            ...conditions,
            { property: '', condition: '', text: '', logicalOperator: 'AND' }, // Default logical operator
        ])
    }

    const handleConditionChange = (index: number, field: 'property' | 'condition' | 'text' | 'logicalOperator', value: string) => {
        const updatedConditions = [...conditions]
        updatedConditions[index][field] = value
        setConditions(updatedConditions)
    }

    const handleRemoveCondition = (index: number) => {
        if (conditions.length > 1) {
            setConditions(conditions.filter((_, i) => i !== index))
        }
    }

    const handleSubmit = () => {
        console.log('Final Conditions:', conditions)
        // Process the conditions array as needed
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxWidth: '500px',
                p: 2,
                border: '1px solid #ddd',
                borderRadius: '8px',
                // backgroundColor: 'rgba(251, 252, 253, 0.8)', // Joy palette token
            }}
        >
            <Typography fontWeight="bold">Properties list to filter</Typography>
            {conditions.map((condition, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: '4px',
                    }}
                >
                    {index > 0 && (
                        <FormControl>
                            <Box sx={{ minWidth: 100, marginBottom: 1 }}>
                                <Select
                                    placeholder="AND/OR"
                                    onChange={(e, newValue) => handleConditionChange(index, 'logicalOperator', newValue as string)}
                                    value={condition.logicalOperator}
                                >
                                    <Option value="AND">AND</Option>
                                    <Option value="OR">OR</Option>
                                </Select>
                            </Box>
                        </FormControl>
                    )}
                    <FormControl>
                        <Box sx={{ minWidth: 220 }}>
                            <Select
                                placeholder="Property to filter on"
                                onChange={(e, newValue) => handleConditionChange(index, 'property', newValue as string)}
                                value={condition.property}
                            >
                                {filterProperties.map((propertyName: string) => (
                                    <Option key={propertyName} value={propertyName}>
                                        {propertyName}
                                    </Option>
                                ))}
                            </Select>
                        </Box>
                    </FormControl>
                    <FormControl>
                        <Box sx={{ minWidth: 220 }}>
                            <Select
                                placeholder="Contains"
                                onChange={(e, newValue) => handleConditionChange(index, 'condition', newValue as string)}
                                value={condition.condition || 'contains'}
                            >
                                {filterConditions.map((conditionName: string) => (
                                    <Option key={conditionName} value={conditionName}>
                                        {conditionName}
                                    </Option>
                                ))}
                            </Select>
                        </Box>
                    </FormControl>
                    <FormControl>
                        <Input
                            placeholder="Enter text (no quotes needed)"
                            onChange={(e) => handleConditionChange(index, 'text', e.target.value)}
                            value={condition.text}
                        />
                    </FormControl>
                    {conditions.length > 1 && (
                        <Button
                            variant="outlined"
                            color="danger"
                            onClick={() => handleRemoveCondition(index)}
                            sx={{
                                textTransform: 'none',
                                alignSelf: 'flex-end',
                            }}
                        >
                            Remove
                        </Button>
                    )}
                </Box>
            ))}
            <Button
                variant="outlined"
                onClick={handleAddCondition}
                sx={{
                    textTransform: 'none',
                    alignSelf: 'flex-start',
                }}
            >
                + Condition
            </Button>
            <Button
                variant="solid"
                color="primary"
                onClick={handleSubmit}
                sx={{
                    textTransform: 'none',
                    alignSelf: 'flex-start',
                    mt: 2,
                }}
            >
                Submit
            </Button>
        </Box>
    )
}

export default FilterEmailTriggerUI
