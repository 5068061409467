import React, { useState, useEffect } from 'react'
import LoadsTableS from './LoadsTableS'
import LoadsTableL from './LoadsTableL'
import { DatabaseLoad } from '@numeo/types'
import { useWebSocket } from 'context/WebSocketContext'
import { useGetQuery } from 'hooks/useGetQuery'

interface LoadsTableProps {
    loads: DatabaseLoad[]
    sortOption: string
    setCallingLoadId: React.Dispatch<React.SetStateAction<string | null>>
    callingLoadId: string | null
    handlePhoneClick: (load: DatabaseLoad) => void
}

export default function TruckLoadTable(props: LoadsTableProps) {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1800)
    const { threadSearchStatuses } = useWebSocket()
    const threadId = useGetQuery('threadId')

    const isSearching = threadId ? threadSearchStatuses[threadId]?.searchStatus === 'active' : false

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 1800)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const tableProps = {
        ...props,
        isSearching,
    }

    return isLargeScreen ? <LoadsTableL {...tableProps} /> : <LoadsTableS {...tableProps} />
}
