// components/VoiceCall.tsx
import CallEndIcon from '@mui/icons-material/CallEnd'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import PhoneIcon from '@mui/icons-material/Phone'
import { Box, Button, CircularProgress, Stack, Textarea, Typography } from '@mui/joy'
import Alert from '@mui/joy/Alert'
import IconButton from '@mui/joy/IconButton'
import { Call, Device } from '@twilio/voice-sdk'
import React, { useEffect, useState } from 'react'
import { DispatcherDashboardClient } from '../api'
import { CallState } from '../api/DispatcherDashboardClient/types'
import AudioVisualizer from './AudioVisuaizetion'

export default function TwilioCaller() {
    const [state, setState] = useState<CallState>({
        device: null,
        call: null,
        status: 'disconnected',
        error: null,
    })
    const phoneNumber = '+15074971964' // FIXME - Use the phone number from the user

    const updateState = (newState: Partial<CallState>) => {
        setState((prev) => ({ ...prev, ...newState }))
    }

    const initDevice = async (): Promise<CallState> => {
        const { token } = await DispatcherDashboardClient.getToken()

        const newDevice = new Device(token, {
            // logLevel: 2,
            // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
            // providing better audio quality in restrained network conditions.
            codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
        })
        // console.log('TwilioCaller::New Device:', newDevice);

        newDevice.on('registered', () => updateState({ status: 'ready' }))
        newDevice.on('error', (error: Error) => {
            // console.error('Device Error:', error);
            updateState({ status: 'error', error: error.message })
        })
        newDevice.on('incoming', (call: Call) => {
            updateState({ call })
        })

        await newDevice.register()
        return { device: newDevice, call: null, status: 'disconnected', error: null }
    }

    useEffect(() => {
        return () => {
            if (state.device) state.device.destroy()
        }
    }, [state.device])

    const makeCall = async (phoneNumber: string) => {
        // updateState({ status: 'ready', call: {} as Call });
        // return; // FIXME - Remove this line to enable calling
        // Clean up any existing call
        if (state.device) {
            await state.device.destroy()
        }
        let newCallState: CallState
        try {
            newCallState = await initDevice()
            setState(newCallState)
        } catch (err) {
            // console.error('Error initializing device:', err);
            updateState({
                status: 'error',
                error: err instanceof Error ? err.message : 'Unknown error',
            })
            // Do not proceed with the call
            return
        }

        const { device } = newCallState
        if (!device) return // Must not happen

        try {
            // console.log('Making call to:', phoneNumber);
            const newCall = await device.connect({
                params: {
                    To: phoneNumber,
                    From: '+15005550000', // FIXME - Use the identity from the token
                    // From: '+12568297065', // FIXME - Use the identity from the token
                },
            })

            newCall.on('accept', () => {
                console.log('Call accepted')
                setTimeout(() => {
                    console.log(newCall.getRemoteStream())
                }, 10000)
                updateState({ status: 'on-call' })
            })
            newCall.on('disconnect', () => {
                updateState({ status: 'ready', call: null })
            })
            newCall.on('error', async (error: Error) => {
                await device.destroy()
                updateState({ status: 'error', error: error.message })
            })

            updateState({ call: newCall, status: 'connecting' })
        } catch (err) {
            updateState({
                status: 'error',
                error: err instanceof Error ? err.message : 'Unknown error',
            })
        }
    }

    const getStatusColor = () => {
        switch (state.status) {
            case 'ready':
                return 'success'
            case 'connecting':
                return 'warning'
            case 'on-call':
                return 'primary'
            case 'error':
                return 'danger'
            default:
                return 'neutral'
        }
    }

    // ...

    return (
        <Stack sx={{ width: '100%' }} direction={'row'} spacing={1} justifyContent={'space-evenly'}>
            <Box
                component="img"
                src={'https://i.imghippo.com/files/Elvs5686NRs.jpg'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    mx: 'auto',
                    maxHeight: 400,
                    position: 'relative',
                }}
                style={{ borderRadius: '10%' }}
            />

            <Box
                sx={{
                    width: '100%',
                    maxHeight: 400,
                    mx: 'auto',
                    overflow: 'auto',
                    my: 2,
                    p: 3,
                    borderRadius: 'sm',
                    border: '1px solid',
                    borderColor: '#e0e0e0',
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    maxWidth: 300,
                }}
            >
                <Typography level="h4" component="h1" sx={{ mb: 2 }}>
                    Voice Call
                </Typography>

                <Box sx={{ position: 'relative', height: '100%', flexGrow: 1 }}>
                    <Typography
                        level="body-sm"
                        sx={{
                            mb: 2,
                            transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
                            transform: state.call ? 'translateX(-100%)' : 'translateX(0)',
                            opacity: state.call ? 0 : 1,
                            pointerEvents: state.call ? 'none' : 'auto', // Disable interactions when hidden
                            position: 'absolute',
                        }}
                    >
                        This is your personal Dispatcher, Emily. She is here to assist you with updates on your truck status. Feel free to ask her anything
                        about your load status.
                        <br />
                        She's always here to help!
                    </Typography>

                    <Box
                        sx={{
                            transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
                            transform: state.call ? 'translateX(0)' : 'translateX(100%)',
                            opacity: state.call ? 1 : 0,
                            pointerEvents: state.call ? 'auto' : 'none', // Disable interactions when hidden
                            position: 'absolute',
                        }}
                    >
                        {state.call && (
                            <Box sx={{ height: '100%', flexGrow: 1 }}>
                                <AudioVisualizer activeCall={state.call} />
                            </Box>
                        )}
                    </Box>
                </Box>
                {state.error && (
                    <Alert
                        color="danger"
                        variant="soft"
                        sx={{ mb: 2 }}
                        endDecorator={
                            <IconButton variant="plain" size="sm" color="danger" onClick={() => updateState({ error: null })}>
                                <CloseRoundedIcon />
                            </IconButton>
                        }
                    >
                        {state.error}
                    </Alert>
                )}

                <Box sx={{ mb: 1 }}>
                    <Typography
                        level="body-sm"
                        startDecorator={state.status === 'connecting' ? <CircularProgress size="sm" /> : undefined}
                        color={getStatusColor()}
                    >
                        Status: {state.status}
                    </Typography>
                </Box>

                {!state.call && (
                    <Button
                        startDecorator={<PhoneIcon />}
                        disabled={['connecting', 'on-call'].includes(state.status)}
                        loading={state.status === 'connecting'}
                        color="success"
                        fullWidth
                        onClick={(e) => {
                            e.preventDefault()
                            if (phoneNumber) {
                                makeCall(phoneNumber)
                            }
                        }}
                    >
                        Make Call
                    </Button>
                )}
                {['connecting', 'on-call'].includes(state.status) && (
                    <Button
                        onClick={() => {
                            const { call, device } = state
                            if (call) {
                                call.disconnect()
                                device?.destroy()
                                setState({ call: null, device: null, status: 'ready', error: null })
                            }
                        }}
                        disabled={!state.call}
                        startDecorator={<CallEndIcon />}
                        color="danger"
                        fullWidth
                    >
                        Hang Up
                    </Button>
                )}
            </Box>
            <Box
                sx={{
                    width: '100%',
                    mx: 'auto',
                    my: 2,
                    p: 3,
                    borderRadius: 'sm',
                    border: '1px solid',
                    borderColor: '#e0e0e0',
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }}
            >
                <Typography level="h4" component="h2" sx={{ mb: 2 }}>
                    Notes
                </Typography>
                <Textarea
                    sx={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        outline: 'none',
                        fontFamily: 'inherit',
                        fontSize: 'inherit',
                        padding: 0,
                        margin: 0,
                        resize: 'none',
                        overflow: 'auto',
                        p: 1,
                    }}
                    minRows={2}
                    maxRows={4}
                    placeholder="Conversation details will apear here"
                />
            </Box>
        </Stack>
    )
}
