import { useLocation } from 'react-router-dom'

export const useGetQuery = (id: string) => {
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search)
    const threadId = queryParams.get(id)

    return threadId
}
