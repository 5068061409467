import { Call } from '@numeo/types'
import axios from 'axios'

class CallCenterApi {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async makeCall(phoneNumber: string): Promise<any> {
        try {
            const response = await axios.post('/voip/twilio/browser-sdk/make-call', {
                To: phoneNumber,
            })
            return response?.data || []
        } catch (error) {
            console.error('Error fetching call list:', error)
            return []
        }
    }

    async getCallList(
        status: 'active' | 'past' | undefined,
        projectName: string | undefined,
        filters?: { types?: string[]; status?: string[]; searchTerm?: string }
    ): Promise<Call[]> {
        try {
            const response = await axios.get('/calls/list', {
                params: {
                    status,
                    projectName,
                    filters: {
                        types: filters?.types || [],
                        status: filters?.status || [],
                        searchTerm: filters?.searchTerm || '',
                    },
                },
            })
            return response?.data || []
        } catch (error) {
            console.error('Error fetching call list:', error)
            return []
        }
    }
}

const callCenterApi = new CallCenterApi()
export default callCenterApi
