import { Box, Option, Select, Typography } from '@mui/joy'
import React, { SetStateAction } from 'react'

interface IStateInterface {
    data: { value: string; label: string; disabled: boolean }[]
    initialData: string[]
    setStateData: React.Dispatch<SetStateAction<string[]>>
    label: string
}

interface IPriceProgramSelector {
    data: { value: string; label: string }[] | null
    initialData: string
    setStateData: React.Dispatch<SetStateAction<number | null>>
}

export function StateSelector({ data, initialData, setStateData, label }: IStateInterface) {
    const handleChange = (event: React.SyntheticEvent | null, newValue: Array<string> | null) => {
        setStateData(newValue as string[])
    }
    console.log(label, 'label')

    return (
        <Box sx={{ minWidth: 220 }}>
            <Typography level="body-sm" sx={{ color: 'black', mb: 0.5, fontWeight: 500 }}>
                {label}
            </Typography>
            <Select multiple placeholder="Select states" onChange={handleChange} value={initialData}>
                {data.map(({ value, label, disabled }: { value: string; label: string; disabled: boolean }) => (
                    <Option key={value} value={value} disabled={disabled}>
                        {label}
                    </Option>
                ))}
            </Select>
        </Box>
    )
}

export const PriceProgramSelector = ({ data, initialData, setStateData }: IPriceProgramSelector) => {
    const handleChange = (newValue: string | null) => {
        if (newValue !== null) {
            const numberValue: number = Number(newValue)
            setStateData(numberValue)
        }
    }

    return (
        <Box sx={{ minWidth: 220 }}>
            <Select
                placeholder="Choose price programs"
                onChange={(event, newValue) => handleChange(newValue)}
                defaultValue={initialData || ''} // Handle initialData possibly being null or undefined
            >
                {data?.map(({ value, label }: { value: string; label: string }) => (
                    <Option key={value} value={value}>
                        {label}
                    </Option>
                ))}
            </Select>
        </Box>
    )
}
