export interface ApplicationNylasConnectedEmail {
    email: string
    provider: string
    grantId: string
}

// Application interfaces for nested objects
export interface IntegrationConfig {
    nylas?: {
        clientId?: string
        clientSecret?: string
        webhookSecret?: string
        enabled: boolean
        emails: ApplicationNylasConnectedEmail[]
    }
    voip?: {
        provider: 'twilio' | 'other'
        accountSid?: string
        authToken?: string
        enabled: boolean
    }
    n8n?: {
        projectId?: string
        dispatcher: {
            enabled: boolean
            reExecuteExecutionWorkflowId?: string
            lastEmailsProcessorWorkflowId?: string
            sendEmailWorkflowId?: string
            emailReceiverWorkflowId?: string
            emailProcessorWorkflowId?: string
            workflowId?: string
            loadReplyProcessorWorkflowId?: string
        }
    }
}

interface Toll {
    tollName: string
    cost: string
    _id: string
}

interface Route {
    distance: string
    duration: string
    from: string
    to: string
}

interface ApplicationSettings {
    theme?: {
        primaryColor?: string
        logoUrl?: string
    }
    notifications?: {
        email: boolean
        slack: boolean
    }
    features?: {
        emailEnabled: boolean
        voipEnabled: boolean
    }
}

interface MessengerConnector {
    telegram?: {
        id: string
        botId: string
    }
}

export interface GoogleDriveDocument {
    _id: string
    id: string
    email: string
    name: string
}

interface KnowledgeBaseConnector {
    googleDrives?: {
        email: string
        refreshToken: string
        scope: string
        accessToken: string
        expiresAt: Date
    }[]
    googleDocs?: GoogleDriveDocument[]
}

interface DataPipelineConnectors {
    messageners: MessengerConnector
    knowledgeBase: KnowledgeBaseConnector
}

export enum ApplicationSettingsStatesListType {
    INCLUDED = 'included',
    EXCLUDED = 'excluded',
}

export interface ApplicationEmailSettings {
    states?: {
        included: string[]
        excluded: string[]
        activeList: ApplicationSettingsStatesListType
    }
    mountainArea?: boolean
    price?: {
        min: number
        max: number
    }
}

export interface ApplicationEmailFooterSettings {
    closing: string
}

export interface ApplicationSpotFinder {
    assistantId: string
    trucks: {
        threadId: string
        mainInfo: string
        truckId: string
        driversNames: string
        equipmentType: string
        length: number | string
        weight: number | string
        deadHeadOrigin: string
        deadHeadDestination: string
        createdAt: Date
        updatedAt: Date
    }[]
}

interface TempData {
    emailSettings?: {
        isSetParameters?: boolean
        states?: {
            included: string[]
            excluded: string[]
            activeList: ApplicationSettingsStatesListType
        }
        mountainArea?: boolean
        pricePerGallon?: string
        driverCost?: string
        tollData?: {
            tolls?: Toll[]
            route?: Route[]
        }
        totalFuelPrice?: string
        elevationPrice?: string
        ratePerMile?: number
    }
    emailFooter?: ApplicationEmailFooterSettings
    spotFinder?: ApplicationSpotFinder
}

// Main Application interface
export interface Application {
    projectName: string
    description?: string
    status: 'active' | 'inactive' | 'suspended'
    settings: ApplicationSettings
    integrations: IntegrationConfig
    connectors: DataPipelineConnectors
    temp: TempData
}
