import { DatabaseLoad } from '@numeo/types'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { createInfoMessage } from '../EmailModal'

export const sendInfoEmail = async (
    load: DatabaseLoad,
    threadId: string,
    projectName: string,
    onEmailSent?: (success: boolean) => void,
    onLoadUpdate?: (loadId: string) => void
) => {
    if (!load?.posterInfo?.contact?.email) return false

    const subject = `Request Rate for ${load.origin!.city}, ${load.origin!.stateProv} to ${load.destination.city}, ${load.destination.stateProv} MC# ${load.posterInfo.mcNumber}`
    const message = createInfoMessage(load)

    try {
        const response = await axios.post('/trucks/send-email', {
            to: { email: load.posterInfo.contact.email },
            subject: subject,
            htmlBody: message,
        })

        const success = response.status === 200
        if (success) {
            // Update the load's isEmailSent status
            await axios.put(`/trucks/loads/${load.matchId}`, {
                isEmailSent: true,
                projectName,
                threadId,
                emailThreadId: response.data?.data?.emailThreadId,
            })

            // Notify UI to update the load's email status
            if (onLoadUpdate) onLoadUpdate(load.matchId)
        }
        if (onEmailSent) onEmailSent(success)
        return success
    } catch (error) {
        console.error('Failed to send info email:', error)
        if (onEmailSent) onEmailSent(false)
        return false
    }
}

export const timeDisplay = (date: string): string => {
    const diff: number = (new Date().getTime() - new Date(date).getTime()) / 60000
    return diff >= 60 ? `${Math.floor(diff / 60)}h` : `${Math.floor(diff)}m`
}

export const calculateRatePerMile = (miles: number, rate: number): string => {
    const ratePerMile = rate / miles
    if (isNaN(ratePerMile)) {
        return ''
    }

    return `$${ratePerMile.toFixed(2)}*/ml`
}

export function formatPhoneNumber(input: string): string {
    const extensionMatch = input.match(/ext\. (\d+)$/)
    const extension = extensionMatch ? extensionMatch[1] : undefined
    const phone = extensionMatch ? input.slice(0, extensionMatch.index).trim() : input.trim()

    const digits = phone.replace(/\D/g, '')

    let formattedNumber = ''
    if (digits.length === 11) {
        formattedNumber = `(${digits.substring(1, 4)}) ${digits.substring(4, 7)}-${digits.substring(7)}`
    } else if (digits.length === 10) {
        formattedNumber = `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6)}`
    }

    if (extension) {
        formattedNumber += ` ext. ${extension}`
    }

    return formattedNumber
}

export function sortLoads(loads: DatabaseLoad[], sortOption: string): DatabaseLoad[] {
    switch (sortOption) {
        // Age sorting - sort by hours from current time
        case 'ageNewest':
            return loads.sort((a, b) => {
                const timeA = (new Date().getTime() - new Date(a.postedAt).getTime()) / (60 * 60 * 1000)
                const timeB = (new Date().getTime() - new Date(b.postedAt).getTime()) / (60 * 60 * 1000)
                return timeA - timeB
            })
        case 'ageOldest':
            return loads.sort((a, b) => {
                const timeA = (new Date().getTime() - new Date(a.postedAt).getTime()) / (60 * 60 * 1000)
                const timeB = (new Date().getTime() - new Date(b.postedAt).getTime()) / (60 * 60 * 1000)
                return timeB - timeA
            })

        // Rate sorting - handle null/undefined values
        case 'rateHighest':
            return loads.sort((a, b) => {
                const rateA = a.rateInfo?.rateUsd ?? -Infinity
                const rateB = b.rateInfo?.rateUsd ?? -Infinity
                return rateB - rateA
            })
        case 'rateLowest':
            return loads.sort((a, b) => {
                const rateA = a.rateInfo?.rateUsd ?? Infinity
                const rateB = b.rateInfo?.rateUsd ?? Infinity
                return rateA - rateB
            })
        case 'ratePerMileHighest':
            return loads.sort((a, b) => {
                const rateA = a.rateInfo?.rateUsd && a.tripLength ? a.rateInfo.rateUsd / a.tripLength : -Infinity
                const rateB = b.rateInfo?.rateUsd && b.tripLength ? b.rateInfo.rateUsd / b.tripLength : -Infinity
                return rateB - rateA
            })
        case 'ratePerMileLowest':
            return loads.sort((a, b) => {
                const rateA = a.rateInfo?.rateUsd && a.tripLength ? a.rateInfo.rateUsd / a.tripLength : Infinity
                const rateB = b.rateInfo?.rateUsd && b.tripLength ? b.rateInfo.rateUsd / b.tripLength : Infinity
                return rateA - rateB
            })

        // Trip sorting - handle null/undefined values
        case 'tripShortest':
            return loads.sort((a, b) => {
                const tripA = a.tripLength ?? Infinity
                const tripB = b.tripLength ?? Infinity
                return tripA - tripB
            })
        case 'tripLongest':
            return loads.sort((a, b) => {
                const tripA = a.tripLength ?? -Infinity
                const tripB = b.tripLength ?? -Infinity
                return tripB - tripA
            })

        // Origin sorting - handle null/undefined values and case-insensitive
        case 'originAToZ':
            return loads.sort((a, b) => {
                const cityA = (a.origin?.city || '').toLowerCase()
                const cityB = (b.origin?.city || '').toLowerCase()
                return cityA.localeCompare(cityB)
            })
        case 'originZToA':
            return loads.sort((a, b) => {
                const cityA = (a.origin?.city || '').toLowerCase()
                const cityB = (b.origin?.city || '').toLowerCase()
                return cityB.localeCompare(cityA)
            })

        // Destination sorting - handle null/undefined values and case-insensitive
        case 'destinationAToZ':
            return loads.sort((a, b) => {
                const cityA = (a.destination?.city || '').toLowerCase()
                const cityB = (b.destination?.city || '').toLowerCase()
                return cityA.localeCompare(cityB)
            })
        case 'destinationZToA':
            return loads.sort((a, b) => {
                const cityA = (a.destination?.city || '').toLowerCase()
                const cityB = (b.destination?.city || '').toLowerCase()
                return cityB.localeCompare(cityA)
            })

        // Deadhead Origin sorting - handle null/undefined values
        case 'deadheadOShortest':
            return loads.sort((a, b) => {
                const milesA = a.originDeadheadMiles?.miles ?? Infinity
                const milesB = b.originDeadheadMiles?.miles ?? Infinity
                return milesA - milesB
            })
        case 'deadheadOLongest':
            return loads.sort((a, b) => {
                const milesA = a.originDeadheadMiles?.miles ?? -Infinity
                const milesB = b.originDeadheadMiles?.miles ?? -Infinity
                return milesB - milesA
            })

        // Deadhead Destination sorting - handle null/undefined values
        case 'deadheadDShortest':
            return loads.sort((a, b) => {
                const milesA = a.destinationDeadheadMiles?.miles ?? Infinity
                const milesB = b.destinationDeadheadMiles?.miles ?? Infinity
                return milesA - milesB
            })
        case 'deadheadDLongest':
            return loads.sort((a, b) => {
                const milesA = a.destinationDeadheadMiles?.miles ?? -Infinity
                const milesB = b.destinationDeadheadMiles?.miles ?? -Infinity
                return milesB - milesA
            })

        // Length sorting - handle null/undefined values
        case 'lengthLongest':
            return loads.sort((a, b) => {
                const lengthA = a.shipmentDetails?.maximumLengthFeet ?? -Infinity
                const lengthB = b.shipmentDetails?.maximumLengthFeet ?? -Infinity
                return lengthB - lengthA
            })
        case 'lengthShortest':
            return loads.sort((a, b) => {
                const lengthA = a.shipmentDetails?.maximumLengthFeet ?? Infinity
                const lengthB = b.shipmentDetails?.maximumLengthFeet ?? Infinity
                return lengthA - lengthB
            })

        // Pickup date sorting - sort by earliest/latest availability
        case 'pickUpEarliestFirst':
            return loads.sort((a, b) => {
                const dateA = new Date(a.earliestAvailability).getTime()
                const dateB = new Date(b.earliestAvailability).getTime()
                return dateA - dateB
            })
        case 'pickUpLatestFirst':
            return loads.sort((a, b) => {
                const dateA = new Date(a.latestAvailability).getTime()
                const dateB = new Date(b.latestAvailability).getTime()
                return dateB - dateA
            })

        // Weight sorting - handle null/undefined values
        case 'weightHeaviest':
            return loads.sort((a, b) => {
                const weightA = a.shipmentDetails?.maximumWeightPounds ?? -Infinity
                const weightB = b.shipmentDetails?.maximumWeightPounds ?? -Infinity
                return weightB - weightA
            })
        case 'weightLightest':
            return loads.sort((a, b) => {
                const weightA = a.shipmentDetails?.maximumWeightPounds ?? Infinity
                const weightB = b.shipmentDetails?.maximumWeightPounds ?? Infinity
                return weightA - weightB
            })

        // Capacity sorting - handle null/undefined values
        case 'capacityFullFirst':
            return loads.sort((a, b) => {
                const aIsFull = a.shipmentDetails?.fullPartial === 'FULL' ? 1 : 0
                const bIsFull = b.shipmentDetails?.fullPartial === 'FULL' ? 1 : 0
                return bIsFull - aIsFull
            })
        case 'capacityPartialFirst':
            return loads.sort((a, b) => {
                const aIsFull = a.shipmentDetails?.fullPartial === 'FULL' ? 0 : 1
                const bIsFull = b.shipmentDetails?.fullPartial === 'FULL' ? 0 : 1
                return bIsFull - aIsFull
            })

        // Company sorting - handle null/undefined values and case-insensitive
        case 'companyNameAToZ':
            return loads.sort((a, b) => {
                const companyA = (a.posterInfo?.companyName || '').toLowerCase()
                const companyB = (b.posterInfo?.companyName || '').toLowerCase()
                return companyA.localeCompare(companyB)
            })
        case 'companyNameZToA':
            return loads.sort((a, b) => {
                const companyA = (a.posterInfo?.companyName || '').toLowerCase()
                const companyB = (b.posterInfo?.companyName || '').toLowerCase()
                return companyB.localeCompare(companyA)
            })

        // Factorable sorting
        case 'factorableFirst':
            return loads.sort((a, b) => (b.isFactorable ? 1 : 0) - (a.isFactorable ? 1 : 0))
        case 'unfactorableFirst':
            return loads.sort((a, b) => (a.isFactorable ? 1 : 0) - (b.isFactorable ? 1 : 0))

        // Contact sorting
        case 'contactEmailFirst':
            return loads.sort((a, b) => {
                const aHasEmail = a.posterInfo?.contact?.email ? 1 : 0
                const bHasEmail = b.posterInfo?.contact?.email ? 1 : 0
                return bHasEmail - aHasEmail
            })
        case 'contactPhoneFirst':
            return loads.sort((a, b) => {
                const aHasPhone = a.posterInfo?.contact?.phoneNumber ? 1 : 0
                const bHasPhone = b.posterInfo?.contact?.phoneNumber ? 1 : 0
                return bHasPhone - aHasPhone
            })

        // Credit Score and Days to Pay sorting
        case 'creditScoreHighest':
            return loads.sort((a, b) => (b.posterInfo?.credit?.creditScore || 0) - (a.posterInfo?.credit?.creditScore || 0))
        case 'creditScoreLowest':
            return loads.sort((a, b) => (a.posterInfo?.credit?.creditScore || 0) - (b.posterInfo?.credit?.creditScore || 0))
        case 'daysToPayHighest':
            return loads.sort((a, b) => (b.posterInfo?.credit?.daysToPay || 0) - (a.posterInfo?.credit?.daysToPay || 0))
        case 'daysToPayLowest':
            return loads.sort((a, b) => (a.posterInfo?.credit?.daysToPay || 0) - (b.posterInfo?.credit?.daysToPay || 0))

        // Book sorting - sort by bookable status
        case 'bookableFirst':
            return loads.sort((a, b) => {
                const bookableA = a.bookingUrl ? 1 : 0
                const bookableB = b.bookingUrl ? 1 : 0
                return bookableB - bookableA
            })
        case 'unbookableFirst':
            return loads.sort((a, b) => {
                const bookableA = a.bookingUrl ? 1 : 0
                const bookableB = b.bookingUrl ? 1 : 0
                return bookableA - bookableB
            })

        default:
            return loads
    }
}

export const countVisibleTableHeaders = (tableSelector: string): number => {
    const table = document.querySelector(tableSelector)
    if (!table) return 0

    const headers = Array.from(table.querySelectorAll('th')).filter((header) => {
        const style = window.getComputedStyle(header)
        return style.display !== 'none'
    })
    return headers.length
}

export const useColspanManager = (tableSelector: string): number => {
    const [colspan, setColspan] = useState(0)

    const updateColspan = useCallback(() => {
        setColspan(countVisibleTableHeaders(tableSelector))
    }, [tableSelector])

    useEffect(() => {
        // Create a MutationObserver to watch for changes in the DOM
        const observer = new MutationObserver(() => {
            // Check if our table exists now
            const table = document.querySelector(tableSelector)
            if (table) {
                updateColspan()
            }
        })

        // Start observing the document with the configured parameters
        observer.observe(document.body, {
            childList: true,
            subtree: true,
        })

        // Initial check
        updateColspan()

        // Handle window resize
        const handleResize = () => {
            updateColspan()
        }
        window.addEventListener('resize', handleResize)

        // Cleanup
        return () => {
            observer.disconnect()
            window.removeEventListener('resize', handleResize)
        }
    }, [tableSelector, updateColspan])

    return colspan
}
