export const getWsSchema = () => {
    return window.location.protocol === 'https:' ? 'wss' : 'ws'
}

export const wssHostUrl = () => {
    const schema = getWsSchema()
    const apiUrl = `${schema}://${process.env.REACT_APP_API_HOST}`
    return apiUrl
}

export const wsConnectWithRetry = (path: string, onOpen: (ws: WebSocket) => void, onClose: () => void, maxRetryCount = 4): EventTarget => {
    const emitter = new EventTarget()
    let retryCount = 0

    const connect = (): WebSocket => {
        const ws = new WebSocket(wssHostUrl() + '/v1' + path)
        ws.onclose = (e) => {
            console.error('Socket::Internal:: connection closed:', e)
            if (retryCount < maxRetryCount) {
                console.log('Socket::Internal:: retrying connection...')
                const backoffTime = Math.pow(2, retryCount + 1) * 1000
                setTimeout(() => {
                    const newWs = connect()
                    retryCount++
                    emitter.dispatchEvent(new CustomEvent('newInstance', { detail: newWs }))
                }, backoffTime)
            } else {
                console.error('Socket::Internal:: max retries exceeded')
                onClose()
            }
        }
        ws.onopen = () => {
            console.log('Socket::Internal:: connection opened')
            retryCount = 0
            onOpen(ws)
        }
        return ws
    }
    console.log('Socket::Internal:: connecting to:', wssHostUrl() + path)
    const ws = connect()
    emitter.dispatchEvent(new CustomEvent('newInstance', { detail: ws }))

    return emitter
}
