/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, Box, IconButton, Typography } from '@mui/joy'
import { KeyboardArrowDownRounded } from '@mui/icons-material'

export interface EmailHeaderProps {
    emailData: any
    isReply?: boolean
    onToggleDetails?: () => void
    showDetails?: boolean
}

export default function EmailHeader({ emailData, isReply, onToggleDetails, showDetails }: EmailHeaderProps) {
    return (
        <Box
            onClick={isReply ? onToggleDetails : undefined}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 2,
                cursor: isReply ? 'pointer' : 'default',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
                <Avatar>{(emailData?.emailData?.object?.from[0]?.name || emailData?.emailData?.object?.from?.[0].email || '')[0]?.toUpperCase()}</Avatar>
                <Box sx={{ ml: 2 }}>
                    <Typography level="title-sm" textColor="text.primary" mb={0.5}>
                        {emailData?.emailData?.object?.from?.[0].name || emailData?.emailData?.object?.from?.[0].email}
                    </Typography>

                    <Typography level="body-xs" textColor="text.tertiary">
                        {new Date(emailData?.emailData?.object?.date * 1000).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                    </Typography>
                </Box>
            </Box>
            {isReply && (
                <IconButton
                    size="sm"
                    variant="plain"
                    color="neutral"
                    onClick={(e) => {
                        e.stopPropagation()
                        onToggleDetails?.()
                    }}
                    sx={{
                        transform: showDetails ? 'rotate(0deg)' : 'rotate(90deg)',
                        transition: 'transform 0.2s',
                    }}
                >
                    <KeyboardArrowDownRounded />
                </IconButton>
            )}
        </Box>
    )
}
