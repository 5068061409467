import * as React from 'react'
import IconButton from '@mui/joy/IconButton'
import Menu from '@mui/joy/Menu'
import MenuItem from '@mui/joy/MenuItem'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import ListDivider from '@mui/joy/ListDivider'
import MoreVert from '@mui/icons-material/MoreVert'
import Edit from '@mui/icons-material/Edit'
import DeleteForever from '@mui/icons-material/DeleteForever'
import MenuButton from '@mui/joy/MenuButton'
import Dropdown from '@mui/joy/Dropdown'
import spotApi from '../api/api'
import { CircularProgress } from '@mui/joy'
import { useWebSocket } from 'context/WebSocketContext'
import { ThreadDataType } from '@numeo/types'

interface IMoreActions {
    threadId: string
    onEdit: (thread) => void
    threadList: ThreadDataType[] | null
    activeActionsThreadId: string | null
    setActiveActionsThreadId: (threadId: string | null) => void
    infoModalThreadId: string | null
    setInfoModalThreadId: (infoModalThreadId: string | null) => void
}

export default function MoreActions({
    infoModalThreadId,
    setInfoModalThreadId,
    threadId,
    onEdit,
    threadList,
    activeActionsThreadId,
    setActiveActionsThreadId,
}: IMoreActions) {
    const [deleteLoading, setDeleteLoading] = React.useState(false)
    const { handleSelectThreadMessage, setIsUpdated, updateData } = useWebSocket()

    const onDelete = async (threadId: string) => {
        try {
            setDeleteLoading(true)
            const response = await spotApi.delete(`/trucks/${threadId}`)
            console.log(response)
            setIsUpdated((prev) => !prev)
        } catch (error) {
            console.log(`Error deleting thread: ${error}`)
        } finally {
            updateData()
            setDeleteLoading(false)
            setActiveActionsThreadId(null)
            handleSelectThreadMessage(null)
        }
    }

    return (
        <Dropdown>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{
                    root: {
                        variant: '',
                        color: '',
                        onClick: (event) => {
                            event.stopPropagation()
                            if (threadId === activeActionsThreadId) {
                                setActiveActionsThreadId(null)
                            } else {
                                setActiveActionsThreadId(threadId)
                                if (threadId === infoModalThreadId) {
                                    setInfoModalThreadId(null)
                                }
                            }
                        },
                    },
                }}
            >
                <MoreVert sx={{ fontSize: 16 }} />
            </MenuButton>
            <Menu
                placement="bottom-end"
                open={Boolean(threadId && activeActionsThreadId === threadId)}
                onClose={() => {
                    console.log('onClose')
                    setActiveActionsThreadId(null)
                }}
                onBlur={() => {
                    console.log('onBlur')
                    setActiveActionsThreadId(null)
                }}
            >
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation()
                        onEdit(threadList?.find((t) => t.threadId === threadId))
                        setActiveActionsThreadId(null)
                    }}
                    sx={{ display: 'flex', justifyContent: 'space-between', fontSize: 14 }}
                >
                    Edit
                    <ListItemDecorator>
                        <Edit sx={{ fontSize: 14 }} />
                    </ListItemDecorator>
                </MenuItem>
                <ListDivider />
                <MenuItem
                    variant="soft"
                    color="danger"
                    onClick={(e) => {
                        e.stopPropagation()
                        if (!deleteLoading) {
                            onDelete(threadId)
                        }
                    }}
                    sx={{ display: 'flex', justifyContent: 'space-between', fontSize: 14 }}
                >
                    Delete
                    <ListItemDecorator sx={{ color: 'inherit' }}>
                        {deleteLoading ? (
                            <CircularProgress
                                thickness={2}
                                sx={{
                                    color: 'inherit',
                                }}
                                size="sm"
                            />
                        ) : (
                            <DeleteForever sx={{ fontSize: 14 }} />
                        )}
                    </ListItemDecorator>
                </MenuItem>
            </Menu>
        </Dropdown>
    )
}
