// Dependencies
import React from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import AppRoutes from './pages/routes'
import ErrorBoundary from 'components/error/ErrorBoundary'
import { WebSocketProvider } from './context/WebSocketContext'
import { useAuth } from 'hooks/auth'
import { EmailSettingsModal } from 'components/EmailSettingsModal'
// import Notification from 'components/common/Notification'

// Initialize Day.js plugins
dayjs.extend(duration)
dayjs.extend(relativeTime)

// Main App Component
export default function App() {
    const { application, setApplication, verifyAuth } = useAuth()

    return (
        <ErrorBoundary>
            <WebSocketProvider>
                <AppRoutes />
                {/* Settings Modal | should be moved to a separate component */}
                {application && !application.temp?.emailSettings?.isSetParameters && (
                    <EmailSettingsModal verifyAuth={verifyAuth} application={application} setApplication={setApplication} />
                )}
            </WebSocketProvider>
        </ErrorBoundary>
    )
}
