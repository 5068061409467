import Select from '@mui/joy/Select'
import Option from '@mui/joy/Option'
import { Box, CircularProgress, IconButton, ListItemDecorator, Tooltip } from '@mui/joy'
import { Add, Email, Google, Microsoft, Remove } from '@mui/icons-material'
import { EmailAccount } from '../controllers/types'
import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useSnackbar } from '@mui/base'
import { NylasEmailClient } from '../controllers'
import { ClickAwayListener } from '@mui/material'

type ToastColor = 'primary' | 'neutral' | 'danger' | 'success' | 'warning'

export const EmailsSelector: FC = () => {
    const [state, setState] = useState<WidgetState | null>(null) // Null means loading
    const [deletingGrantId, setDeletingGrantId] = useState<string | null>(null)
    // Toast
    const [toast, setToast] = useState<{ message: string; color: ToastColor } | null>(null)

    const processOAuthCode = useCallback(async () => {
        try {
            await NylasEmailClient.processOAuthCode()
        } catch (error) {
            console.error('Error processing OAuth code:', error)
            showToast('Failed to process OAuth code', 'danger')
        }
    }, [])

    const fetchAccounts = useCallback(async () => {
        try {
            setState(null)
            const data = await NylasEmailClient.getAccounts()
            setState({
                selectedAccount: data?.[0],
                accounts: data,
                isAddingAccount: false,
            })
        } catch (error) {
            console.error('Error fetching accounts:', error)
            showToast('Failed to fetch email accounts', 'danger')
            setState({
                selectedAccount: null,
                accounts: [],
                isAddingAccount: false,
            })
        }
    }, [])

    const handleAddEmail = async () => {
        if (!state) {
            console.error('Widget state is expected. But it is null. Early exit from handleAddAccount')
            return
        }
        try {
            setState({ ...state, isAddingAccount: true })
            // const { authUrl } = await NylasEmailClient.getAuthUrl(process.env.REACT_APP_NYLAS_REDIRECT_URI!)

            const params = new URLSearchParams({
                client_id: process.env.REACT_APP_NYLAS_CLIENT_ID!,
                redirect_uri: process.env.REACT_APP_NYLAS_REDIRECT_URI!,
                response_type: 'code',
            })

            const authUrl = `${process.env.REACT_APP_NYLAS_API_URL}/v3/connect/auth?${params.toString()}`

            const authWindow = window.open(authUrl.toString(), 'Outlook Auth', 'width=500,height=600,scrollbars=yes')

            window.addEventListener('message', async function handleAuthMessage(event) {
                try {
                    if (event.origin !== window.location.origin) return

                    if (event.data.type === 'NYLAS_AUTH_SUCCESS') {
                        window.removeEventListener('message', handleAuthMessage)
                        const accounts = await NylasEmailClient.getAccounts()
                        setState({
                            selectedAccount: accounts[accounts.length - 1],
                            accounts: accounts,
                            isAddingAccount: false,
                        })
                        authWindow?.close()
                    } else if (event.data.type === 'NYLAS_AUTH_ERROR') {
                        window.removeEventListener('message', handleAuthMessage)
                        authWindow?.close()
                        showToast(event.data.error || 'Failed to add email', 'danger')
                    }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                    showToast(error?.message || 'Failed to add email', 'danger')
                }
            })
        } catch (error: unknown) {
            console.error('Error adding email:', error)
            setState({ ...state, isAddingAccount: false })
            showToast((error as Error).message || 'Failed to add email', 'danger')
        }
    }

    const handleDisconnect = async (account: EmailAccount) => {
        try {
            setDeletingGrantId(account.grantId)
            await NylasEmailClient.disconnect(account.grantId)
            showToast('Email account disconnected successfully', 'success')
            await fetchAccounts()
        } catch (error) {
            showToast(error instanceof Error ? error.message : 'Failed to disconnect email account', 'danger')
        } finally {
            setDeletingGrantId(null)
        }
    }

    useEffect(() => {
        processOAuthCode()
        fetchAccounts()
    }, [processOAuthCode, fetchAccounts])

    const handleClose = () => {
        setToast(null)
    }

    const { getRootProps, onClickAway } = useSnackbar({
        onClose: handleClose,
        open: toast != null,
        autoHideDuration: 15000,
    })

    const showToast = (message: string, color: ToastColor = 'neutral') => {
        setToast({
            message,
            color,
        })
    }
    let placeholderText = 'Fetching accounts...'
    if (state?.accounts.length === 0) {
        placeholderText = 'No accounts'
    } else if (state?.selectedAccount) {
        placeholderText = state.selectedAccount.email
    }
    return (
        <Box>
            <Select
                placeholder={placeholderText}
                startDecorator={state?.selectedAccount ? getProviderIcon(state?.selectedAccount.provider || 'all') : null}
                indicator={null}
                endDecorator={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Add email" variant="soft">
                            <IconButton
                                size="sm"
                                variant="soft"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleAddEmail()
                                }}
                            >
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
                onChange={(event: SyntheticEvent | null, newValue: EmailAccount | null) => {
                    newValue && state && setState({ ...state, selectedAccount: newValue })
                }}
                sx={{ width: 240 }}
            >
                {state?.accounts.map((account, i, arr) => (
                    <Option
                        sx={{
                            width: '100%',
                            alignItems: 'center',
                            gap: 1,
                            opacity: deletingGrantId === account.grantId ? 0.5 : 1,
                            pointerEvents: deletingGrantId ? 'none' : 'auto',
                            transition: 'opacity 0.2s ease-in-out',
                        }}
                        key={account.email}
                        value={account}
                    >
                        <ListItemDecorator>{getProviderIcon(account.provider)}</ListItemDecorator>
                        {account.email}
                        <Box sx={{ flexGrow: 1 }} />
                        {arr.length > 1 && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {deletingGrantId === account.grantId ? (
                                    <CircularProgress size="sm" />
                                ) : (
                                    <Tooltip title="Remove email" variant="soft">
                                        <IconButton
                                            size="sm"
                                            variant="soft"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleDisconnect(account)
                                            }}
                                            disabled={Boolean(deletingGrantId)}
                                        >
                                            <Remove />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box>
                        )}
                    </Option>
                ))}
            </Select>

            {toast ? (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div
                        {...getRootProps()}
                        style={{
                            position: 'fixed',
                            bottom: 16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: toast.color === 'danger' ? '#d32f2f' : toast.color === 'success' ? '#2e7d32' : '#1976d2',
                            color: 'white',
                            padding: '6px 16px',
                            borderRadius: 4,
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            zIndex: 1400,
                        }}
                    >
                        {toast.message}
                        <button
                            onClick={handleClose}
                            style={{
                                marginLeft: 8,
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                cursor: 'pointer',
                                padding: '4px',
                            }}
                        >
                            ✕
                        </button>
                    </div>
                </ClickAwayListener>
            ) : null}
        </Box>
    )
}
const getProviderIcon = (provider: string) => {
    switch (provider) {
        case 'google':
            return <Google />
        case 'microsoft':
            return <Microsoft />
        default:
            return <Email />
    }
}

interface WidgetState {
    accounts: EmailAccount[]
    selectedAccount: EmailAccount | null
    isAddingAccount: boolean
}
