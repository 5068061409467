import { Route, Routes, useLocation } from 'react-router-dom'
import DashboardRoutes from './dashboard'
import AuthRoutes from './auth'
import { Suspense } from 'react'
import MasterLayout from 'layouts/MasterLayout'
import { AuthLayout } from 'layouts'
import { CircularProgress } from '@mui/joy'
import { NylasCallback } from './auth/pages/callbacks'

export default function AppRoutes() {
    const location = useLocation()
    return (
        <Suspense fallback={<CircularProgress />}>
            <Routes key={location.pathname} location={location}>
                <Route path="auth/nylas/callback" element={<NylasCallback />} />
                <Route
                    path="auth/*"
                    element={
                        <AuthLayout>
                            <AuthRoutes />
                        </AuthLayout>
                    }
                />
                <Route
                    path="*"
                    element={
                        <MasterLayout>
                            <DashboardRoutes />
                        </MasterLayout>
                    }
                />
            </Routes>
        </Suspense>
    )
}
