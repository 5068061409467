import Box from '@mui/joy/Box'
import Breadcrumbs from '@mui/joy/Breadcrumbs'
import CssBaseline from '@mui/joy/CssBaseline'
import Link from '@mui/joy/Link'
import Typography from '@mui/joy/Typography'
import { CssVarsProvider } from '@mui/joy/styles'
import { extendTheme as materialExtendTheme, CssVarsProvider as MaterialCssVarsProvider, THEME_ID as MATERIAL_THEME_ID } from '@mui/material/styles'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import Header from './components/Header'
import Sidebar from './components/Sidebar'
import { Children } from 'types'
import { toggleSidebar } from '../utils'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/joy/IconButton'
import { useTheme } from '@mui/joy'

export default function MasterLayout({ children }: Children) {
    const pathname = window.location.pathname.split('/')
    const theme = useTheme()

    const materialTheme = materialExtendTheme()

    return (
        <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
            <CssVarsProvider disableTransitionOnChange>
                <CssBaseline />
                <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                    <Header />
                    <Sidebar />
                    <IconButton
                        sx={{
                            position: 'fixed',
                            top: 10,
                            left: 10,
                            gap: 1,
                            borderBottom: '1px solid',
                            borderColor: 'background.level1',
                            border: `1px solid ${theme.palette.mode === 'dark' ? '#000' : '#dfdede'}`,
                        }}
                        onClick={() => toggleSidebar()}
                        variant="outlined"
                        color="neutral"
                        size="md"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box
                        component="main"
                        className="MainContent"
                        sx={{
                            pl: { xs: 2, md: 6 },
                            pr: { xs: 2, md: 2 },
                            pt: {
                                xs: 'calc(12px + var(--Header-height))',
                                sm: 'calc(12px + var(--Header-height))',
                                md: 0,
                            },
                            pb: { xs: 2, sm: 2, md: 3 },
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 0,
                            height: '100vh',
                            gap: 1,
                        }}
                    >
                        {pathname.includes('/spot-finder') && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Breadcrumbs size="sm" aria-label="breadcrumbs" separator={<ChevronRightRoundedIcon fontSize="small" />} sx={{ pl: 0 }}>
                                    <Link underline="none" color="neutral" href="/" aria-label="Home">
                                        <HomeRoundedIcon />
                                    </Link>
                                    {pathname.map((path, index) => {
                                        if (path === '') return null
                                        return (
                                            <Link key={index} underline="none" color="neutral" href={`${window.location.pathname.split(path)[0] + path}`}>
                                                <Typography color="primary" fontWeight={500} fontSize={12} sx={{ textTransform: 'capitalize' }}>
                                                    {path}
                                                </Typography>
                                            </Link>
                                        )
                                    })}
                                </Breadcrumbs>
                            </Box>
                        )}

                        {/*
                    <Typography level="h3" sx={{ textTransform: 'capitalize', pl: 1 }}>
                        {pathname ? pathname?.pop()?.replace(/-/g, ' ') : 'Dashboard'}
                    </Typography>
                    */}

                        {children}
                    </Box>
                </Box>
            </CssVarsProvider>
        </MaterialCssVarsProvider>
    )
}
