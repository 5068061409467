import * as React from 'react'
import IconButton from '@mui/joy/IconButton'
import Menu from '@mui/joy/Menu'
import MenuItem from '@mui/joy/MenuItem'
import MenuButton from '@mui/joy/MenuButton'
import Dropdown from '@mui/joy/Dropdown'
import { Box, Typography, useTheme } from '@mui/joy'
import { ThreadDataType } from '@numeo/types'
import InfoIcon from '@mui/icons-material/Info'
interface IMoreActions {
    threadData: ThreadDataType
    activeActionsThreadId: string | null
    setActiveActionsThreadId: (infoModalThreadId: string | null) => void
    infoModalThreadId: string | null
    setInfoModalThreadId: (infoModalThreadId: string | null) => void
}

export default function TruckInfoModal({ activeActionsThreadId, setActiveActionsThreadId, threadData, infoModalThreadId, setInfoModalThreadId }: IMoreActions) {
    const theme = useTheme()

    return (
        <Dropdown>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{
                    root: {
                        variant: '',
                        color: '',
                        padding: 0,
                        onClick: (event) => {
                            event.stopPropagation()
                            if (threadData.threadId === infoModalThreadId) {
                                setInfoModalThreadId(null)
                            } else {
                                setInfoModalThreadId(threadData.threadId)
                                if (threadData.threadId === activeActionsThreadId) {
                                    setActiveActionsThreadId(null)
                                }
                            }
                        },
                    },
                }}
            >
                <InfoIcon sx={{ fontSize: 16 }} />
            </MenuButton>
            <Menu
                placement="bottom-end"
                open={Boolean(threadData.threadId && infoModalThreadId === threadData.threadId)}
                onClose={() => setInfoModalThreadId(null)}
                onBlur={() => setInfoModalThreadId(null)}
                sx={{ minWidth: '200px' }}
            >
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation()
                        setInfoModalThreadId(null)
                    }}
                    sx={{ display: 'flex', justifyContent: 'space-between', fontSize: 14 }}
                >
                    <Box
                        flex={1}
                        sx={{
                            p: 2,
                            backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                        }}
                    >
                        <Typography fontWeight="bold" sx={{ mb: 1, fontSize: '15px', fontWeight: '500' }}>
                            <b>Truck Name:</b> <b>{threadData?.mainInfo ? threadData?.mainInfo : 'Not available'}</b>
                        </Typography>
                        <Typography fontWeight="bold" sx={{ mb: 1, fontSize: '15px', fontWeight: '500' }}>
                            <b>Truck ID:</b> <b>{threadData?.truckId ? threadData?.truckId : 'Not available'}</b>
                        </Typography>
                        <Typography fontWeight="bold" sx={{ mb: 1, fontSize: '15px', fontWeight: '500' }}>
                            <b>Equipment Types:</b> <b>{threadData?.equipmentType ? threadData?.equipmentType : 'Not available'}</b>
                        </Typography>
                        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems={{ sm: 'center' }} sx={{ mb: 1 }}>
                            <Typography fontWeight="bold" sx={{ fontSize: '15px', fontWeight: '500', mb: { xs: 1, sm: 0 }, mr: { xs: 0, sm: 1 } }}>
                                <b>Drivers' Names:</b>
                            </Typography>
                            <Typography>
                                <b>{threadData?.driversNames ? threadData?.driversNames : 'Not available'}</b>
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                mb: 1,
                                fontSize: '14px',
                                fontWeight: '500',
                                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                            }}
                        >
                            <b>Additional Information:</b> Additional truck information will be available soon.
                        </Typography>
                    </Box>
                </MenuItem>
            </Menu>
        </Dropdown>
    )
}
