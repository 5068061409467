import React, { useEffect } from 'react'
import { Box, Button, Modal, ModalDialog, Select, Option, Input, Typography, useColorScheme, Snackbar } from '@mui/joy'
import { DatabaseLoad } from '@numeo/types'
import { RichTextEditor } from '../../../../../../../components/common'
import dayjs from 'dayjs'
import axios from 'axios'
import { useGetQuery } from '../../../../../../../hooks/useGetQuery'
import { useAuth } from '../../../../../../../hooks/auth/Provider'

interface EmailModalProps {
    open: boolean
    onClose: () => void
    load?: DatabaseLoad
    onEmailSent?: (success: boolean) => void
    onLoadUpdate?: (loadId: string) => void
    embedded?: boolean
}

const createBidMessage = (load: DatabaseLoad, totalRate: number = 0) => {
    if (!load) return ''

    return `<p>Hello,</p>

<p>I'm reaching out regarding your order from ${load.origin!.city}, ${load.origin!.stateProv}, to ${load.destination.city}, ${load.destination.stateProv}. I'd like to propose a rate of <b>$${totalRate.toLocaleString()}</b>.</p>

<p>Could you let us know if this proposal works for you or if you'd like to discuss further?</p>

<p>Thanks,<br>
 Numoe </p>

<p>SFG Logistics<br>
MC:${load.posterInfo.mcNumber}</p>`
}

export const createInfoMessage = (load: DatabaseLoad) => {
    return `<p>Hello, team!</p>
<p>Can you share more details about the load from <strong>${load.origin.city}, ${load.origin.stateProv}</strong> to <strong>${load.destination.city}, ${load.destination.stateProv}</strong>, scheduled for <strong>${dayjs(load.earliestAvailability).format('MM/DD/YYYY')}</strong>?</p>

<p>Additionally, could you let us know the best rate available for this load?</p>

<p>Thanks,<br>
 Numoe </p>

<p>SFG Logistics<br>
MC:${load.posterInfo.mcNumber}</p>`
}

const EmailModal: React.FC<EmailModalProps> = ({ open, onClose, load, onEmailSent, onLoadUpdate, embedded = false }) => {
    const [emailType, setEmailType] = React.useState<'bid' | 'info'>('info')
    const [totalRate, setTotalRate] = React.useState<number>(0)
    const [bidMessage, setBidMessage] = React.useState<string>('')
    const [infoMessage, setInfoMessage] = React.useState<string>('')
    const [sendingEmail, setSendingEmail] = React.useState(false)
    const [snackbar, setSnackbar] = React.useState<{
        open: boolean
        message: string
        color: 'success' | 'danger'
    }>({
        open: false,
        message: '',
        color: 'success',
    })
    const threadId = useGetQuery('threadId')
    const { application } = useAuth()
    const [subject, setSubject] = React.useState(
        load
            ? `Request Rate for ${load.origin!.city}, ${load.origin!.stateProv} to ${load.destination.city}, ${load.destination.stateProv} MC# ${load.posterInfo.mcNumber}`
            : ''
    )
    const [ratePerMile, setRatePerMile] = React.useState<string>('0.00')
    const { mode } = useColorScheme()

    useEffect(() => {
        if (load?.tripLength) {
            // If rate info is available, use rate per mile * trip length
            if (load.rateInfo?.rateUsd) {
                const ratePerMile = (load.rateInfo.rateUsd / load.tripLength).toFixed(2)
                setRatePerMile(ratePerMile)
                setTotalRate(Math.round(parseFloat(ratePerMile) * load.tripLength))
            } else {
                // Default to 2.5 per mile if no rate info
                setRatePerMile('2.50')
                setTotalRate(Math.round(2.5 * load.tripLength))
            }
        }
    }, [load?.tripLength, load?.rateInfo?.rateUsd])

    useEffect(() => {
        if (load) {
            setBidMessage(createBidMessage(load, totalRate))
            setInfoMessage(createInfoMessage(load))
        }
    }, [load, totalRate])

    const handleSend = async () => {
        if (!load) return

        setSendingEmail(true)
        const message = emailType === 'bid' ? bidMessage : infoMessage
        try {
            const response = await axios.post('/trucks/send-email', {
                to: { email: load.posterInfo.contact.email },
                subject: subject,
                htmlBody: message,
            })

            if (response.status === 200) {
                // Update the load's isEmailSent status
                await axios.put(`/trucks/loads/${load.matchId}`, {
                    isEmailSent: true,
                    projectName: application?.projectName || '',
                    threadId: threadId || '',
                    emailThreadId: response.data?.data?.emailThreadId,
                })

                // Update UI to show email sent status
                onLoadUpdate?.(load.matchId)

                setSnackbar({
                    open: true,
                    message: 'Email sent successfully!',
                    color: 'success',
                })
                onEmailSent?.(true)
            } else {
                setSnackbar({
                    open: true,
                    message: 'Failed to send email',
                    color: 'danger',
                })
                onEmailSent?.(false)
            }
            onClose()
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Failed to send email',
                color: 'danger',
            })
            onEmailSent?.(false)
        } finally {
            setSendingEmail(false)
        }
    }

    const handleSnackbarClose = () => {
        setSnackbar((prev) => ({ ...prev, open: false }))
    }

    const content = (
        <Box
            sx={{
                p: embedded ? 0 : 2,
                border: '1px solid',
                borderColor: mode === 'dark' ? '#666' : '#e0e0e0',
                borderRadius: '8px',
                padding: '1rem',
            }}
        >
            <Box sx={{ mb: 2 }}>
                <Typography level="body-md" sx={{ fontSize: '0.8rem', mb: 1 }}>
                    Email Type
                </Typography>
                <Select
                    sx={{ fontSize: '0.8rem' }}
                    value={emailType}
                    onChange={(_, newValue) => {
                        if (newValue) {
                            setEmailType(newValue as 'bid' | 'info')
                            setSubject(
                                newValue === 'bid'
                                    ? load
                                        ? `Bid for ${load.origin!.city}, ${load.origin!.stateProv} to ${load.destination.city}, ${load.destination.stateProv} MC# ${load.posterInfo.mcNumber}`
                                        : ''
                                    : load
                                      ? `Request Rate for ${load.origin!.city}, ${load.origin!.stateProv} to ${load.destination.city}, ${load.destination.stateProv} MC# ${load.posterInfo.mcNumber}`
                                      : ''
                            )
                        }
                    }}
                >
                    <Option sx={{ fontSize: '0.8rem' }} value="bid">
                        Bid
                    </Option>
                    <Option sx={{ fontSize: '0.8rem' }} value="info">
                        Request Rate
                    </Option>
                </Select>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                    <Typography level="body-md" sx={{ fontSize: '0.8rem' }}>
                        Subject
                    </Typography>
                    <Input value={subject} onChange={(e) => setSubject(e.target.value)} sx={{ width: '100%', fontSize: '0.8rem' }} />
                </Box>
                {emailType === 'bid' && (
                    <Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box sx={{ flex: 1 }}>
                                <Typography level="body-md" sx={{ fontSize: '0.8rem' }}>
                                    Total Rate ($)
                                </Typography>
                                <Input
                                    slotProps={{
                                        input: {
                                            type: 'number',
                                            pattern: '[0-9]*',
                                            inputMode: 'numeric',
                                            onKeyDown: (e) => {
                                                if (!/[\d\b]/.test(e.key) && !['Delete', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                                                    e.preventDefault()
                                                }
                                            },
                                        },
                                    }}
                                    value={totalRate === 0 ? '' : totalRate}
                                    onChange={(e) => {
                                        const val = e.target.value.replace(/\D/g, '')
                                        if (val === '') {
                                            setTotalRate(0)
                                            setRatePerMile('0.00')
                                        } else {
                                            const num = parseInt(val)
                                            setTotalRate(num)
                                            if (load?.tripLength) {
                                                const newRatePerMile = (num / load.tripLength).toFixed(2)
                                                setRatePerMile(newRatePerMile)
                                            }
                                        }
                                    }}
                                    sx={{
                                        width: '100%',
                                        fontSize: '0.8rem',
                                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                            display: 'none',
                                        },
                                        '& input[type=number]': {
                                            MozAppearance: 'textfield',
                                        },
                                    }}
                                />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Typography level="body-md" sx={{ fontSize: '0.8rem' }}>
                                    Rate Per Mile ($)
                                </Typography>
                                <Input
                                    value={ratePerMile}
                                    onChange={(e) => {
                                        const val = e.target.value.replace(/[^\d.]/g, '')
                                        if (val === '' || val === '.') {
                                            setRatePerMile('')
                                            setTotalRate(0)
                                        } else {
                                            // Ensure only one decimal point and max 2 decimal places
                                            const parts = val.split('.')
                                            if (parts.length > 2) return // More than one decimal point
                                            if (parts[1] && parts[1].length > 2) return // More than 2 decimal places

                                            const rate = parseFloat(val)
                                            if (!isNaN(rate) && load?.tripLength) {
                                                setRatePerMile(val)
                                                const newTotalRate = Math.round(rate * load.tripLength)
                                                setTotalRate(newTotalRate)
                                            }
                                        }
                                    }}
                                    onBlur={(e) => {
                                        // Format to exactly 2 decimal places when leaving the input
                                        const val = e.target.value
                                        if (val && val !== '.') {
                                            const rate = parseFloat(val)
                                            if (!isNaN(rate)) {
                                                setRatePerMile(rate.toFixed(2))
                                            }
                                        }
                                    }}
                                    slotProps={{
                                        input: {
                                            type: 'number',
                                            step: '0.01',
                                            min: '0',
                                            onKeyDown: (e) => {
                                                if (!/[\d.\b]/.test(e.key) && !['Delete', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                                                    e.preventDefault()
                                                }
                                                // Prevent multiple decimal points
                                                if (e.key === '.' && e.currentTarget.value.includes('.')) {
                                                    e.preventDefault()
                                                }
                                            },
                                        },
                                    }}
                                    sx={{
                                        width: '100%',
                                        fontSize: '0.8rem',
                                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                            display: 'none',
                                        },
                                        '& input[type=number]': {
                                            MozAppearance: 'textfield',
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
                <Box>
                    <Typography level="body-md" sx={{ fontSize: '0.8rem' }}>
                        Message
                    </Typography>
                    <RichTextEditor
                        value={emailType === 'bid' ? bidMessage : infoMessage}
                        onChange={emailType === 'bid' ? setBidMessage : setInfoMessage}
                        minHeight={300}
                        placeholder={' '}
                    />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                {!embedded && (
                    <Button variant="plain" color="neutral" onClick={onClose} disabled={sendingEmail}>
                        Cancel
                    </Button>
                )}
                <Button variant="solid" color="primary" onClick={handleSend} loading={sendingEmail} disabled={!load?.posterInfo?.contact?.email}>
                    Send
                </Button>
            </Box>
            <Snackbar
                variant="soft"
                color={snackbar.color}
                open={snackbar.open}
                onClose={handleSnackbarClose}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                sx={{
                    maxWidth: '400px',
                    width: '100%',
                    '& .MuiSnackbar-content': {
                        fontSize: '1rem',
                        padding: '1rem',
                        justifyContent: 'center',
                    },
                }}
            >
                {snackbar.message}
            </Snackbar>
        </Box>
    )

    if (embedded) {
        return content
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog
                sx={{
                    borderRadius: 'md',
                    boxShadow: 'lg',
                    width: '60%',
                    p: 0,
                }}
            >
                {content}
            </ModalDialog>
        </Modal>
    )
}

export default EmailModal
