/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react'
import { Box, Input, Button, Select, Option, Autocomplete, CircularProgress, Tooltip } from '@mui/joy'
import { allTypes, subTypes } from '../../../../../../../utils/truckEquipmentTypes'
import dayjs from 'dayjs'
import { useWebSocket } from '../../../../../../../context/WebSocketContext'
import StopIcon from '@mui/icons-material/Stop'
import { cityStates } from '../assets'
import { DateRangePicker } from './DateRangePicker'
import spotApi from '../api/api'
import { useGetQuery } from 'hooks/useGetQuery'

interface DateRange {
    startDate: string
    endDate: string
}

interface SearchState {
    origin: { label: string; id: number }
    destination: { label: string; id: number }
    equipmentType: string
    loadType: string
    length: number | string
    weight: number | string
    maxOriginDeadheadMiles: number | string
    maxDestinationDeadheadMiles: number | string
    dateRange: DateRange
}

interface SearchData {
    latestWhen: string | null
    earliestWhen: string | null
    origin: string
    destination: string
    maxOriginDeadheadMiles: number
    maxDestinationDeadheadMiles: number
    equipmentType: string[]
    length?: number
    weight?: number
}

const defaultState: SearchState = {
    origin: { label: '', id: 0 },
    destination: { label: '', id: 0 },
    equipmentType: '',
    loadType: 'BOTH',
    length: '',
    weight: '',
    maxOriginDeadheadMiles: 150,
    maxDestinationDeadheadMiles: 150,
    dateRange: {
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
    },
}

const SearchBar: React.FC<{ threads: any[] }> = ({ threads }) => {
    const threadId = useGetQuery('threadId')
    const { sendMessageToService, stopSearch, threadSearchStatuses, messagesLoading } = useWebSocket()
    const [searchArgs, setSearchArgs] = useState<SearchState>(defaultState)
    const [isSearching, setIsSearching] = useState(false)
    const [isLoadingParams, setIsLoadingParams] = useState(false)
    const prevSearchArgsRef = useRef<SearchState>(defaultState)
    const [searchParamsState, setSearchParamsState] = useState<any>(null)
    const isInitialSetupRef = useRef(false)

    // Reset everything when threadId changes
    useEffect(() => {
        if (threadId) {
            setSearchArgs(defaultState)
            setSearchParamsState(null)
            isInitialSetupRef.current = true
        }
    }, [threadId])

    // Fetch search params after reset
    useEffect(() => {
        const getSearchParams = async () => {
            if (!threadId || !isInitialSetupRef.current) return

            setIsLoadingParams(true)
            // First get current thread data
            const currentThread = threads.find((thread) => thread.threadId === threadId)
            if (!currentThread) {
                setIsLoadingParams(false)
                return
            }

            try {
                const response = await spotApi.get(`/trucks/searchParams/${threadId}`)
                if (response?.data) {
                    setSearchParamsState(response.data)
                } else {
                    // If no search params data, use thread data
                    const today = dayjs().format('YYYY-MM-DD')
                    setSearchArgs({
                        ...defaultState,
                        equipmentType: currentThread.equipmentType || '',
                        length: currentThread.length || '',
                        weight: currentThread.weight || '',
                        maxOriginDeadheadMiles: currentThread.deadHeadOrigin || 150,
                        maxDestinationDeadheadMiles: currentThread.deadHeadDestination || 150,
                        dateRange: {
                            startDate: today,
                            endDate: today,
                        },
                    })
                    isInitialSetupRef.current = false
                }
            } catch (error) {
                // If API call fails, use thread data
                const today = dayjs().format('YYYY-MM-DD')
                setSearchArgs({
                    ...defaultState,
                    equipmentType: currentThread.equipmentType || '',
                    length: currentThread.length || '',
                    weight: currentThread.weight || '',
                    maxOriginDeadheadMiles: currentThread.deadHeadOrigin || 150,
                    maxDestinationDeadheadMiles: currentThread.deadHeadDestination || 150,
                    dateRange: {
                        startDate: today,
                        endDate: today,
                    },
                })
                isInitialSetupRef.current = false
            } finally {
                setIsLoadingParams(false)
            }
        }

        getSearchParams()
    }, [threadId, threads])

    // Set values from search params if they exist
    useEffect(() => {
        if (searchParamsState && isInitialSetupRef.current) {
            const {
                origin,
                destination,
                equipmentType,
                length,
                weight,
                maxOriginDeadheadMiles,
                maxDestinationDeadheadMiles,
                latestAvailability,
                earliestAvailability,
                maximumLengthFeet,
                maximumWeightPounds,
            } = searchParamsState

            // Convert date from MM/DD/YYYY to YYYY-MM-DD format
            const formatDate = (dateStr: string) => {
                if (!dateStr) return dayjs().format('YYYY-MM-DD')
                try {
                    const parts = dateStr.split('/')
                    if (parts.length !== 3) return dayjs().format('YYYY-MM-DD')
                    const [month, day, year] = parts
                    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
                } catch (error) {
                    console.error('Error parsing date:', error)
                    return dayjs().format('YYYY-MM-DD')
                }
            }

            const today = dayjs().format('YYYY-MM-DD')

            setSearchArgs({
                ...defaultState,
                origin: origin
                    ? {
                          label: `${origin.city}, ${origin.stateProv}`,
                          id: 0,
                      }
                    : defaultState.origin,
                destination: destination
                    ? {
                          label: `${destination.city}, ${destination.stateProv}`,
                          id: 0,
                      }
                    : defaultState.destination,
                equipmentType: equipmentType || '',
                length: maximumLengthFeet || length || '',
                weight: maximumWeightPounds || weight || '',
                maxOriginDeadheadMiles: maxOriginDeadheadMiles || 150,
                maxDestinationDeadheadMiles: maxDestinationDeadheadMiles || 150,
                dateRange: {
                    startDate: earliestAvailability ? formatDate(earliestAvailability) : today,
                    endDate: latestAvailability ? formatDate(latestAvailability) : today,
                },
            })
            isInitialSetupRef.current = false
        }
    }, [searchParamsState])

    const isSearchDisabled = () => {
        const requiredFieldsFilled =
            searchArgs.origin?.label &&
            searchArgs.destination?.label &&
            searchArgs.equipmentType &&
            searchArgs.dateRange.startDate &&
            searchArgs.dateRange.endDate

        // If searching, button should always be enabled to allow stopping
        if (isSearching) {
            return false
        }

        // If not searching, check if required fields are filled
        return !requiredFieldsFilled
    }

    const handleSearch = () => {
        if (isSearching) {
            if (threadId) {
                stopSearch(threadId, threadSearchStatuses[threadId]?.searchTaskId)
                setIsSearching(false)
            }
            return
        } else {
            setIsSearching(true)
        }

        const data: SearchData = {
            origin: searchArgs.origin.label,
            destination: searchArgs.destination.label,
            maxOriginDeadheadMiles: Number(searchArgs.maxOriginDeadheadMiles),
            maxDestinationDeadheadMiles: Number(searchArgs.maxDestinationDeadheadMiles),
            equipmentType: searchArgs.equipmentType ? searchArgs.equipmentType.split(',') : [],
            earliestWhen: searchArgs.dateRange.startDate,
            latestWhen: searchArgs.dateRange.endDate,
        }

        if (searchArgs.length) {
            data.length = Number(searchArgs.length)
        }

        if (searchArgs.weight) {
            data.weight = Number(searchArgs.weight)
        }
        prevSearchArgsRef.current = { ...searchArgs }

        if (sendMessageToService && threadId) {
            sendMessageToService(data, threadId)
        }
    }

    // Update search status when threadSearchStatuses changes
    useEffect(() => {
        if (threadId && threadSearchStatuses[threadId]) {
            const status = threadSearchStatuses[threadId].searchStatus
            if (status === 'active') {
                setIsSearching(true)
            } else if (status === 'completed') {
                setIsSearching(true)
            } else if (status === 'stopped') {
                setIsSearching(false)
            }
        } else {
            setIsSearching(false)
        }
    }, [threadSearchStatuses, threadId])

    const handleChange = (prop) => (eventOrValue) => {
        const value = eventOrValue && eventOrValue.target ? eventOrValue.target.value : eventOrValue
        setSearchArgs({ ...searchArgs, [prop]: value })
    }

    const handleAutocompleteChange = (prop) => (_, newValue) => {
        setSearchArgs({ ...searchArgs, [prop]: newValue })
    }

    const handleDateRangeChange = (newValue: DateRange) => {
        setSearchArgs((prev) => ({
            ...prev,
            dateRange: newValue,
        }))
    }

    return (
        <Box sx={{ position: 'relative', width: '100%' }}>
            {isLoadingParams && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 1,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <Box
                sx={{
                    opacity: isLoadingParams ? 0.5 : 1,
                    pointerEvents: isLoadingParams ? 'none' : 'auto',
                }}
            >
                <Tooltip title={isSearching ? 'Stop searching first' : ''} variant="soft" sx={{ color: '#FF2400', fontSize: '1rem', p: 1 }}>
                    <Box
                        sx={(theme) => ({
                            display: 'grid',
                            gap: 2,
                            width: '100%',
                            p: 2,
                            backgroundColor: theme.palette.mode === 'dark' ? '#2b2b2b' : '#f7f7f7',
                            borderRadius: '0.5rem',

                            // Three rows (< 1280px)
                            '@media (max-width: 1279px)': {
                                gridTemplateColumns: 'repeat(100, 1fr)',
                                gap: 1,
                                // First row
                                '& > .origin-group': {
                                    gridColumn: 'span 50',
                                    order: 1,
                                },
                                '& > .destination-group': {
                                    gridColumn: 'span 50',
                                    order: 2,
                                },
                                // Second row
                                '& > .equipment-type': {
                                    gridColumn: 'span 40',
                                    order: 3,
                                },
                                '& > .load-type': {
                                    gridColumn: 'span 30',
                                    order: 4,
                                },
                                '& > .length-input': {
                                    gridColumn: 'span 30',
                                    order: 5,
                                },
                                // Third row
                                '& > .weight-input': {
                                    gridColumn: 'span 30',
                                    order: 6,
                                },
                                '& > .date-range': {
                                    gridColumn: 'span 45',
                                    order: 7,
                                },
                                '& > .search-button': {
                                    gridColumn: 'span 25',
                                    order: 8,
                                },
                            },

                            // Two rows (1280px - 1919px)
                            '@media (min-width: 1280px) and (max-width: 1919px)': {
                                gridTemplateColumns: 'repeat(100, 1fr)',
                                gap: 1,
                                '& > .origin-group': {
                                    gridColumn: 'span 50',
                                    order: 1,
                                },
                                '& > .destination-group': {
                                    gridColumn: 'span 50',
                                    order: 2,
                                },
                                '& > .length-input': {
                                    gridColumn: 'span 14',
                                    order: 3,
                                },
                                '& > .weight-input': {
                                    gridColumn: 'span 14',
                                    order: 4,
                                },
                                '& > .load-type': {
                                    gridColumn: 'span 15',
                                    order: 5,
                                },
                                '& > .equipment-type': {
                                    gridColumn: 'span 22',
                                    order: 6,
                                },
                                '& > .date-range': {
                                    gridColumn: 'span 23',
                                    order: 7,
                                },
                                '& > .search-button': {
                                    gridColumn: 'span 12',
                                    order: 8,
                                },
                            },

                            // One row (≥ 1920px)
                            '@media (min-width: 1920px)': {
                                gridTemplateColumns: 'repeat(100, 1fr)',
                                gap: 1,
                                '& > .origin-group, & > .destination-group': {
                                    gridColumn: 'span 18',
                                },
                                '& > .length-input, & > .weight-input': {
                                    gridColumn: 'span 8',
                                },
                                '& > .equipment-type': {
                                    gridColumn: 'span 14',
                                },
                                '& > .load-type': {
                                    gridColumn: 'span 10',
                                },
                                '& > .date-range': {
                                    gridColumn: 'span 16',
                                },
                                '& > .search-button': {
                                    gridColumn: 'span 8',
                                },
                            },

                            '& > div': {
                                margin: 0,
                                '& .MuiInput-root, & .MuiAutocomplete-root, & .MuiSelect-root, & .MuiButton-root, & .date-range-picker': {
                                    height: '2.5rem',
                                    minHeight: '2.5rem',
                                    maxHeight: '2.5rem',
                                    '--Input-minHeight': '2.5rem',
                                    '--Input-height': '2.5rem',
                                    '--Input-paddingInline': '0.5rem',
                                    '--Input-gap': '0.5rem',
                                    '--Input-decoratorChildHeight': '2.5rem',
                                },
                                '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0,
                                },
                                '& input[type="number"]': {
                                    '-moz-appearance': 'textfield',
                                },
                            },
                        })}
                    >
                        <Box className="origin-group">
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Autocomplete
                                    disabled={isSearching}
                                    placeholder="Origin"
                                    options={cityStates}
                                    value={searchArgs.origin}
                                    onChange={handleAutocompleteChange('origin')}
                                    sx={{ flex: 1 }}
                                />
                                <Input
                                    disabled={isSearching}
                                    type="number"
                                    value={searchArgs.maxOriginDeadheadMiles as number}
                                    onChange={(e) => {
                                        const value = Number(parseInt(e.target.value)) || ''
                                        if (!isNaN(value as number)) {
                                            handleChange('maxOriginDeadheadMiles')({ target: { value } })
                                        } else {
                                            handleChange('maxOriginDeadheadMiles')({ target: { value: '' } })
                                        }
                                    }}
                                    placeholder="DH"
                                    sx={{ width: '4.5rem' }}
                                />
                            </Box>
                        </Box>

                        <Box className="destination-group">
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Autocomplete
                                    disabled={isSearching}
                                    placeholder="Destination"
                                    options={cityStates}
                                    value={searchArgs.destination}
                                    onChange={handleAutocompleteChange('destination')}
                                    sx={{ flex: 1 }}
                                />
                                <Input
                                    disabled={isSearching}
                                    type="number"
                                    value={searchArgs.maxDestinationDeadheadMiles as number}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value) || ''
                                        if (!isNaN(value as number)) {
                                            handleChange('maxDestinationDeadheadMiles')({ target: { value } })
                                        } else {
                                            handleChange('maxDestinationDeadheadMiles')({ target: { value: '' } })
                                        }
                                    }}
                                    placeholder="DH"
                                    sx={{ width: '4.5rem' }}
                                />
                            </Box>
                        </Box>

                        <Box className="length-input">
                            <Input
                                disabled={isSearching}
                                type="number"
                                value={searchArgs.length || ''}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value) || 0
                                    handleChange('length')({ target: { value } })
                                }}
                                placeholder="Length (ft)"
                                sx={{ width: '100%' }}
                            />
                        </Box>

                        <Box className="weight-input">
                            <Input
                                disabled={isSearching}
                                type="number"
                                value={searchArgs.weight || ''}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value) || 0
                                    handleChange('weight')({ target: { value } })
                                }}
                                placeholder="Weight (lbs)"
                                sx={{ width: '100%' }}
                            />
                        </Box>

                        <Box className="equipment-type">
                            <Select
                                disabled={isSearching}
                                multiple
                                value={searchArgs.equipmentType ? searchArgs.equipmentType.split(',') : []}
                                onChange={(_, newValue) => handleChange('equipmentType')(newValue.join(','))}
                                placeholder="Equipment Type"
                                sx={{ width: '100%' }}
                            >
                                {allTypes.map((type) => (
                                    <Option key={type.code} value={type.code}>
                                        {type.name}
                                    </Option>
                                ))}
                                {subTypes.map((type) => (
                                    <Option key={type.code} value={type.code}>
                                        {type.name}
                                    </Option>
                                ))}
                            </Select>
                        </Box>

                        <Box className="load-type">
                            <Select
                                disabled={isSearching}
                                value={searchArgs.loadType}
                                onChange={(_, newValue) => handleChange('loadType')(newValue)}
                                placeholder="Load Type"
                                sx={{ width: '100%' }}
                            >
                                <Option value="BOTH">Full & Partial</Option>
                                <Option value="FULL">Full</Option>
                                <Option value="PARTIAL">Partial</Option>
                            </Select>
                        </Box>

                        <Box className="date-range">
                            <DateRangePicker disabled={isSearching} value={searchArgs.dateRange} onChange={handleDateRangeChange} sx={{ width: '100%' }} />
                        </Box>

                        <Box className="search-button">
                            <Button
                                variant="solid"
                                color="primary"
                                loading={messagesLoading}
                                disabled={isSearchDisabled()}
                                onClick={handleSearch}
                                endDecorator={
                                    isSearching && !messagesLoading ? (
                                        <StopIcon
                                            sx={{
                                                fontSize: '1.25rem',
                                                color: 'red',
                                                animation: 'pulse 1.5s infinite',
                                                '@keyframes pulse': {
                                                    '0%': {
                                                        opacity: 1,
                                                    },
                                                    '50%': {
                                                        opacity: 0.5,
                                                    },
                                                    '100%': {
                                                        opacity: 1,
                                                    },
                                                },
                                            }}
                                        />
                                    ) : null
                                }
                                sx={{
                                    width: '100%',
                                    '& .MuiButton-endDecorator': {
                                        margin: 0,
                                    },
                                }}
                            >
                                {isSearching && !messagesLoading ? 'Stop Searching' : 'Search'}
                            </Button>
                        </Box>
                    </Box>
                </Tooltip>
            </Box>
        </Box>
    )
}

export default SearchBar
