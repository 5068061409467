/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Card, Input, Typography } from '@mui/joy'
import { Send } from '@mui/icons-material'
import { useState, useEffect } from 'react'
import { RichTextEditor } from 'components/common'
import { useSnackbar, ClickAwayListener } from '@mui/base'
import { EmailThreadClient } from '../../../../../api/EmailThreadClient'
import { Email, EmailProcessingResult } from '@numeo/types'
import { getFooterSettings } from 'pages/dashboard/pages/settings/api/EmailSettings'
import { useColorScheme } from '@mui/joy/styles'
import { EmailAccount } from '../../../../../controllers/types'
import { useAuth } from 'hooks/auth'

interface OfferPreviewProps {
    execution: { email: Email; processingResult: EmailProcessingResult }
}

const generateOfferEmail = (execution: any, mode = 'light'): string => {
    const { orders } = execution.processingResult

    // Safely extract customer name

    // Check if there are any orders
    if (!orders || orders.length === 0) {
        return `
            <div style="font-family: Arial, sans-serif; line-height: 1.6;">
                <p>Hi!</p>
                <br>
                <p>Unfortunately, we don't have any available trucks for you at the moment.</p>
                <br>
                <p>Thanks!</p>
            </div>
        `.trim()
    }

    // Define colors based on mode
    const colors = {
        headerBg: mode === 'dark' ? '#2D3748' : '#f2f2f2',
        headerText: mode === 'dark' ? '#FFFFFF' : '#000000',
        borderColor: mode === 'dark' ? '#4A5568' : '#ddd',
        rowBg: mode === 'dark' ? '#1A202C' : '#FFFFFF',
        rowText: mode === 'dark' ? '#FFFFFF' : '#000000',
    }

    // Generate email content with orders in a table
    const ordersTable = `
        <table style="width: 70%; border-collapse: collapse; font-family: Arial, sans-serif;">
            <thead style="text-align: left;">
                <tr style="background-color: ${colors.headerBg}; color: ${colors.headerText};">
                    <th style="border: 1px solid ${colors.borderColor}; padding: 8px;">Origin</th>
                    <th style="border: 1px solid ${colors.borderColor}; padding: 8px;">Destination</th>
                    <th style="border: 1px solid ${colors.borderColor}; padding: 8px;">Rate</th>
                </tr>
            </thead>
            <tbody>
                ${orders
                    .map(
                        (order: any) => `
                        <tr style="background-color: ${colors.rowBg}; color: ${colors.rowText};">
                            <td style="border: 1px solid ${colors.borderColor}; padding: 8px;">${order.destination.label || 'N/A'}</td>
                            <td style="border: 1px solid ${colors.borderColor}; padding: 8px;">${order.origin.label || 'N/A'}</td>
                            <td style="border: 1px solid ${colors.borderColor}; padding: 8px;">$${order.revenue.chargeAmount?.toFixed(2) || '0.00'}</td>
                        </tr>
                    `
                    )
                    .join('')}
            </tbody>
        </table>
    `

    // Return the final email content
    return `
        <div style="font-family: Arial, sans-serif; line-height: 1.6;">
            <p>Hi!</p>
            <br>
            <p>We can have truck${orders.length > 1 ? 's' : ''} for you!</p>
            <br>
            ${ordersTable}
            <br>
            <p>Please confirm to get this booked.</p>
            <br>
            <p>Thanks!</p>
        </div>
    `.trim()
}

let isFooterSet = false

export default function OfferPreview({ execution }: OfferPreviewProps) {
    const { mode } = useColorScheme()
    const [loading, setLoading] = useState(false)
    const [editedSubject, setEditedSubject] = useState(`RE: ${execution.email.emailData.object.subject}`)
    const [editedBody, setEditedBody] = useState(generateOfferEmail(execution, mode))
    const [open, setOpen] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [toastColor, setToastColor] = useState<'success' | 'danger'>('success')
    const [offerSent, setOfferSent] = useState(execution.processingResult?.offerSent)
    const [connectedAccounts, setConnectedAccounts] = useState<EmailAccount[]>([])

    const { application } = useAuth()

    const handleClose = () => {
        setOpen(false)
    }

    const { getRootProps, onClickAway } = useSnackbar({
        onClose: handleClose,
        open,
        autoHideDuration: 5000,
    })

    const showToast = (message: string, color: 'success' | 'danger') => {
        setToastMessage(message)
        setToastColor(color)
        setOpen(true)
    }

    const fetchFooter = async () => {
        try {
            const emailFooter = await getFooterSettings()
            const footer = emailFooter.data?.closing || ''
            setEditedBody((prev) => `${prev}${footer}`)
        } catch (error) {
            console.error('Error fetching footer:', error)
        }
    }

    useEffect(() => {
        if (!isFooterSet) {
            console.log('fetching footer')
            fetchFooter()
            isFooterSet = true
        } else {
            console.log('footer already set')
        }

        // Map application emails to EmailAccount type
        const mappedAccounts: EmailAccount[] = (application?.integrations.nylas?.emails || []).map((email) => ({
            _id: email.grantId, // Using grantId as _id since it's unique
            email: email.email,
            provider: email.provider,
            grantId: email.grantId,
            status: 'valid', // Assuming connected emails are valid
        }))
        setConnectedAccounts(mappedAccounts)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isEmailConnected = (email: string) => {
        return connectedAccounts.some((account) => account.email.toLowerCase() === email.toLowerCase())
    }

    useEffect(() => {
        setEditedSubject(`RE: ${execution.email.emailData.object.subject}`)
        setEditedBody(generateOfferEmail(execution, mode))
    }, [execution, mode])

    const sendOfferEmail = async () => {
        setLoading(true)
        try {
            const payload = {
                subject: editedSubject,
                body: editedBody,
                threadId: execution.email.emailData.object.thread_id,
                processedResId: execution.processingResult._id,
            }
            const response = await EmailThreadClient.sendOfferEmail(payload as any)

            if (response.data?.data?.isOfferSent) {
                setOfferSent(true)
            }

            showToast('Sent to Email successfully', 'success')
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || error?.message || 'Failed to send to Email'
            showToast(errorMessage, 'danger')
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Card variant="outlined" sx={{ p: 3, mt: 2 }}>
                <Typography level="title-lg" startDecorator={<Send />} sx={{ mb: 3 }}>
                    Generated Offer
                </Typography>

                <Box sx={{ mb: 3 }}>
                    <Typography level="title-sm" sx={{ mb: 1, color: 'neutral.500' }}>
                        Subject
                    </Typography>
                    <Input
                        value={editedSubject}
                        disabled={offerSent}
                        onChange={(e) => setEditedSubject(e.target.value)}
                        variant="soft"
                        sx={{
                            mb: 1,
                            px: 2.5,
                            py: 1,
                            '--Input-minHeight': '45px',
                            '--Input-paddingInline': '1rem',
                            bgcolor: 'background.level1',
                        }}
                    />
                </Box>

                <Box>
                    <Typography level="title-sm" sx={{ mb: 1, color: 'neutral.500' }}>
                        Message
                    </Typography>
                    <RichTextEditor readOnly={offerSent} value={editedBody} onChange={setEditedBody} minHeight="auto" showToolbar />
                </Box>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    {!offerSent ? (
                        <Button
                            loading={loading}
                            disabled={execution.processingResult?.offerSent || !isEmailConnected(execution.email.emailData?.object?.to[0].email)}
                            onClick={sendOfferEmail}
                            startDecorator={<Send />}
                            size="lg"
                        >
                            {!isEmailConnected(execution.email.emailData?.object?.to[0].email) ? 'Email account not connected' : 'Send'}
                        </Button>
                    ) : (
                        <Typography>Email will be sent soon</Typography>
                    )}
                </Box>
            </Card>

            {open && (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div
                        {...getRootProps()}
                        style={{
                            position: 'fixed',
                            bottom: 16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: toastColor === 'danger' ? '#d32f2f' : '#2e7d32',
                            color: 'white',
                            padding: '6px 16px',
                            borderRadius: 4,
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            zIndex: 1400,
                        }}
                    >
                        {toastMessage}
                        <button
                            onClick={handleClose}
                            style={{
                                marginLeft: 8,
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                cursor: 'pointer',
                                padding: '4px',
                            }}
                        >
                            ✕
                        </button>
                    </div>
                </ClickAwayListener>
            )}
        </>
    )
}
