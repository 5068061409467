import { Navigate, Route, Routes } from 'react-router-dom'
import { ForgotPasswordPage, LoginPage, RegisterPage, ResetPasswordPage } from './pages'
import { useAuth } from 'hooks/auth'
import PrivacyPolicyPage from './pages/privacy-policy/PrivacyPolicy'
import TermsPage from './pages/terms/Terms'

export default function AuthRoutes() {
    const { isAuthenticated } = useAuth()

    if (isAuthenticated) {
        return <Navigate to="/" />
    }

    return (
        <Routes>
            <Route path="sign-in" element={<LoginPage />} />
            <Route path="sign-up" element={<RegisterPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="terms" element={<TermsPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}
