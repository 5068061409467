import { ApplicationEmailSettings, ApplicationEmailFooterSettings } from '@numeo/types'
import axios from 'axios'

export const saveEmailSettings = async (emailSettings: ApplicationEmailSettings) => {
    try {
        const response = await axios.post('/email/email-settings', emailSettings)
        return response
    } catch (error) {
        console.error('Error saving email settings:', error)
        throw error
    }
}

export const getEmailSettings = async () => {
    try {
        // Perform GET request to fetch email settings
        const response = await axios.get('/email/email-settings')

        // Return the response data
        return response.data
    } catch (error) {
        console.error('Error fetching email settings:', error)
        throw error // Optionally, throw the error if you want to handle it in the calling function
    }
}

// Save email footer settings to the server
export const saveFooterSettings = async (emailFooterSettings: ApplicationEmailFooterSettings) => {
    try {
        const response = await axios.post('/email/footer-settings', emailFooterSettings)
        return response
    } catch (error) {
        console.error('Error saving footer settings:', error)
        throw error
    }
}

// Fetch email footer settings
export const getFooterSettings = async () => {
    try {
        const response = await axios.get('/email/footer-settings')
        return response
    } catch (error) {
        console.error('Error fetching footer settings:', error)
        throw error
    }
}
