/* eslint-disable @typescript-eslint/no-explicit-any */
import { Sheet, Box, useColorScheme, Divider } from '@mui/joy'
import { EmailHeader, EmailAttachments, EmailDetails } from './components'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import DOMPurify from 'dompurify'
import { HTML_TEMPLATE, SANITIZE_OPTIONS } from './const'
import { Email, EmailProcessingResult } from '@numeo/types'

interface EmailCardProps {
    execution: { email: Email; processingResult?: EmailProcessingResult }
    isReply?: boolean
    isFromMainSender: boolean
}

const EmailCard: React.FC<EmailCardProps> = ({ execution, isReply, isFromMainSender }) => {
    const emailData = execution?.email
    const [showDetails, setShowDetails] = useState(false)
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const { mode } = useColorScheme()

    const handleToggleDetails = useCallback(() => {
        setShowDetails((prev) => !prev)
    }, [])

    const setupIframeContent = useCallback(
        (iframe: HTMLIFrameElement, content: string) => {
            const doc = iframe.contentDocument || iframe.contentWindow?.document
            if (!doc) return

            const cleanContent = DOMPurify?.sanitize(content, SANITIZE_OPTIONS)

            doc.open()
            doc.write(HTML_TEMPLATE(cleanContent, mode))
            doc.close()

            // Make external links open in new tab
            doc.querySelectorAll('a').forEach((link) => {
                if (link.host !== window.location.host) {
                    link.setAttribute('target', '_blank')
                    link.setAttribute('rel', 'noopener noreferrer')
                }
            })

            return doc.body
        },
        [mode]
    ) // Add mode as dependency

    useEffect(() => {
        if (!iframeRef.current || !emailData?.emailData.object.body) return

        const iframe = iframeRef.current
        const body = setupIframeContent(iframe, emailData.emailData.object.body)

        if (!body) return

        // Handle iframe resizing with debouncing
        let rafId: number
        const resizeObserver = new ResizeObserver((entries) => {
            // Cancel any pending animation frame
            if (rafId) {
                cancelAnimationFrame(rafId)
            }

            // Schedule a new resize update
            rafId = requestAnimationFrame(() => {
                if (!iframe.isConnected) return

                const height = Math.ceil(entries[0].contentRect.height)
                if (height > 0) {
                    iframe.style.height = `${height + 40}px`
                }
            })
        })

        resizeObserver.observe(body)

        return () => {
            resizeObserver.disconnect()
            if (rafId) {
                cancelAnimationFrame(rafId)
            }
        }
    }, [emailData?.emailData.object.body, setupIframeContent])

    if (!emailData) return null

    return (
        <Sheet
            variant="outlined"
            sx={{
                borderRadius: 'sm',
                p: 2,
                mb: 2,
                ml: isReply ? (!isFromMainSender ? 4 : 'auto') : 0,
                mr: isReply ? (!isFromMainSender ? 0 : 4) : 0,
                width: isReply ? '85%' : '100%',
            }}
        >
            <EmailHeader emailData={emailData} isReply={isReply} onToggleDetails={handleToggleDetails} showDetails={showDetails} />

            <Divider sx={{ mt: 2 }} />

            {(!isReply || showDetails) && (
                <>
                    <EmailDetails emailData={emailData} />
                    <Divider />
                </>
            )}
            <Box sx={{ mt: 2, mb: 2 }}>
                <iframe
                    ref={iframeRef}
                    sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                    style={{
                        width: '100%',
                        border: 'none',
                        height: '100%',
                    }}
                    title={`Email: ${emailData.emailData.object.subject || 'No subject'}`}
                />
            </Box>

            <EmailAttachments attachments={emailData.emailData.object.attachments || []} />
        </Sheet>
    )
}

export default EmailCard
