export const openSidebar = () => {
    if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden'
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1')
    }
}

export const closeSidebar = () => {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn')
        document.body.style.removeProperty('overflow')
    }
}

export const toggleSidebar = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window.getComputedStyle(document.documentElement).getPropertyValue('--SideNavigation-slideIn')
        if (slideIn) {
            closeSidebar()
        } else {
            openSidebar()
        }
    }
}

export const formatDate = (date: Date) => {
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    // Format date as MM/DD/YYYY
    return `${month}/${day}/${date.getFullYear()}`
}

export const openMessagesPane = () => {
    if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden'
        document.documentElement.style.setProperty('--MessagesPane-slideIn', '1')
    }
}

export const closeMessagesPane = () => {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty('--MessagesPane-slideIn')
        document.body.style.removeProperty('overflow')
    }
}

export const toggleMessagesPane = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window.getComputedStyle(document.documentElement).getPropertyValue('--MessagesPane-slideIn')
        if (slideIn) {
            closeMessagesPane()
        } else {
            openMessagesPane()
        }
    }
}

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname
