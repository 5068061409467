import { Box, Typography, useTheme } from '@mui/joy'
import React, { useEffect, useRef, useState } from 'react'
import ChatThreads from './ChatThreads'
import SettingModal from './SettingModal'
import LoadsTable from './LoadsTable'
import SearchBar from './SearchBar'
import { useGetQuery } from 'hooks/useGetQuery'
import { useWebSocket } from 'context/WebSocketContext'
import callCenterApi from '../../call-center/api'
import { DatabaseLoad } from '@numeo/types/lib/spot-finder'

interface Thread {
    threadId: string
    // Add other thread properties as needed
}

const TruckManagementUI: React.FC = () => {
    const theme = useTheme()
    const threadId = useGetQuery('threadId')
    const messagesEndRef = useRef<HTMLDivElement | null>(null)
    const [showAddModal, setShowAddModal] = useState(false)
    const [editingThread, setEditingThread] = useState<string | null>(null)
    const [threads, setThreads] = useState<Thread[]>([])
    const { currentLoadsTableData } = useWebSocket()
    const [callingLoadId, setCallingLoadId] = useState<string | null>(null)

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
        }
    }

    const handlePhoneClick = async (load: DatabaseLoad, phoneNumber?: string) => {
        try {
            setCallingLoadId(load.matchId)
            await callCenterApi.makeCall(
                // TODO: add phone number of the company
                // load.posterInfo.contact.phoneNumber!,
                phoneNumber!,
                load._id,
                load.origin!.city,
                load.destination.city,
                load.earliestAvailability
            )
        } catch (error) {
            console.error('Error sending phone call:', error)
            alert(`Failed to make phone call: ${error}`)
        } finally {
            setCallingLoadId(null)
        }
    }

    useEffect(() => {
        scrollToBottom()
    }, [])

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                height: '100%',
                width: '100%',
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                sx={{
                    backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
                    height: '100%',
                    width: '100%',
                    padding: 1,
                }}
            >
                <ChatThreads
                    setShowAddModal={setShowAddModal}
                    showAddModal={showAddModal}
                    scrollToBottom={scrollToBottom}
                    setEditingThread={setEditingThread}
                    editingThread={editingThread}
                    onThreadsUpdate={setThreads}
                />
                <div ref={messagesEndRef} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            zIndex: 0,
                        }}
                    >
                        <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'start' }}>
                            {threadId ? (
                                <>
                                    <SearchBar threads={threads} />
                                    <LoadsTable
                                        loads={currentLoadsTableData}
                                        sortOption={'ageNewest'}
                                        setCallingLoadId={setCallingLoadId}
                                        callingLoadId={callingLoadId}
                                        handlePhoneClick={handlePhoneClick}
                                    />
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <Typography level="h3" color="neutral">
                                        Please select a truck to view available loads
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>

            <SettingModal openSettingsModal={false} handleCloseSettings={() => {}} setRadius={() => {}} radius={200} handleSaveSettings={() => {}} />
        </Box>
    )
}

export default TruckManagementUI
