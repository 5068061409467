import React, { useState, useRef, useEffect } from 'react'
import { format, addMonths, subMonths, startOfMonth as dateStartOfMonth, isSameMonth, isSameDay, isBefore, startOfDay, getDaysInMonth, getDay } from 'date-fns'
import { isWithinInterval } from 'date-fns/isWithinInterval'
import { Box, Typography, IconButton, Input, Button } from '@mui/joy'
import type { SxProps } from '@mui/joy/styles/types'
import { Popover } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTheme } from '@mui/joy/styles'

interface DateRange {
    startDate: string
    endDate: string
}

interface DateRangePickerProps {
    value: DateRange
    onChange: (value: DateRange) => void
    sx?: SxProps
}

export const DateRangePicker = ({ value, onChange, sx }: DateRangePickerProps) => {
    const theme = useTheme()
    const anchorRef = useRef<HTMLDivElement>(null)
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const [currentDate, setCurrentDate] = useState(new Date())
    const [viewMode, setViewMode] = useState<'date' | 'month' | 'year'>('date')
    const [yearViewStart, setYearViewStart] = useState(new Date().getFullYear() - 10)
    const [isInitialized, setIsInitialized] = useState(false)

    const today = startOfDay(new Date())

    useEffect(() => {
        if (!isInitialized && (!value.startDate || !value.endDate)) {
            const todayStr = format(today, 'MM/dd/yyyy')
            onChange({
                startDate: todayStr,
                endDate: todayStr,
            })
            setIsInitialized(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialized, value.startDate, value.endDate])

    const isDateDisabled = (date: Date) => {
        const startOfDate = startOfDay(date)
        return isBefore(startOfDate, today)
    }

    const handleClick = () => {
        if (anchorRef.current) {
            setAnchorEl(anchorRef.current)
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleDateClick = (date: Date) => {
        if (isDateDisabled(date)) return

        const dateStr = format(startOfDay(date), 'MM/dd/yyyy')
        if (!value.startDate || (value.startDate && value.endDate)) {
            onChange({
                startDate: dateStr,
                endDate: '',
            })
        } else {
            const startDate = startOfDay(new Date(value.startDate))
            const selectedDate = startOfDay(date)

            if (isBefore(selectedDate, startDate)) {
                onChange({
                    startDate: dateStr,
                    endDate: value.startDate,
                })
            } else {
                onChange({
                    startDate: value.startDate,
                    endDate: dateStr,
                })
                handleClose()
            }
        }
    }

    const handleYearSelect = (year: number) => {
        setCurrentDate(new Date(year, currentDate.getMonth()))
        setViewMode('month')
    }

    const handleMonthClick = (month: number) => {
        setCurrentDate(new Date(currentDate.getFullYear(), month))
        setViewMode('date')
    }

    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

    const renderYearView = () => {
        const currentYear = new Date().getFullYear()
        const years = Array.from({ length: 24 }, (_, i) => yearViewStart + i)

        return (
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <IconButton size="sm" onClick={() => setYearViewStart((prev) => prev - 24)}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Typography
                        level="body-md"
                        fontWeight={600}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                        }}
                    >
                        {`${yearViewStart} – ${yearViewStart + 23}`}
                        <ExpandMoreIcon sx={{ fontSize: '1.25rem' }} />
                    </Typography>
                    <IconButton size="sm" onClick={() => setYearViewStart((prev) => prev + 24)}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        gap: 1,
                        p: 2,
                    }}
                >
                    {years.map((year) => {
                        const isPastYear = year < currentYear

                        return (
                            <Button
                                key={year}
                                variant={currentDate.getFullYear() === year ? 'solid' : 'plain'}
                                color={currentDate.getFullYear() === year ? 'primary' : 'neutral'}
                                disabled={isPastYear}
                                onClick={() => handleYearSelect(year)}
                                sx={{
                                    py: 1.5,
                                    fontSize: '0.875rem',
                                    fontWeight: currentDate.getFullYear() === year ? 600 : 400,
                                    color: (theme) => {
                                        if (isPastYear)
                                            return theme.palette.mode === 'dark'
                                                ? 'rgba(255, 255, 255, 0.7)' // Lighter text in dark mode
                                                : 'rgba(0, 0, 0, 0.3)'
                                        return 'inherit'
                                    },
                                    backgroundColor: (theme) => {
                                        if (isPastYear && theme.palette.mode === 'dark') {
                                            return 'rgba(255, 255, 255, 0.08)' // Light grey background for disabled in dark mode
                                        }
                                        return 'transparent'
                                    },
                                    '&.Mui-disabled': {
                                        opacity: 1,
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? 'rgba(255, 255, 255, 0.7)' // Lighter text in dark mode
                                                : 'rgba(0, 0, 0, 0.3)',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? 'rgba(255, 255, 255, 0.08)' // Light grey background in dark mode
                                                : 'transparent',
                                        cursor: 'not-allowed',
                                    },
                                    '&:hover:not(.Mui-disabled)': {
                                        bgcolor: (theme) =>
                                            currentDate.getFullYear() === year
                                                ? 'primary.600'
                                                : theme.palette.mode === 'dark'
                                                  ? 'rgba(255, 255, 255, 0.1)'
                                                  : 'rgba(0, 0, 0, 0.1)',
                                    },
                                }}
                            >
                                {year}
                            </Button>
                        )
                    })}
                </Box>
            </Box>
        )
    }

    const renderMonthView = () => (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <IconButton size="sm" onClick={() => handleYearChange('prev')} disabled={currentDate.getFullYear() <= new Date().getFullYear()}>
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <Box
                    onClick={() => setViewMode('year')}
                    sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        '&:hover': {
                            color: 'primary.main',
                        },
                    }}
                >
                    <Typography level="body-md" fontWeight={600}>
                        {currentDate.getFullYear()}
                    </Typography>
                    <ExpandMoreIcon sx={{ fontSize: '1.25rem' }} />
                </Box>
                <IconButton size="sm" onClick={() => handleYearChange('next')}>
                    <KeyboardArrowRightIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 1,
                    p: 2,
                }}
            >
                {months.map((month, index) => {
                    const isDisabled = currentDate.getFullYear() === new Date().getFullYear() && index < new Date().getMonth()

                    return (
                        <Button
                            key={month}
                            variant={currentDate.getMonth() === index ? 'solid' : 'plain'}
                            color={currentDate.getMonth() === index ? 'primary' : 'neutral'}
                            disabled={isDisabled}
                            onClick={() => handleMonthClick(index)}
                            sx={{
                                py: 1.5,
                                fontSize: '0.875rem',
                                fontWeight: currentDate.getMonth() === index ? 600 : 400,
                                color: (theme) => {
                                    if (isDisabled)
                                        return theme.palette.mode === 'dark'
                                            ? 'rgba(255, 255, 255, 0.7)' // Lighter text in dark mode
                                            : 'rgba(0, 0, 0, 0.3)'
                                    return 'inherit'
                                },
                                backgroundColor: (theme) => {
                                    if (isDisabled && theme.palette.mode === 'dark') {
                                        return 'rgba(255, 255, 255, 0.08)' // Light grey background for disabled in dark mode
                                    }
                                    return 'transparent'
                                },
                                '&.Mui-disabled': {
                                    opacity: 1,
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? 'rgba(255, 255, 255, 0.7)' // Lighter text in dark mode
                                            : 'rgba(0, 0, 0, 0.3)',
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? 'rgba(255, 255, 255, 0.08)' // Light grey background in dark mode
                                            : 'transparent',
                                    cursor: 'not-allowed',
                                },
                                '&:hover:not(.Mui-disabled)': {
                                    bgcolor: (theme) =>
                                        currentDate.getMonth() === index
                                            ? 'primary.600'
                                            : theme.palette.mode === 'dark'
                                              ? 'rgba(255, 255, 255, 0.1)'
                                              : 'rgba(0, 0, 0, 0.1)',
                                },
                            }}
                        >
                            {month}
                        </Button>
                    )
                })}
            </Box>
        </Box>
    )

    const renderDateView = () => {
        const monthStart = dateStartOfMonth(currentDate)
        const daysInMonth = getDaysInMonth(currentDate)
        const firstDayOfMonth = getDay(monthStart)

        return (
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
                    <IconButton size="sm" onClick={() => handleDateChange('prev')} disabled={isSameMonth(currentDate, today)}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Box
                        onClick={() => setViewMode('month')}
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            '&:hover': {
                                color: 'primary.main',
                            },
                        }}
                    >
                        <Typography level="body-sm" fontWeight={600}>
                            {format(currentDate, 'MMMM yyyy')}
                        </Typography>
                        <ExpandMoreIcon sx={{ fontSize: '1.25rem' }} />
                    </Box>
                    <IconButton size="sm" onClick={() => handleDateChange('next')}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: 1, p: 2 }}>
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                        <Typography
                            key={day}
                            level="body-xs"
                            sx={{
                                textAlign: 'center',
                                fontWeight: 600,
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'rgba(255, 255, 255, 0.9)' // Very bright in dark mode
                                        : 'rgba(0, 0, 0, 0.7)',
                                letterSpacing: '0.02em', // Slightly improved readability
                            }}
                        >
                            {day}
                        </Typography>
                    ))}
                    {Array.from({ length: firstDayOfMonth }, (_, i) => (
                        <Box key={`empty-${i}`} />
                    ))}
                    {Array.from({ length: daysInMonth }, (_, i) => {
                        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i + 1)
                        const isSelected =
                            value.startDate &&
                            value.endDate &&
                            isWithinInterval(date, {
                                start: new Date(value.startDate),
                                end: new Date(value.endDate),
                            })
                        const isStart = value.startDate && isSameDay(date, new Date(value.startDate))
                        const isEnd = value.endDate && isSameDay(date, new Date(value.endDate))
                        const isPast = isBefore(date, today)
                        const isInRange =
                            value.startDate &&
                            value.endDate &&
                            isWithinInterval(date, {
                                start: new Date(value.startDate),
                                end: new Date(value.endDate),
                            }) &&
                            !isStart &&
                            !isEnd

                        return (
                            <Button
                                key={i}
                                variant={isSelected ? 'solid' : 'plain'}
                                color={isSelected ? 'primary' : 'neutral'}
                                disabled={isPast}
                                onClick={() => handleDateClick(date)}
                                sx={{
                                    aspectRatio: '1',
                                    p: 0,
                                    fontWeight: isSelected ? 600 : 400,
                                    backgroundColor: (theme) => {
                                        if (isStart || isEnd) return 'primary.500'
                                        if (isInRange)
                                            return theme.palette.mode === 'dark'
                                                ? 'rgba(66, 165, 245, 0.15)' // lighter blue in dark mode
                                                : 'rgba(66, 165, 245, 0.1)' // light blue in light mode
                                        if (isPast && theme.palette.mode === 'dark') {
                                            return 'rgba(255, 255, 255, 0.08)' // Light grey background for disabled in dark mode
                                        }
                                        return 'transparent'
                                    },
                                    color: (theme) => {
                                        if (isPast)
                                            return theme.palette.mode === 'dark'
                                                ? 'rgba(255, 255, 255, 0.7)' // Lighter text in dark mode
                                                : 'rgba(0, 0, 0, 0.3)'
                                        if (isStart || isEnd) return 'white'
                                        return 'inherit'
                                    },
                                    '&.Mui-disabled': {
                                        opacity: 1,
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? 'rgba(255, 255, 255, 0.7)' // Lighter text in dark mode
                                                : 'rgba(0, 0, 0, 0.3)',
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? 'rgba(255, 255, 255, 0.08)' // Light grey background in dark mode
                                                : 'transparent',
                                        cursor: 'not-allowed',
                                    },
                                    '&:hover:not(.Mui-disabled)': {
                                        backgroundColor: (theme) => {
                                            if (isStart || isEnd) return 'primary.600'
                                            if (isInRange)
                                                return theme.palette.mode === 'dark'
                                                    ? 'rgba(66, 165, 245, 0.25)' // darker on hover
                                                    : 'rgba(66, 165, 245, 0.2)'
                                            return theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
                                        },
                                    },
                                }}
                            >
                                {i + 1}
                            </Button>
                        )
                    })}
                </Box>
            </Box>
        )
    }

    const handleDateChange = (direction: 'prev' | 'next') => {
        if (direction === 'prev') {
            setCurrentDate(subMonths(currentDate, 1))
        } else {
            setCurrentDate(addMonths(currentDate, 1))
        }
    }

    const handleYearChange = (direction: 'prev' | 'next') => {
        if (direction === 'prev') {
            setCurrentDate(new Date(currentDate.getFullYear() - 1, currentDate.getMonth()))
        } else {
            setCurrentDate(new Date(currentDate.getFullYear() + 1, currentDate.getMonth()))
        }
    }

    const formatDateRange = (dateRange: DateRange) => {
        if (dateRange.startDate && dateRange.endDate) {
            return `${format(new Date(dateRange.startDate), 'MM/dd/yyyy')} - ${format(new Date(dateRange.endDate), 'MM/dd/yyyy')}`
        } else if (dateRange.startDate) {
            return format(new Date(dateRange.startDate), 'MM/dd/yyyy')
        } else {
            return ''
        }
    }

    return (
        <Box sx={sx}>
            <Box
                ref={anchorRef}
                onClick={handleClick}
                sx={{
                    cursor: 'pointer',
                    width: '100%',
                }}
            >
                <Input
                    startDecorator={<CalendarMonthIcon />}
                    value={formatDateRange(value)}
                    readOnly
                    placeholder="Select date range"
                    sx={{
                        width: '100%',
                        cursor: 'pointer',
                    }}
                />
            </Box>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            bgcolor: theme.palette.mode === 'dark' ? 'common.black' : 'common.white',
                            color: theme.palette.mode === 'dark' ? 'common.white' : 'common.black',
                            borderRadius: 'md',
                            boxShadow: 'lg',
                            border: '1px solid',
                            borderColor: (theme) => (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'),
                            '& .MuiIconButton-root': {
                                color: 'inherit',
                                '&:hover': {
                                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'),
                                },
                                '&.Mui-disabled': {
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? 'rgba(255, 255, 255, 0.3)' // Lighter text in dark mode
                                            : 'rgba(0, 0, 0, 0.3)',
                                },
                            },
                            '& .MuiButton-root': {
                                color: 'inherit',
                                '&:hover:not(.Mui-disabled)': {
                                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'),
                                },
                                '&.Mui-disabled': {
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? 'rgba(255, 255, 255, 0.3)' // Lighter text in dark mode
                                            : 'rgba(0, 0, 0, 0.3)',
                                    opacity: 1,
                                },
                                '&.MuiButton-solidPrimary': {
                                    bgcolor: 'primary.main',
                                    color: '#fff',
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    },
                                },
                            },
                            '& .MuiTypography-root': {
                                color: 'inherit',
                                '&.day-label': {
                                    color: (theme) => (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'),
                                },
                            },
                            '& .MuiDivider-root': {
                                borderColor: (theme) => (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'),
                            },
                        },
                    },
                }}
            >
                <Box sx={{ width: 320 }}>
                    {viewMode === 'date' && renderDateView()}
                    {viewMode === 'month' && renderMonthView()}
                    {viewMode === 'year' && renderYearView()}
                </Box>
            </Popover>
        </Box>
    )
}
