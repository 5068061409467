import axios, { AxiosError } from 'axios'
import { ApiError, AuthUrlResponse, ConnectionStatusResponse, EmailAccount, TokenExchangeResponse } from './types'

export const NylasEmailClient = {
    async getAccounts(): Promise<EmailAccount[]> {
        try {
            const response = await axios.get(`/email/accounts`)
            return response.data
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to fetch email accounts', error.response?.status)
            }
            throw error
        }
    },

    async getAuthUrl(redirectUrl: string): Promise<AuthUrlResponse> {
        try {
            const response = await axios.get(`/email/auth/nylas/auth-url?redirectUrl=${redirectUrl}`)
            return response.data
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to get auth URL', error.response?.status)
            }
            throw error
        }
    },

    async exchangeToken(code: string): Promise<TokenExchangeResponse> {
        try {
            const response = await axios.post(`/email/auth/nylas/exchange-token`, { code })
            return response.data
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to exchange token', error.response?.status)
            }
            throw error
        }
    },

    async getConnectionStatus(grantId: string): Promise<ConnectionStatusResponse> {
        try {
            const response = await axios.get(`/email/auth/nylas/connection-status/${grantId}`)
            return response.data
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to get connection status', error.response?.status)
            }
            throw error
        }
    },

    async disconnect(grantId: string): Promise<void> {
        try {
            await axios.delete(`/email/auth/nylas/disconnect/${grantId}`)
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to disconnect email account', error.response?.status)
            }
            throw error
        }
    },

    async processOAuthCode(): Promise<TokenExchangeResponse | undefined> {
        try {
            const searchParams = new URLSearchParams(window.location.search)
            const code = searchParams.get('code')

            if (!code) {
                return
                // throw new ApiError('No authorization code found in URL', 400)
            }

            const response = await axios.post(`/email/auth/nylas/exchange-token`, { code })
            return response.data
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to exchange token', error.response?.status)
            }
            throw error
        }
    },
}
