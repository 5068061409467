import React, { useEffect, useState } from 'react'
import { Modal, ModalDialog, Typography, DialogTitle, DialogContent, Button } from '@mui/joy'

interface PrivacyPolicyModalProps {
    open: boolean
    onClose: () => void
}

export const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ open, onClose }) => {
    const [policyContent, setPolicyContent] = useState<string>('')

    useEffect(() => {
        if (open) {
            fetch('/privacy-policy.txt')
                .then((response) => response.text())
                .then((text) => setPolicyContent(text))
                .catch((error) => console.error('Error loading privacy policy:', error))
        }
    }, [open])

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog sx={{ maxWidth: 800, maxHeight: '90vh', overflowY: 'auto' }}>
                <DialogTitle>Privacy Policy</DialogTitle>
                <DialogContent>
                    <Typography
                        component="div"
                        sx={{
                            whiteSpace: 'pre-wrap',
                            '& h2, & h3': {
                                marginTop: 2,
                                marginBottom: 1,
                                fontWeight: 'bold',
                            },
                        }}
                    >
                        {policyContent}
                    </Typography>
                </DialogContent>
                <Button onClick={onClose} sx={{ mt: 2 }}>
                    Close
                </Button>
            </ModalDialog>
        </Modal>
    )
}
