export enum CallType {
    DISPATCHER = 'dispatcher',
    CUSTOMER_SERVICE = 'customer_service',
    SALES = 'sales',
    SUPPORT = 'support',
}

export enum CallStatus {
    INITIATING = 'initiating',
    CONNECTED = 'connected',
    ENDED = 'ended',
    ERROR = 'error',
}

export interface Participant {
    id: string
    name: string
    phoneNumber: string
    role: 'agent' | 'customer' | 'dispatcher'
    joinTime: Date
}

export interface CallIntent {
    name: string
    confidence: number
    timestamp: Date
    metadata?: Record<string, any>
}

export interface TranscriptionSegment {
    id: string
    speakerId: string
    text: string
    timestamp: Date
    confidence: number
    corrected?: boolean
    metadata?: {
        interim?: boolean
    }
    intent?: {
        name: string
        confidence: number
        metadata?: Record<string, any>
    }
    entities?: Array<{
        type: string
        value: string
        confidence: number
    }>
}

export interface BaseCall {
    id: string
    type: CallType
    status: CallStatus
    startTime: Date
    duration: number
    participants: Participant[]
    transcription: TranscriptionSegment[]
    metadata: Record<string, any>
    _lastUpdate?: number
}

export interface DispatcherCall extends BaseCall {
    type: CallType.DISPATCHER
    dispatcherMetadata: {
        priority: 'high' | 'medium' | 'low'
        category: string
        intent: Array<{
            name: string
            confidence: number
            timestamp: Date
            metadata?: Record<string, any>
        }>
    }
}

export interface CallState {
    calls: Record<string, BaseCall>
    selectedCallId: string | null
    filters: {
        types: string[]
        status: CallStatus[]
        priority?: 'high' | 'medium' | 'low'
        searchTerm: string
    }
    searchStatus: string
    searchTaskId: string
}

export interface CallStateActions {
    updateCall: (callId: string, updates: Partial<BaseCall>) => void
    removeCall: (callId: string) => void
    setSelectedCall: (callId: string | null) => void
    updateFilters: (filters: Partial<CallState['filters']>) => void
}

export interface CallTranscript {
    role: 'assistant' | 'user'
    content: string[]
}

export enum CallCenterEventActions {
    CALL_INITIATED = 'call.dispatcher.initiated',
    CALL_UPDATED = 'call.dispatcher.updated',
    CALL_ENDED = 'call.dispatcher.ended',
    GET_ACTIVE_CALLS = 'call.dispatcher.getActiveCalls',
}

export interface ActiveCall {
    callId: string
    transcripts: CallTranscript[]
    from: string
    to: string
    startedAt: number
    status?: 'ended' | 'active'
}

export interface WSEvents {
    [CallCenterEventActions.CALL_INITIATED]: ActiveCall
    [CallCenterEventActions.CALL_UPDATED]: ActiveCall
    [CallCenterEventActions.CALL_ENDED]: {
        callId: string
    }
    [CallCenterEventActions.GET_ACTIVE_CALLS]: ActiveCall[]

    'call.connected': {
        callId: string
        timestamp: Date
    }
    'call.state.update': {
        callId: string
        changes: Array<{
            field: string
            oldValue: any
            newValue: any
            timestamp: Date
        }>
    }
    'participant.joined': {
        callId: string
        participant: Participant
        timestamp: Date
    }
    'participant.left': {
        callId: string
        participantId: string
        timestamp: Date
    }
    'transcription.segment': {
        callId: string
        segment: TranscriptionSegment
    }
    'transcription.correction': {
        callId: string
        segmentId: string
        correctedText: string
    }
    'call.transfer': {
        callId: string
        targetAgent: string
        timestamp: Date
    }
}

export interface Call {
    from: string
    to: string
    status: string
    startedAt: number
    endedAt: number
    callId: string
    transcripts: CallTranscript[]
}
