import { StyledEngineProvider } from '@mui/joy/styles'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import setupAxios from './setup/SetupAxios'
import { AuthProvider } from 'hooks/auth'

setupAxios(() => {
    // Do something when the user is not authorized
    console.error('User is not authorized')
})

ReactDOM.createRoot(document.querySelector('#root')!).render(
    <StyledEngineProvider injectFirst>
        <BrowserRouter>
            <AuthProvider>
                <App />
            </AuthProvider>
        </BrowserRouter>
    </StyledEngineProvider>
)
