import { Modal, Sheet, Typography, Box, IconButton } from '@mui/joy'
import { Close } from '@mui/icons-material'
import React, { useEffect, useRef } from 'react'

interface WeatherDetailsModalProps {
    open: boolean
    onClose: () => void
    weatherData: {
        coord: {
            lat: number
            lon: number
        }
        name: string
    }
    onDateChange?: (date: string) => void
    selectedDate?: string
}

const WeatherDetailsModal: React.FC<WeatherDetailsModalProps> = ({ open, onClose, weatherData }) => {
    const widgetContainerRef = useRef<HTMLDivElement>(null)

    const setupWidget = () => {
        if (!open || !widgetContainerRef.current) {
            return
        }

        // Clear any previous content
        widgetContainerRef.current.innerHTML = ''

        // Create and append the widget container first
        const widgetContainer = document.createElement('div')
        widgetContainer.id = 'openweathermap-widget-11'
        widgetContainer.style.width = '100%'
        widgetContainer.style.height = '100%'
        widgetContainerRef.current.appendChild(widgetContainer)

        // Initialize widget parameters
        if (!window.myWidgetParam) {
            window.myWidgetParam = []
        } else {
            window.myWidgetParam.length = 0
        }

        window.myWidgetParam.push({
            id: 11,
            cityid: '',
            appid: 'bd5e378503939ddaee76f12ad7a97608',
            units: 'metric',
            containerid: 'openweathermap-widget-11',
            lat: weatherData.coord.lat,
            lon: weatherData.coord.lon,
        })

        // Load scripts sequentially
        const loadScripts = async () => {
            // Load D3.js first
            await new Promise<void>((resolve) => {
                const d3Script = document.createElement('script')
                d3Script.src = 'https://openweathermap.org/themes/openweathermap/assets/vendor/owm/js/d3.min.js'
                d3Script.async = true
                d3Script.onload = () => resolve()
                document.head.appendChild(d3Script)
            })

            // Then load the widget generator
            await new Promise<void>((resolve) => {
                const widgetScript = document.createElement('script')
                widgetScript.src = 'https://openweathermap.org/themes/openweathermap/assets/vendor/owm/js/weather-widget-generator.js'
                widgetScript.async = true
                widgetScript.charset = 'utf-8'
                widgetScript.onload = () => resolve()
                document.head.appendChild(widgetScript)
            })
        }

        loadScripts()

        return () => {
            if (widgetContainerRef.current) {
                widgetContainerRef.current.innerHTML = ''
            }
            // Remove scripts
            const scripts = document.querySelectorAll('script[src*="openweathermap.org"]')
            scripts.forEach((script) => script.remove())
        }
    }

    useEffect(() => {
        let cleanup: (() => void) | undefined

        setTimeout(() => {
            cleanup = setupWidget()
        }, 0)
        return cleanup
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, weatherData])

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                '& .MuiSheet-root:focus-visible': {
                    outline: 'none',
                },

                '& .widget-left': {
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                },

                '& .widget-left-menu--brown': {
                    display: 'none',
                },
            }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    width: 740,
                    borderRadius: 'md',
                    p: 3,
                    boxShadow: 'lg',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography component="h2" id="weather-modal-title" level="h4" textColor="inherit" fontWeight="lg">
                            Weather in {weatherData.name}
                        </Typography>
                    </Box>
                    <IconButton variant="plain" color="neutral" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>

                <Box ref={widgetContainerRef} sx={{ width: '100%', height: 250 }} />
            </Sheet>
        </Modal>
    )
}

declare global {
    interface Window {
        myWidgetParam?: Array<{
            id: number
            cityid: string
            appid: string
            units: string
            containerid: string
            lat: number
            lon: number
        }>
    }
}

export default WeatherDetailsModal
